import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as S from './Footer.style';

import Image from '../image/Image';
import { RootStoreContext } from '../../App';

const Footer = observer(() => {
	const { modeStore } = useContext(RootStoreContext);
	const [instaSrcArray, setInstaSrcArray] = useState([
		S.instagram,
		S.instagram2x,
		S.instagram3x,
	]);
	const [youtubeSrcArray, setYoutubeSrcArray] = useState([
		S.youtube,
		S.youtube2x,
		S.youtube3x,
	]);
	const [googleSrcArray, setGoogleSrcArray] = useState([
		S.google_play_icon,
		S.google_play_icon2x,
		S.google_play_icon3x,
	]);
	const [appleSrcArray, setAppleSrcArray] = useState([
		S.apple_app_store,
		S.apple_app_store2x,
		S.apple_app_store3x,
	]);
	const [isQRVisible, setQRVisible] = useState(false);

	useEffect(() => {
		if (modeStore.isBigMode) {
			setInstaSrcArray([
				S.instagram_color,
				S.instagram_color2x,
				S.instagram_color3x,
			]);
			setYoutubeSrcArray([
				S.youtube_color,
				S.youtube_color2x,
				S.youtube_color3x,
			]);
			setGoogleSrcArray([
				S.google_play_icon_color,
				S.google_play_icon_color2x,
				S.google_play_icon_color3x,
			]);
			setAppleSrcArray([
				S.apple_app_store_color,
				S.apple_app_store_color2x,
				S.apple_app_store_color3x,
			]);
		} else {
			setInstaSrcArray([S.instagram, S.instagram2x, S.instagram3x]);
			setYoutubeSrcArray([S.youtube, S.youtube2x, S.youtube3x]);
			setGoogleSrcArray([
				S.google_play_icon,
				S.google_play_icon2x,
				S.google_play_icon3x,
			]);
			setAppleSrcArray([
				S.apple_app_store,
				S.apple_app_store2x,
				S.apple_app_store3x,
			]);
		}
	}, [modeStore.isBigMode]);

	const qrMouseOver = (_isGoogle) => {
		if (_isGoogle) {
			setQRVisible('google');
		} else {
			setQRVisible('apple');
		}
	};

	const qrMouseOut = () => {
		setQRVisible(false);
	};

	return (
		<S.Container>
			<S.Alert>
				{
					'본 콘텐츠의 저작권은 저자 또는 제공처에 있으며, 이를 무단 이용하는 경우 저작권등에 따라 법적 책임을 질 수 있습니다.'
				}
			</S.Alert>
			<S.Content>
				<S.LContent>
					<ul>
						<li>{'공지사항'}</li>
						<li>{'개인정보처리방침'}</li>
						<li>{'고객센터'}</li>
					</ul>
					<div className='torooc'>© TOROOC</div>
				</S.LContent>
				<S.RContent>
					<ul>
						<li
							onClick={() => {
								window.open('https://www.instagram.com/liku_robot/', '_blank');
							}}>
							{<Image alt='instagram' srcArray={instaSrcArray} />}
						</li>
						<li
							onClick={() => {
								window.open(
									'https://www.youtube.com/@Social_Robot_Liku/featured',
									'_blank',
								);
							}}>
							{<Image alt='youtube' srcArray={youtubeSrcArray} />}
						</li>
						<li onMouseOver={() => qrMouseOver(true)} onMouseOut={qrMouseOut}>
							{<Image alt='google' srcArray={googleSrcArray} />}
						</li>
						<li onMouseOver={() => qrMouseOver(false)} onMouseOut={qrMouseOut}>
							{<Image alt='apple' srcArray={appleSrcArray} />}
						</li>
					</ul>
					<div className={`QR ${isQRVisible ? 'visible' : ''}`}>
						<Image
							alt={'qrCode'}
							srcArray={[isQRVisible === 'google' ? S.googleQR : S.appleQR]}
						/>
					</div>
				</S.RContent>
			</S.Content>
		</S.Container>
	);
});

export default Footer;
