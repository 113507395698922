// CategoryTitleItem.jsx

import React, { useState, useEffect, useRef, useContext } from 'react';
import * as S from './CategoryTitleItem.style';
import {
	categoryPanelTagList,
	seniorCategoryPanelTagList,
} from '../../../data/categoryTagList';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { premiumTags } from '../../../data/categoryTagId';
import { RootStoreContext } from '../../../App';

export const CategoryTitleItem = observer(
	({
		item,
		selected,
		onClick,
		onHover,
		isHovered,
		titleType,
		isMouseOnBlackBackground,
		isPremium = false,
	}) => {
		const [isSelectedCategoryTitleClicked, setIsSelectedCategoryTitleClicked] =
			useState(false);
		const [isItemClicked, setIsItemClicked] = useState(false);
		const [categoryTitle, setCategoryTitle] = useState(null);

		const categoryTitleRef = useRef(null);

		const location = useLocation();
		const queryParams = new URLSearchParams(location.search);
		const search = queryParams.get('search');
		const navigate = useNavigate();
		const { authStore } = useContext(RootStoreContext);

		// 카테고리 항목 클릭 시 처리하는 이벤트
		useEffect(() => {
			setIsSelectedCategoryTitleClicked(selected === item.id);
			setIsItemClicked(!(selected === item.id));
		}, [selected, item.id]);

		// 타이틀 항목 클릭 시 이벤트 처리
		const handleItemClicked = () => {
			if (isPremium) return;
			// 카테고리 아이템 안의 텍스트를 가져와서 공백 모두 제거
			let categoryItemText;
			let categoryItemTags;

			if (isItemClicked) {
				const categoryTitleElem = categoryTitleRef.current;
				const elemText = categoryTitleElem.innerText;
				categoryItemText = elemText.replace(/\s/g, '');
				if (authStore.auth.role.some((role) => role.name === 'senior')) {
					categoryItemTags = seniorCategoryPanelTagList[categoryItemText];
				} else {
					categoryItemTags = categoryPanelTagList[categoryItemText];
				}

				if (categoryTitle !== categoryItemText) {
					queryParams.delete('category');
					queryParams.delete('search');
					navigate(`?${queryParams.toString()}`);
				}
			} else {
				categoryItemText = null;
				categoryItemTags = null;
				queryParams.delete('category');
				queryParams.delete('search');
				navigate(`?${queryParams.toString()}`);
			}
			// 불러온 태그 정보를 바탕으로 각 태그에 해당하는 컨텐츠들을 홈화면에 캔버스에 슬라이드로 표시
			// URL의 Param에 카테고리=* 형식으로 추가 (categoryItemText 넘기기)
			if (categoryItemText !== categoryTitle) {
				setCategoryTitle(categoryItemText);
			} else {
				setCategoryTitle(null);
			}
		};

		useEffect(() => {
			if (isPremium) return;
			if (isItemClicked) {
				if (categoryTitle) {
					queryParams.set('category', categoryTitle);
				}
			} else {
				queryParams.delete('category');
			}

			navigate(`?${queryParams.toString()}`);
		}, [categoryTitle]);

		useEffect(() => {
			const titleElem = categoryTitleRef.current;
			const elemText = titleElem.innerText;
			const categoryItemText = elemText.replace(/\s/g, '');

			if (categoryTitle !== elemText) {
				if (isItemClicked) {
					queryParams.set('category', categoryItemText);
				} else {
					queryParams.delete('category');
				}

				navigate(`?${queryParams.toString()}`);
			}
		}, [categoryTitle]);

		return (
			<S.Container
				ref={categoryTitleRef}
				className={
					isSelectedCategoryTitleClicked && titleType === 'main'
						? `${titleType} clicked`
						: `${titleType}`
				}
				onClick={() => {
					onClick(item.id);
					handleItemClicked();
				}}
				onMouseEnter={onHover}>
				<S.TitleContent isItemHovered={isHovered}>
					<span className={!isMouseOnBlackBackground ? titleType : ''}>
						{item.name}
					</span>
				</S.TitleContent>
			</S.Container>
		);
	},
);

export default CategoryTitleItem;
