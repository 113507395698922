import { makeAutoObservable } from 'mobx';

/**
 * player 페이지의 영상에 필요한 정보를 담은 스토어
 */
class VideoV2Store {
	rootStore;

	isPlay = false;
	isFullScreen = false;

	/** 비디오 컨스트럭터 */
	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
	}

	/** 재생상태 토글 */
	togglePlay(value = !this.isPlay) {
		this.isPlay = value;
	}

	/** 전체화면 토글 */
	toggleFullscreen(value = !this.isFullScreen) {
		this.isFullScreen = value;
	}

	clear() {
		this.isPlay = false;
		this.isFullScreen = false;

		this.sceneIndex = -1;
	}
}

export default VideoV2Store;
