import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as S from './AlarmContent.style';
import Image from '../../../components/image/Image';
import { switchAlarm } from '../../../services/apis/CustomApi';

function convertToWeekdays(str) {
	let result = [];
	for (let i = 0; i < str.length; i++) {
		if (str[i] === 'o') {
			switch (i) {
				case 0:
					result.push('월');
					break;
				case 1:
					result.push('화');
					break;
				case 2:
					result.push('수');
					break;
				case 3:
					result.push('목');
					break;
				case 4:
					result.push('금');
					break;
				case 5:
					result.push('토');
					break;
				case 6:
					result.push('일');
					break;
				default:
					break;
			}
		}
	}
	return result;
}

const DefaultAlarmContent = observer(
	({ key, alarm, getList, onClick, users }) => {
		const [date, setDate] = useState([]);
		const [day, setDay] = useState([]);
		const [user, setUser] = useState([]);

		useEffect(() => {
			console.log('alarm', alarm);
			if (!alarm) return;
			const start_date = new Date(alarm?.start_date);
			const end_date = new Date(alarm?.end_date);
			const startDateFormattedDate = `${start_date.getFullYear()}.${
				start_date.getMonth() + 1
			}.${start_date.getDate()}`;
			const endDateFormattedDate = `${end_date.getFullYear()}.${
				end_date.getMonth() + 1
			}.${end_date.getDate()}`;

			setDate([startDateFormattedDate, endDateFormattedDate]);

			const days = convertToWeekdays(alarm.day);
			setDay(days.join(','));

			const userThem = [];
			for (let u of users) {
				if (alarm.face_users.some((r) => r.id === u.id)) {
					userThem.push(u.name);
				}
			}
			setUser(userThem);
		}, [alarm]);

		return (
			<S.Container onClick={onClick}>
				<div className='title'>
					<p>{alarm?.name}</p>
					<div
						className='switch_wrapper'
						onClick={(event) => {
							event.stopPropagation();
							try {
								switchAlarm(alarm.id, !alarm.state);
							} catch (error) {
								console.error(error);
							} finally {
								getList();
							}
						}}>
						<input
							className='switch_input'
							type='checkbox'
							id={'switch' + key + `_${alarm.id}`}
							checked={alarm?.state}
						/>
						<label
							htmlFor={'switch' + key + `_${alarm.id}`}
							className='switch_label'>
							<span className='onf_btn'></span>
						</label>
					</div>
				</div>
				<div className='option'>
					<div className='time'>
						<Image srcArray={[S.clock]}></Image>
						<p>
							{alarm?.start_time} ~ {alarm?.end_time}
						</p>
					</div>
					<hr />
					<div className='date' key={date + key + `_${alarm.id}`}>
						<Image srcArray={[S.calendar]}></Image>
						<p>
							{date?.[0]} ~ {date?.[1]}
						</p>
					</div>
					<hr />
					<div className='day'>
						<Image srcArray={[S.calendar_day]}></Image>
						<p>{day}</p>
					</div>
					<hr />
					<div className='day'>
						{user.map((u, index) => {
							return (
								<div key={u + index} className='user'>
									<div>{u}</div>
								</div>
							);
						})}
					</div>
					{/*<hr />*/}
					{/*<div className='user'>*/}
					{/*	<div>*/}
					{/*		{alarm?.face_user_ids?.length === 0 ? '모두에게' : '선택 사용자'}*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
			</S.Container>
		);
	},
);

export default DefaultAlarmContent;
