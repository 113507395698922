/**
 * react Query
 * @module Query
 */

import { useQuery } from 'react-query';
import { getAllTags } from '../../services/apis/TagApi';

const useTagListQuery = () => {
	return useQuery(['tags'], () => getAllTags());
};

export default useTagListQuery;
