import React, { useContext, useEffect, useState } from 'react';
import * as S from './DockLikuConnecting.style';
import Spinner from '../../spinner/Spinner';
import Image from '../../image/Image';
import { RootStoreContext } from '../../../App';
import { observer } from 'mobx-react';

const DockLikuConnecting = observer(() => {
	const { likuStore } = useContext(RootStoreContext);

	const [isHover, setIsHover] = useState(false);
	const [time, setTime] = useState(10);

	useEffect(() => {
		if (likuStore.thisLiku) {
			// 	console.log('timeout', timeout);
			setTimeout(() => {
				if (time > 0) {
					setTime(time - 1);
				} else {
					setIsHover(true);
					// if (parseInt(likuStore.thisLiku?.version?.likuVer) >= 4) {
					// 	setTimeout(() => {
					// 		likuStore.setThisLiku();
					// 	}, 3000);
					// }
				}
			}, 1000);
		}
	}, [time]);

	return (
		<S.Content>
			<S.LinkButton
				onClick={() => likuStore.setThisLiku()}
				onMouseOver={() => setIsHover(true)}
				onMouseOut={() => setIsHover(false)}>
				<S.IconContainer>
					{isHover ? <Image srcArray={S.x_list} /> : <Spinner color='gold' />}
				</S.IconContainer>
				{time > 0 ? (isHover ? '연결끊기' : '연결중') : '연결실패'}
			</S.LinkButton>
		</S.Content>
	);
});

export default DockLikuConnecting;
