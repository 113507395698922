import { authApi } from './index.js';

export const getAllMotion = async () => {
	try {
		const { data } = await authApi.get('/contents/motions');
		console.log('motion', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getMotion = async (uuid) => {
	try {
		const { data } = await authApi.get(`/contents/motions/${uuid}`);
		//console.log('getMotion data', data);
		return data;
	} catch (error) {
		console.error(error);
		console.log('getMotion uuid', uuid);
	}
};

export const setMotion = async (formData) => {
	try {
		const { data } = await authApi.post('/contents/motions', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		console.log('setMotion data', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const modifyMotion = async (uuid, formData) => {
	try {
		const { data } = await authApi.put(`/contents/motions/${uuid}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		console.log('modifyMotion data', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteMotion = async (_uuid) => {
	try {
		const { data } = await authApi.delete(`/contents/motions?uuid=${_uuid}`);
		return data;
	} catch (error) {
		console.error(error);
	}
};
