import { useQuery } from 'react-query';

import { getMotion } from '../../services/apis/MotionApi';

import { useUrlQuery } from './useUrlQuery';

const useMotionQuery = (uuid) => {
	const { data: url } = useQuery(['motion-url', uuid], () => getMotion(uuid), {
		refetchOnWindowFocus: false,
	});

	return useUrlQuery(['motion', uuid], url, {
		enabled: !!url,
	});
};

export default useMotionQuery;

// const useMotionQuery = async (uuid) => {
// 	const queryClient = useQueryClient();
// 	const [url, setUrl] = useState(null);
//
// 	useEffect(() => {
// 		const fetchMotion = async () => {
// 			await queryClient.prefetchQuery(
// 				['motion-url', uuid],
// 				() => getMotion(uuid),
// 				{
// 					enabled: !!uuid,
// 				},
// 			);
//
// 			const url = queryClient.getQueryData(['motion-url', uuid]);
// 			setUrl(url);
// 		};
//
// 		if (uuid) {
// 			fetchMotion();
// 		}
// 	}, [uuid, queryClient]);
//
// 	await useUrlPrefetchQuery(['motion', uuid], url, {
// 		enabled: !!url,
// 	});
// };
//
// export default useMotionQuery;
