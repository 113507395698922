import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import * as S from './CategoryItem.style';
import useTagClickHandler from '../../../hooks/useTagClickHandler';
import { useNavigate } from 'react-router-dom';
import { RootStoreContext } from '../../../App';

const CategoryItem = observer(
	({ item, content, checkTagContain, setMainCategory, categoryID }) => {
		// 1: 언어, 2: 영어, 3: 미술, 4: 음악, 5: 과학, 6: 리쿠 행사
		const tagClickHandler = useTagClickHandler('unique');
		const navigate = useNavigate();
		const { authStore, likuStore } = useContext(RootStoreContext);

		return (
			<S.Container
				onClick={() => {
					setMainCategory(categoryID);
					if (
						// item?.id === 55 &&
						// authStore.auth?.role?.some((role) => role.id === 9)
						item?.id === 55
					) {
						if (!likuStore.thisLiku) {
							return alert('로봇을 연결해주세요');
						}
						return window.open('http://aien.torooc.com');
					}
					// else if (item?.id === 55) {
					// 	alert('AIEN 서비스 구매 회원만 이용 가능합니다.');
					// 	return navigate('/play');
					// }
					tagClickHandler(item, 'search');
				}}>
				<S.Content className={checkTagContain(item.id) !== -1 ? 'clicked' : ''}>
					{content}
				</S.Content>
			</S.Container>
		);
	},
);

export default CategoryItem;
