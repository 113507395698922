import { makeAutoObservable, runInAction } from 'mobx';
import {
	deleteAlarm,
	makeAlarm,
	switchAlarm,
	updateAlarm,
} from '../../services/apis/CustomApi';
import { authApi } from '../../services/apis';

class AlarmStore {
	rootStore;
	alarmList = null;
	makeAlarm = null;
	users = null;
	selectedUser = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		this.alarmList = null;
		this.makeAlarm = null;
		this.selectedUser = null;
		this.users = null;
	}

	setAlarmList(_list) {
		runInAction(() => {
			this.alarmList = _list;
			console.log('list', this.alarmList);
		});
	}

	async setMakeAlarm(_alarm) {
		console.log('make' + JSON.stringify(_alarm));
		await makeAlarm(_alarm);

		await this.mqttAlarms();

		runInAction(() => {
			this.makeAlarm = _alarm;
		});
	}

	setSelectedUser(_user) {
		runInAction(() => {
			this.selectedUser = _user;
		});
	}

	setUsers(_users) {
		runInAction(() => {
			this.users = _users;
		});
	}

	updateAlarm = async (alarm) => {
		console.log('update', alarm);
		await updateAlarm(alarm);

		await this.mqttAlarms();
	};

	deleteAlarm = async (alarm) => {
		await deleteAlarm(alarm);

		await this.mqttAlarms();
	};

	switchAlarm = async (alarmId, state) => {
		console.log('switch alarm');
		await switchAlarm(alarmId, state);

		await this.mqttAlarms();
	};

	async mqttAlarms() {
		const likus = this.rootStore.likuStore.likus;
		const uuids = [];
		for await (const liku of likus) {
			const curLiku = await authApi.get(`/likus?serial=${liku}`);
			uuids.push(curLiku.data?.uuid);
		}
		const topic = `liku/${uuids[0]}/robot/custom/set`;
		console.log('mqttAlarms');
		await this.rootStore.mqttStore.connect(() => {
			return {
				type: 'custom',
				likus: uuids,
				topic,
				message: 'alarm',
			};
		});
	}
}

export default AlarmStore;

// {
// 	"category": "meal",
// 	"name": "name",
// 	"info": "info",
// 	"start_date": "2023-06-18 14:11:13",
// 	"end_date": "2023-06-18 14:11:13",
// 	"start_time": "00:00",
// 	"end_time": "23:59",
// 	"day": "oooxoxx",
// 	"time": "12:00",
// 	"repeat": "10M",
// 	"target": "Anyone",
// 	"priority": 120,
// 	"scenario_id_array": [457],
// 	"face_user_id_array": [26170, 26596]
// 	}
