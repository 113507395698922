import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	pointer-events: auto;
	//min-width: 65rem;
	min-width: ${({ thisLiku }) => (thisLiku ? '37rem' : '32rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;

export const Context = styled.div`
	// position: relative;
	// z-index: 1;
	// background-color: #fff;
	// border-radius: 120px 120px 65px 65px;
	// box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	//
	// ${({ theme }) => theme.common.wh('100%', '8.8rem')};
	// ${({ theme }) => theme.common.flex('row', 'center', 'center')};

	position: relative;
	//min-width: 65rem;
	background-color: #fff;
	border-radius: 44px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	padding: 2rem 2.4rem;
	${({ theme }) => theme.common.wh('100%', '8.8rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;
