import * as T from './categoryTagId';

// 헤더의 카테고리 패널에 넣을 것들
export const categoryPanelTagList = {
	언어: T.languageTags,
	영어: T.englishTags,
	미술: T.artTags,
	음악: T.musicTags,
	과학: T.scienceTags,
	역사: T.historyTags,
	안전: T.safeTags,
	리쿠행사: T.likuFestivalTags,
};

export const seniorCategoryPanelTagList = {
	신체: T.bodyTags,
	인지: T.mindTags,
	정서: T.emotionTags,
};
