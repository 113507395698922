import { useQuery } from 'react-query';
import { getUserFace } from '../../services/apis/UserFaceApi';

const useUserFaceQuery = (userId, collection) => {
	return useQuery(
		['user_face', userId],
		() => getUserFace({ faceUserId: userId, collectionName: collection }),
		{
			enabled: userId !== undefined,
			onSuccess: (data) => {
				return data?.map((value) => {
					return {
						id: value.id,
						faceId: value.faceId,
						path: value.path,
					};
				});
			},
			onError: (error) => {},
		},
	);
};

export default useUserFaceQuery;
