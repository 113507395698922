import { makeAutoObservable, runInAction } from 'mobx';

import {
	deleteMotion,
	getAllMotion,
	getMotion,
	modifyMotion,
	setMotion,
} from '../../services/apis/MotionApi';
import {
	createScenario,
	deleteScenarioId,
	getAllScenario,
	getScenario,
} from '../../services/apis/ScenarioApi';

import { Motion } from './Motion';
import { LikuMotion } from './LikuMotion';

import axios from 'axios';

// import { getAllScenario } from '../../services/apis/ScenarioApi';

class MotionStore {
	rootStore;

	motions = [];
	scenarios = [];

	playIndex = -1;
	playList = [];

	uuidModify = '';
	motionModify = [];
	dataModify = [];
	isModified = false;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
	}

	async loadMotion() {
		const motionList = await getAllMotion();
		const scenarioList = await getAllScenario();
		const detailedScenarioList = [];

		//console.log('motionList', motionList.length);
		//console.log('sceList', scenarioList.length);

		for await (const scenario of scenarioList) {
			const detailedScenario = await getScenario(scenario.id);
			for await (const motion of detailedScenario.motions) {
				try {
					const motionData = await getMotion(motion.uuid);
					const { data } = await axios.get(motionData.url);
					motion.motion = data;
				} catch (e) {
					console.error(motion);
					if (e?.response?.status === 404) {
						await deleteMotion(motion.uuid);
					}
				}
			}
			detailedScenarioList.push(detailedScenario);
		}
		console.log('detailedScenarioList', detailedScenarioList, scenarioList);

		runInAction(() => {
			this.motions = motionList;
			this.scenarios = detailedScenarioList;
		});
	}

	getMotions(type) {
		return this.motions?.filter((motion) => motion.type === type);
	}

	async setMotion(data) {
		const tempMotion = data.motion;
		delete data.motion;

		const motion = new Motion(this, data);
		const formData = new FormData();
		for (let key of Object.keys(motion)) {
			if (key !== 'store') {
				formData.append(key, motion[key]);
			}
		}

		const likuMotion = new LikuMotion(
			tempMotion.action,
			tempMotion.display,
			tempMotion.speech,
		);
		const jsonFile = new Blob([likuMotion.toJson()], {
			type: 'application/json',
		});
		formData.append('file', jsonFile, 'motion.json');

		for (let [key, value] of formData.entries()) {
			console.log(`${key}: ${value}`);
		}
		const result = await setMotion(formData);
		await this.loadMotion();
		console.log(result);
	}

	playMotion(data) {
		const tempMotion = { ...data.motion };
		console.log('tempMotion', tempMotion);
		if (
			tempMotion?.speech &&
			typeof tempMotion?.speech !== 'string' &&
			!tempMotion?.speech?.TTS
		) {
			tempMotion.speech.TTS = data?.name;
		}
		const likuMotion = new LikuMotion(
			tempMotion?.action,
			tempMotion?.display,
			tempMotion?.speech,
		);
		this.rootStore.likuStore.playJson(likuMotion);
	}

	async deleteMotion(uuid) {
		console.log('delMotion', uuid);
		const result = await deleteMotion(uuid);
		await this.loadMotion();
		return result;
	}

	setPlayList(motion, index = null) {
		if (!this.rootStore.likuStore.isControl) {
			if (index !== null) {
				this.playList.splice(index, 1);
			} else {
				this.playList.push(motion);
			}
		}
	}

	setPlay() {
		this.rootStore.likuStore.setIsControl(true);
		this.isNext();
	}

	updateMotion(index, data) {
		if (!this.playList[index] || !data) return;
		console.log('realMotion 22', this.playList[index], data);
		this.playList[index].motion = data;
	}

	setModifyMotion(motion, data) {
		this.isModified = false;
		this.uuidModify = motion.uuid;
		this.motionModify = motion;
		this.dataModify = data;
	}

	async modifyMotion() {
		// console.log('modifyMotion');
		const motion = this.motionModify;
		const formData = new FormData();
		for (let key of Object.keys(motion)) {
			if (key !== 'store' && key !== 'uuid') {
				formData.append(key, motion[key]);
			}
		}

		const likuMotion = new LikuMotion(
			this.dataModify.action,
			this.dataModify.display,
			this.dataModify.speech,
		);
		const jsonFile = new Blob([likuMotion.toJson()], {
			type: 'application/json',
		});
		formData.append('file', jsonFile, 'motion.json');

		for (let [key, value] of formData.entries()) {
			console.log(`${key}: ${value}`);
		}
		const result = await modifyMotion(this.motionModify.uuid, formData);
		await this.loadMotion();

		//await getMotion(motion.uuid);

		runInAction(() => {
			this.isModified = true;
		});

		console.log(result);
	}

	isNext() {
		this.playIndex += 1;
		if (this.playIndex < this.playList.length) {
			const motion = this.playList[this.playIndex].motion;
			console.log('motionssssss', motion);
			const tempMotion = { ...motion };
			if (tempMotion?.speech && !tempMotion?.speech?.TTS) {
				tempMotion.speech.TTS = this.playList[this.playIndex]?.name;
			}
			this.rootStore.likuStore.playJson(tempMotion);
		} else {
			this.rootStore.likuStore.setIsControl(false);
			this.playIndex = -1;
			this.playList = [];
		}
	}

	setPlayIndex(_index) {
		runInAction(() => {
			this.playIndex = _index;
		});
	}

	async createScenario(_title) {
		console.log('scenario', this.scenarios, this.scenarios.length);
		const result = await createScenario(
			this.playList,
			this.scenarios?.length
				? this.scenarios[this.scenarios.length - 1]?.scenarioOrder + 1
				: 1,
			this.rootStore.authStore?.auth?.id,
			_title,
		);
		await this.loadMotion();
		console.log('createScenario', result, this.playList);
	}

	async deleteScenario(_scenarioId) {
		const curScenario = await getScenario(_scenarioId);
		console.log('curScenario', curScenario);
		try {
			await deleteScenarioId(_scenarioId);
			// await Promise.all(
			// 	curScenario?.motions.map(
			// 		async (motion) =>
			// 			await deleteScenario(_scenarioId, motion.id, motion.motionOrder),
			// 	),
			// );
		} catch (error) {
			console.error(error);
		}
		// const result = await deleteScenarioId(_scenarioId);
		await this.loadMotion();
		// return result;
	}
}

export default MotionStore;
