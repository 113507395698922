import { authApi } from './index';
import { get as getCookie } from '../../utils/Cookie';
import axios from 'axios';

export const getBirthdayFaceUsers = async (isBirthday) => {
	try {
		const collectionName = getCookie('collectionName');
		const url = `/portal/collection/name/face-users?collectionName=${collectionName}`;
		let { data } = await authApi.get(url);

		for await (let child of data) {
			const childFaceData = await getFaces(
				child.id,
				child.collections[0]?.name,
			);
			child.faces = childFaceData;
			if (child.faces[0]?.path) {
				const imgFile = await convertKidImageUrlToFile(child.faces[0]?.path);
				child.faceImages = imgFile;
			}
		}

		if (isBirthday) {
			const curMonth = new Date().getMonth() + 1;
			data = data.filter((item) => {
				const curBirth = item.birth.toString();
				const itemMonth = +curBirth?.slice(4, 6);
				return curMonth == itemMonth;
			});
		}
		return data;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const getFaces = async (_faceUserId, _collectionName) => {
	try {
		const url = `/portal/collection/faces?faceUserId=${_faceUserId}&collectionName=${_collectionName}`;
		let { data } = await authApi.get(url);
		return data;
	} catch (error) {
		console.error(error);
	}
};

/**
 * 이미지 보내고 얼굴인식정보 받아오는 함수
 * @param {object} file - 파일 형태의 이미지
 * @returns {json} response - 객체 형태의 얼굴인식정보
 */
export const faceDetection = async (file) => {
	let URL = '/portal/face/detect';

	/* 서버로 파일(이미지 포함)을 전송하기 위해 FormData에 추가 */
	const formData = new FormData();
	/* 'file'은 서버에서 해당 파일을 식별하는 키이다. */
	formData.append('file', file);

	try {
		const response = await authApi.post(URL, formData, {
			headers: { Authorization: `Bearer ${getCookie('token')}` },
		});
		return response;
	} catch (error) {
		console.error(error);
		return;
	}
};

/**
 * 자른 얼굴 이미지 파일 업로드
 * @param {string} url
 * @param {string} serial
 * @returns
 */
export const croppedFaceFileUpload = async (url, serial) => {
	let stringSerial = serial.toString();
	let topic = `http://api.likuwithme.com/liku/contents/metaverse/liku/${stringSerial}/photos`;
	const formData = new FormData();
	formData.append('file', url);

	try {
		const response = await authApi.post(topic, formData, {
			// headers: { Authorization: `Bearer ${masterToken}` },
			headers: { Authorization: `Bearer ${getCookie('token')}` },
		});
		console.log('response', response);

		return;
	} catch (error) {
		console.error('uploadedFileError', error);
		return;
	}
};

/**
 * 얼굴 부분 자르기와 자른 얼굴 회전 처리
 * @param {string} tempURL - 리소스 가르키는 url
 * @param {Array} cropDataList - 얼굴 별 위치와 회전 각도 정보 리스트
 * @returns
 */
export const editImage = async (tempURL, cropDataList) => {
	// console.log('getBirthday', cropDataList, 'tempURL', tempURL);
	return new Promise((resolve, reject) => {
		try {
			/**
			 * 이미지 객체화
			 * 객체화된 이미지 중 얼굴 부분 crop
			 * 자른 얼굴 부분 회전 후
			 * 캔버스에 그리기
			 */
			const image = new Image();
			image.src = tempURL;
			image.onload = async function () {
				// console.log("parameter cropData", cropDataList[bigIdx]);
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				// console.log("image", image);
				// console.log("image.width", image.width);
				// console.log("image.height", image.height);
				const ix = cropDataList.BoundingBox.Left * image.width;
				const iy = cropDataList.BoundingBox.Top * image.height;
				const iw = cropDataList.BoundingBox.Width * image.width;
				const ih = cropDataList.BoundingBox.Height * image.height;
				canvas.width = iw;
				canvas.height = ih;

				let typeList = ['nose', 'rightEyeLeft', 'leftEyeRight'];

				let landmarkList = [];
				// get landmarks
				if (landmarkList.length === 0) {
					await cropDataList.Landmarks.map((value, index) => {
						// console.log('getBirthday', value);
						for (let i = 0; i < typeList.length; i++) {
							if (value.Type === typeList[i]) {
								let landmarksCopy = Object.assign({}, value);
								landmarksCopy.x = landmarksCopy.x * iw;
								landmarksCopy.y = landmarksCopy.y * ih;
								landmarkList.push(landmarksCopy);
								break;
							}
						}
					});
				}

				for (let i = 0; i < landmarkList.length; i++) {
					if (landmarkList[i].Type === 'nose') {
						context.fillStyle = '#2B1C14';
						context.fillRect(0, 0, iw, ih);
						context.translate(iw / 2, ih / 2);
						context.rotate((-cropDataList.Pose.Roll * Math.PI) / 180);
						context.translate(-iw / 2, -ih / 2);
						context.globalAlpha = 0.9;
						context.fill();
						context.drawImage(image, ix, iy, iw, ih, 0, 0, iw, ih);
						// console.log('landmark', image, ix, iy, iw, ih);
					}
				}

				const editedImageDataURL = canvas.toDataURL('image/png');
				resolve(editedImageDataURL);

				// /**
				//  * 캔버스에 그린 자르고 회전한 얼굴 부분 이미지 blob화 한 후 url 해제
				//  */
				// const imageBlob = await new Promise((resolve) => {
				// 	canvas.toBlob(resolve, 'image/png');
				// 	URL.revokeObjectURL(tempURL);
				// });
				// /* blob화된 이미지 file형태로 변환 */
				// const file = new File([imageBlob], 'image.png', {
				// 	type: 'image/png',
				// });

				// resolve(file);
			};
		} catch (error) {
			reject(error);
		}
	});
};

export const convertKidImageUrlToFile = async (_url) => {
	try {
		const { data: imageData } = await axios({
			url: _url,
			method: 'GET',
			responseType: 'blob',
			withCredentials: false,
		});

		const file = new File([imageData], 'faceImage.png', {
			type: 'image/png',
		});

		const faceDetectionData = await faceDetection(file);
		// console.log('getBirthday faceDetectionData', faceDetectionData);
		const YAW_limit = 25;
		const PITCH_limit = 20;
		let biggestFaceSize =
			faceDetectionData.data[0].BoundingBox.Width *
			faceDetectionData.data[0].BoundingBox.Height;
		let tempImageIdx = 0;
		let isLimit = false;
		faceDetectionData.data?.map((value, index) => {
			let checkSize = value.BoundingBox.Width * value.BoundingBox.Height;
			if (checkSize > biggestFaceSize) {
				biggestFaceSize = checkSize;
				tempImageIdx = index;
				console.log('getBirthday tempImageIdx', tempImageIdx, value);
			}
		});
		/**
		 * 선택한 얼굴 부분의 x축, z축 회전이 제한한 값에 속하는지 체크해준다.
		 */
		if (
			Math.abs(faceDetectionData.data[tempImageIdx].Pose.Yaw) < YAW_limit &&
			Math.abs(faceDetectionData.data[tempImageIdx].Pose.Pitch) < PITCH_limit
		) {
			isLimit = true;
		} else {
			return;
		}

		let tempImageURL = '';
		if (isLimit) {
			if (typeof file === 'string') {
				tempImageURL = URL.createObjectURL(new Blob([file]), {
					type: 'image/png',
				});
			} else {
				tempImageURL = URL.createObjectURL(file);
			}
		}
		// console.log(
		// 	'getBirthday faceDetectionData',
		// 	faceDetectionData?.data[tempImageIdx],
		// 	'tempURL',
		// 	tempImageURL,
		// );
		let cropResult = await editImage(
			tempImageURL,
			faceDetectionData?.data[tempImageIdx],
		);
		// console.log('getBirthday cropResult', cropResult);

		return cropResult;
	} catch (error) {
		console.error('getBirthday', error);
	}
};

export const getMetaverseFaceImageList = async (serial) => {
	const url = `/contents/metaverse/liku/${serial}/photos`;
	const result = await authApi.get(url);
	console.log('getMetaverseFaceImageList', result);
	return result;
};
