import styled from 'styled-components';

export const Content = styled.div`
	position: relative;
	z-index: 9999;
	gap: 1.2rem;
	transform: translate(3rem, 10rem);
	transition-property: width, height, min-height, max-height, transform,
		margin-bottom;

	@keyframes moveXIn {
		0% {
			transform: translateX(3rem);
		}
		70% {
			transform: translateX(2rem);
		}
		100% {
			transform: translateX(0);
		}
	}
	@keyframes moveXOut {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(3rem);
		}
	}

	overflow: ${({ isAnimating }) => (isAnimating ? 'hidden' : 'visible')};
	${({ theme }) => theme.common.flex('row', 'flex-end', 'space-between')};
	${({ isVisible, isAnimating }) =>
		isVisible
			? `width: 93rem;
				 // height: 134.41%;
				//  width: 74.4rem;
				 height: 100%;
         min-height: 25rem;
         max-height: 46.5rem;
				 margin-bottom: 1.2rem;
         animation: moveXIn 0.35s ease-out;
				 transform: translate(0, 0);
				 transition-duration: 0.4s, 0.8s, 0.8s, 0.8s, 0.7s;
				 transition-delay: 0.35s, 0s, 0s, 0s;
				 transition-timing-function: ease-in-out;
				 ${isAnimating ? 'hidden' : 'visible'}`
			: `
				 width: 0rem;
				 height: 0rem;
         min-height: 0rem;
         max-height: 0rem;
         animation: moveXOut 0.45s ease-in;
				 transform: translate(3rem, 8rem);
				 transition-duration: 0.5s, 0.8s, 0.8s, 0.8s, 0.8s;
				 transition-timing-function: ease-in-out;
         margin-bottom: 0;
         overflow: hidden;
			 `};
	@media (max-width: 1000px) {
		overflow: ${({ isAnimating }) => (isAnimating ? 'hidden' : 'visible')};
		${({ theme }) => theme.common.flex('row', 'flex-end', 'space-between')};
		${({ isVisible, isAnimating }) =>
			isVisible
				? `width: 74.4rem;
				 height: 100%;
         min-height: 20rem;
         max-height: 37.2rem;
				 margin-bottom: 1.2rem;
         animation: moveXIn 0.35s ease-out;
				 transform: translate(0, 0);
				 transition-duration: 0.4s, 0.8s, 0.8s, 0.8s, 0.7s;
				 transition-delay: 0.35s, 0s, 0s, 0s;
				 transition-timing-function: ease-in-out;
				 ${isAnimating ? 'hidden' : 'visible'}`
				: `
				 width: 0rem;
				 height: 0rem;
         min-height: 0rem;
         max-height: 0rem;
         animation: moveXOut 0.45s ease-in;
				 transform: translate(3rem, 8rem);
				 transition-duration: 0.5s, 0.8s, 0.8s, 0.8s, 0.8s;
				 transition-timing-function: ease-in-out;
         margin-bottom: 0;
         overflow: hidden;
			 `};
	}
`;

export const Button = styled.button`
	padding: 0.3rem 1.5rem 0.4rem 1.5rem;
	border: none;
	border-radius: 4px;
	font-family: NotoSansKR-Regular;
	font-size: 1.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: center;
	color: #46423d;
	background-color: #fff;

	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('12.5rem', '3rem')};

	@media (max-width: 1000px) {
		font-size: 1.2rem;
		${({ theme }) => theme.common.wh('9.6rem', '2.4rem')};
	}

	& image {
		aspect-ratio: 1 /1;
		${({ theme }) => theme.common.wh('100%')};
	}
`;

export const Box = styled.div`
	border-radius: 12px;
	min-height: 16rem;
	max-height: 40rem;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
	border: solid 1px #ebebe9;
	background-color: #fff;
	pointer-events: auto;
	${({ theme }) => theme.common.wh('30rem', '125%')};

	&.light {
		border: solid 1px #ebebe9;
		background-color: #fff;

		.title {
			background-color: #ebebe9;
			color: ${({ theme }) => theme.color.subTitle};

			& input {
				background-color: #fff;
				color: ${({ theme }) => theme.color.title};

				&::placeholder {
					color: ${({ theme }) => theme.color.gray_a8};
				}
			}
		}
	}

	&.dark {
		border: solid 1px ${({ theme }) => theme.color.gray_20};
		background-color: ${({ theme }) => theme.color.gray_35};

		.title {
			background-color: ${({ theme }) => theme.color.gray_20};
			color: ${({ theme }) => theme.color.gray_70};

			& input {
				color: #fff;
				background-color: ${({ theme }) => theme.color.gray_35};

				&::placeholder {
					color: ${({ theme }) => theme.color.gray_a8};
				}
			}
		}
	}

	.title {
		border-radius: 12px 12px 0 0;
		padding: 0.8rem 1rem 0.8rem 1.7rem;
		font-family: NotoSansKR-Bold;
		font-size: 1.5rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		text-align: left;
		${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
		${({ theme }) => theme.common.wh('auto', '4rem')};

		& input {
			padding: 0.3rem 0.8rem;
			outline: none;
			border: none;
			border-radius: 4px;
			font-family: NotoSansKR-Regular;
			font-size: 1.5rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			background-color: #fff;
			color: ${({ theme }) => theme.color.title};
			${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
			${({ theme }) => theme.common.wh('17.5rem', '125%')};

			&::placeholder {
				color: ${({ theme }) => theme.color.gray_a8};
			}
		}
	}

	.content {
		padding: 1.2rem;
		//min-height: 14.75rem;
		min-height: 11.8rem;
		${({ theme }) => theme.common.flex('column', 'center', 'space-between')};
		${({ theme }) => theme.common.wh('100%')};
	}

	.rowWrap {
		${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
		${({ theme }) => theme.common.wh('100%', 'auto')};
	}

	@media (max-width: 1000px) {
		border-radius: 12px;
		min-height: 16rem;
		max-height: 40rem;
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
		border: solid 1px #ebebe9;
		background-color: #fff;
		pointer-events: auto;
		${({ theme }) => theme.common.wh('24rem', '100%')};

		.title {
			border-radius: 12px 12px 0 0;
			padding: 0.8rem 1rem 0.8rem 1.7rem;
			font-family: NotoSansKR-Bold;
			font-size: 1.2rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: normal;
			text-align: left;
			${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
			${({ theme }) => theme.common.wh('auto', '4rem')};

			& input {
				padding: 0.3rem 0.8rem;
				outline: none;
				border: none;
				border-radius: 4px;
				font-family: NotoSansKR-Regular;
				font-size: 1.2rem;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				background-color: #fff;
				color: ${({ theme }) => theme.color.title};
				${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
				${({ theme }) => theme.common.wh('14rem', '100%')};

				&::placeholder {
					color: ${({ theme }) => theme.color.gray_a8};
				}
			}
		}

		//.content {
		//	min-height: 11.8rem;
		//}
	}
`;

export const Wrap = styled.div`
	gap: 1.2rem;
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
`;
