import React, { useEffect, useState } from 'react';
import { DefaultImage } from './Image.style';

const Image = ({ alt, srcArray, custom, onClick }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);

	const handleImageLoaded = (e) => {
		setIsLoaded(true);
	};

	const handleImageError = (e) => {
		// console.log('handleImageError', e);
		setIsError(true);
	};

	useEffect(() => {}, [isLoaded]);

	return (
		<>
			{/* 스켈레톤 처리 파트 */}
			{!isLoaded && !isError && (
				<div className={''} style={{ width: '99.9%', height: '99.9%' }}></div>
			)}

			{/* 만약 onLoad를 통해 로드 되었다면 isLoaded = true.
				이후 className을 통해 opacity 조절로 부드럽게 보여줌
			*/}
			{
				<DefaultImage
					onClick={onClick}
					onMouseEnter={(e) => e.target.classList.add('hovered')}
					onMouseLeave={(e) => e.target.classList.remove('hovered')}
					draggable={false}
					custom={custom}
					alt={alt}
					srcSet={`${srcArray?.[1] || srcArray?.[0]} 2x,
					 ${srcArray?.[2] || srcArray?.[0]} 3x`}
					// sizes={}
					src={srcArray?.[0] || null}
					onLoad={handleImageLoaded}
					onError={handleImageError}
					loading='lazy'
					// decoding='async'
					// fetchpriority='high'
					className={isLoaded ? 'isLoaded' : ''}
				/>
			}
		</>
	);
};

export default Image;
