import { lazy } from 'react';

export const MainPage = lazy(() => import('./main/MainPage'));
export const LoginPage = lazy(() => import('./login/LoginPage'));
export const PlayPage = lazy(() => import('./play/PlayPage'));
export const PlayListPage = lazy(() => import('./play/playList/PlayListPage'));
export const CustomPage = lazy(() => import('./custom/CustomPage'));
export const ChatBotPage = lazy(() => import('./custom/chatbot/ChatBotPage'));
export const PlayerPage = lazy(() => import('./player/PlayerPage'));
export const SchoolPage = lazy(() => import('./school/School'));
export const ManagePage = lazy(() => import('./manage/Manage'));
export const StoragePage = lazy(() => import('./storage/StoragePage'));
export const NoticePage = lazy(() => import('./notice/NoticePage'));
