import { makeAutoObservable } from 'mobx';
import { v4 as createUuid } from 'uuid';

// import { getMotion } from '../../services/apis/MotionApi';

export class Motion {
	store;

	// id;
	name;
	uuid;
	info;
	warning;
	// jsonPath;
	type;
	// json;

	constructor(store, motion) {
		makeAutoObservable(this);
		this.store = store;

		// this.id = motion?.id;
		this.name = motion?.name;
		this.info = motion?.info || motion?.name || 'test3';
		this.warning = motion?.warning || 'test3';
		// this.jsonPath = motion?.jsonPath;
		this.type = motion?.type || 'CUSTOM';

		this.uuid = motion?.uuid || createUuid();
		// this.init(motion);
	}

	// async init(motion) {
	// 	if (motion?.json) {
	// 		this.json = motion.json;
	// 	} else {
	// 		try {
	// 			const response = await getMotion(this.uuid);
	// 			this.setJson(response.data);
	// 		} catch (error) {
	// 			console.error('Failed to fetch motion:', error);
	// 		}
	// 	}
	// }

	getKeys() {
		return;
	}

	setJson(json) {
		this.json = json;
	}

	// toFormData(data) {}
}
