// const HEARTBEAT_TOPIC = ['volume', 'motion', 'language'];

import { observable } from 'mobx';

const MAX_WAITING_TIME = 10; //ack response max waiting time (in seconds)

const LikuHeartBeat = (
	isMqttClientState,
	isContentsLoading,
	isContentsStart,
) => {
	const acks = [];

	let isHeartBeat = observable.box(false);
	let heartBeat = 0;

	const getCurrentDate = () => {
		const currentDate = new Date();
		return `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}:${currentDate.getMilliseconds()}`;
	};

	const setIsHeartBeat = (value = true) => {
		// isHeartBeat = value;
		isHeartBeat.set(value);
		console.log('setIsHeartBeat', isHeartBeat.get());
	};

	const setHeartBeat = (value) => {
		if (isHeartBeat.get()) {
			console.log(`setHeartBeat !! ${getCurrentDate()}`);

			if (value) {
				heartBeat = value;
			} else {
				heartBeat = 0;
			}
		} else {
			setHeartBeatInterval();
		}
	};

	const setHeartBeatInterval = () => {
		console.log(`setHeartBeatInterval START !! ${getCurrentDate()}`);
		// isHeartBeat = true;
		// setIsHeartBeat(true);
		const heartBeatInterval = setInterval(() => {
			// console.log('heartBeatInterval isContentsLoading', isContentsLoading());
			if (!isContentsLoading()) {
				heartBeat += 1;

				// console.log('isContentsStart', isContentsStart());
				const TIME_OUT = isContentsStart() ? 15 : 150;
				const isHeartBeatState = isHeartBeat.get();
				// console.log('isHeartBeatState', isHeartBeatState);
				if (
					!isHeartBeatState ||
					heartBeat >= TIME_OUT ||
					!isMqttClientState()
				) {
					console.log(`heartbeat clearInterval !!! ${getCurrentDate()}`);
					// isHeartBeat = false;
					acks.splice(0, acks.length);
					setIsHeartBeat(false);
					clearInterval(heartBeatInterval);
				}
				checkAck();
			}
		}, 1000);
	};

	// const getTopicType = (topic) => {
	// 	return HEARTBEAT_TOPIC.find((value) => topic.includes(value));
	// };

	const checkAck = () => {
		const current_time = new Date();
		acks.forEach((obj) => {
			console.log('checkAck', obj);
			if (
				current_time.getTime() / 1000 - obj.date.getTime() / 1000 >=
				MAX_WAITING_TIME
			) {
				console.log('checkAck False!!!!');
				setIsHeartBeat(false);
				acks.splice(0);
			}
		});
	};

	const addAck = (type) => {
		// const type = getTopicType(topic);
		if (type) {
			acks.push({ type, date: new Date() });
			console.log('acks last data', acks[acks.length - 1]);
		}
	};

	const popAck = (type) => {
		// const type = getTopicType(topic);

		const index = acks.findIndex((ack) => ack.type === type);
		if (index !== -1) {
			const a = acks.splice(index, 1);
			console.log('popAck', a);
		}
	};

	return {
		get isHeartBeat() {
			return isHeartBeat.get();
		},
		heartBeat,
		// getCurrentDate,
		setIsHeartBeat,
		setHeartBeat,
		setHeartBeatInterval,
		checkAck,
		addAck,
		popAck,
	};
};

export default LikuHeartBeat;
