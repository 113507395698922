const TopicHandlerStrategy = () => {
	const handlers = new Map();

	const addHandler = (topic, handler) => {
		// handlers.set(topic, handler);
		if (handlers.has(topic)) {
			handlers.set(topic, [...handlers.get(topic), handler]);
		} else {
			handlers.set(topic, [handler]);
		}
		console.log('topic', topic);
		console.log(handlers.get(topic));
	};

	// const removeHandler = (topicPattern) => {
	// 	handlers.delete(topicPattern);
	// };
	const removeHandler = (topic, handlerToRemove) => {
		if (handlers.has(topic)) {
			const updatedHandlers = handlers
				.get(topic)
				.filter((handler) => handler.handlerFunction !== handlerToRemove);
			if (updatedHandlers.length > 0) {
				handlers.set(topic, updatedHandlers);
			} else {
				removeHandlerAll(topic);
			}
		}
	};

	const removeHandlerAll = (topic) => {
		handlers.delete(topic);
	};

	const handleMessage = (topic, payload, originPayload) => {
		for (const [topicPattern, handler] of handlers) {
			// console.log('topicPattern', topicPattern);
			if (topic.match(topicPattern)) {
				// handler(payload);
				handler.forEach((handler) => {
					if (handler.requiredParameter === 'payload') {
						handler.handlerFunction(payload);
					} else if (handler.requiredParameter === 'originPayload') {
						handler.handlerFunction(originPayload);
					} else if (handler.requiredParameter === 'topic') {
						handler.handlerFunction(topic);
					} else if (handler.requiredParameter === 'both') {
						handler.handlerFunction(topic, payload, originPayload);
					} else {
						if (handler?.handlerFunction) {
							handler?.handlerFunction();
						}
					}
				});
				console.log('topicPattern match!!!', topicPattern);
				return;
			}
		}

		console.log('Unknown topic:', topic);
	};

	return {
		addHandler,
		removeHandler,
		removeHandlerAll,
		handleMessage,
	};
};

export default TopicHandlerStrategy;
