import { useEffect } from 'react';

/**
 * 특정 요소에 [Enter] 키 이벤트를 추가하는 훅
 * @param {import('react').Ref} elementRef [Enter] 키 이벤트를 추가할 대상
 * @param {function} functionToExecute [Enter] 키를 누르면 실행되는 함수
 */
const useEnterKeyEvent = ({ elementRef, functionToExecute }) => {
	useEffect(() => {
		// 검색창에서 [Enter] 키 처리
		const handleEnterKey = (e) => {
			if (e.key === 'Enter') {
				functionToExecute();
			}
		};

		const element = elementRef.current;

		if (!element) return;

		element.addEventListener('keypress', handleEnterKey);

		return () => {
			element.removeEventListener('keypress', handleEnterKey);
		};
	}, [functionToExecute]);
};

export default useEnterKeyEvent;
