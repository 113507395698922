import React from 'react';
import { observer } from 'mobx-react';

import * as S from './CardHoverOverlay.style';
import Image from '../image/Image';

const CardHoverOverlay = observer(
	({ title, subTitle, description, uiStyle, backColor, imageSrc }) => {
		return (
			<S.Container uiStyle={uiStyle} backColor={backColor}>
				<pre className='sub'>{subTitle}</pre>
				<pre>{title}</pre>
				{uiStyle === 'small' ? <Image srcArray={[imageSrc]} /> : <></>}
				<p>{description}</p>
				{uiStyle === 'small' && (
					<S.ShortCutButton uiStyle={uiStyle}>
						<p>바로가기</p>
						<Image srcArray={S.shortcut_list} />
					</S.ShortCutButton>
				)}
			</S.Container>
		);
	},
);

export default CardHoverOverlay;
