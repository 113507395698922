/**
 * query Param Hook
 * @module Hook
 */

import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/** params를 좀 더 쉽게 받아오기 위한 훅
 * - 사용 예시
 * const queryParams = useQueryParams();
 * const uuid = queryParams.get('uuid');
 */
const useQueryParams = () => {
	const location = useLocation();
	return useMemo(() => {
		return new URLSearchParams(location.search);
	}, [location.search]);
};

/** 원하는 query를 삭제하는 훅 */
const useRemoveQueryParam = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// useCallback을 사용하여 함수를 메모이제이션합니다.
	const removeQueryParam = useCallback(
		(query) => {
			const queryParams = new URLSearchParams(location.search);
			queryParams.delete(query); // query 파라미터 제거
			navigate(
				{
					pathname: location.pathname,
					search: queryParams.toString(),
				},
				{ replace: true },
			);
		},
		[navigate, location], // 의존성 배열에 navigate와 location을 추가합니다.
	);

	return removeQueryParam;
};

export { useQueryParams, useRemoveQueryParam };
