import styled, { css } from 'styled-components';
import { Box } from './DockLikuController.style';

import zoom_in from '../../../../assets/image/zoom/zoom_in.webp';
import zoom_in2x from '../../../../assets/image/zoom/zoom_in@2x.webp';
import zoom_in3x from '../../../../assets/image/zoom/zoom_in@3x.webp';

import zoom_out from '../../../../assets/image/zoom/zoom_out.webp';
import zoom_out2x from '../../../../assets/image/zoom/zoom_out@2x.webp';
import zoom_out3x from '../../../../assets/image/zoom/zoom_out@3x.webp';

import play from '../../../../assets/image/play/play.webp';
import play2x from '../../../../assets/image/play/play@2x.webp';
import play3x from '../../../../assets/image/play/play@3x.webp';

import add_list from '../../../../assets/image/add_list/add_list.webp';
import add_list2x from '../../../../assets/image/add_list/add_list@2x.webp';
import add_list3x from '../../../../assets/image/add_list/add_list@3x.webp';

import adjustable from '../../../../assets/image/adjustable/adjustable.webp';
import adjustable2x from '../../../../assets/image/adjustable/adjustable@2x.webp';
import adjustable3x from '../../../../assets/image/adjustable/adjustable@3x.webp';

const zoom_in_list = [zoom_in, zoom_in2x, zoom_in3x];
const zoom_out_list = [zoom_out, zoom_out2x, zoom_out3x];
const play_list = [play, play2x, play3x];
const add_list_list = [add_list, add_list2x, add_list3x];
const adjustable_list = [adjustable, adjustable2x, adjustable3x];

export {
	zoom_in_list,
	zoom_out_list,
	play_list,
	add_list_list,
	adjustable_list,
};

export const SaveMotionBox = styled(Box)`
	transition: all 1.5s ease;
	/* height: 20rem; */
	overflow: hidden;
	height: ${({ isDetail }) => (isDetail ? '40rem' : '20rem')};

	.content {
		padding: 0.8rem;
		${({ theme }) => theme.common.wh('100%', '80%')};
	}

	.toggleZoom {
		padding: 0.6rem;
		border: none;
		border-radius: 4px;
		background-color: #fff;
		aspect-ratio: 1 / 1;
		min-height: 2.4rem;
		${({ theme }) => theme.common.flex()};
	}

	.tooltip {
		position: relative;
		display: block;
	}

	.tooltip .tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: black;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;

		position: absolute;
		z-index: 1;
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
	}

	.tooltip .tooltiptext::after {
		content: ' ';
		position: absolute;
		border-style: solid;
		border-width: 5px;
	}

	.tooltip .tooltip-right {
		top: -5px;
		left: 105%;
	}

	.tooltip .tooltip-right::after {
		top: 50%;
		right: 100%;
		margin-top: -5px;
		border-color: transparent black transparent transparent;
	}
`;

export const TapBar = styled.ul`
	gap: 0.9rem;
	overflow: hidden;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
	${({ theme }) => theme.common.wh('100%', '2.4rem')};

	@media (max-width: 1000px) {
		gap: 0.7rem;
	}
`;

export const Tap = styled.li`
	white-space: nowrap;
	font-family: NotoSansKR-Regular;
	font-size: 1.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: center;
	//padding: 0.3rem 0.5rem;
	padding: 0.5rem 0.7rem;
	border-radius: 4px;
	background-color: ${({ click }) => (click ? '#2ece70' : '#f0f0ef')};
	color: ${({ theme, click }) => (click ? '#fff' : theme.color.subTitle)};
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('auto', '100%')};

	@media (max-width: 1000px) {
		font-size: 1.2rem;
		padding: 0.3rem 0.5rem;
	}
`;

export const MotionWrap = styled.div`
	margin-top: 0.8rem;
	overflow-y: auto;
	gap: 0.8rem;
	${({ theme }) => theme.common.wh('100%')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'flex-start')};
`;
export const Motion = styled.div`
	//background-color: #7e84a3;
	//min-height: 3.2rem;
	position: relative;
	max-height: 15.2rem;
	transition: all 0.3s ease;
	${({ theme }) => theme.common.flex('row', 'flex-start', 'space-between')};
	${({ theme }) => theme.common.wh('100%', 'auto')};

	& button {
		background-color: transparent;
		border: none;
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('auto', '4rem')};
	}

	.info {
		overflow: hidden;
		padding: 0.7rem 0.4rem 0.7rem 0.4rem;
		border-radius: 4px;
		border: solid 1px #ebebe9;
		background-color: #fff;
		font-family: NotoSansKR-Regular;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		min-height: 3.2rem;
		max-height: 15.2rem;
		gap: 0.7rem;
		color: ${({ theme }) => theme.color.gray_a8};

		${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
		${({ theme }) => theme.common.wh('20.5rem', '100%')};

		.main {
			padding-left: 0.8rem;
			${({ theme }) => theme.common.flex('row', 'flex-start', 'space-between')};
			${({ theme }) => theme.common.wh('100%', 'auto')};
		}

		.sub {
			overflow-x: hidden;
			overflow-y: auto;
			gap: 0.4rem;
			${({ theme }) => theme.common.flex('column', 'flex-start', 'flex-start')};
			${({ theme }) => theme.common.wh('100%', 'auto')};
		}
	}

	.detail {
		${({ theme }) => theme.common.flex('row', 'flex-start', 'space-between')};
		${({ theme }) => theme.common.wh('100%', '100%')};
	}

	.name {
		min-width: 7.4rem;
	}

	.motion {
		//z-index: 1;
		gap: 0.4rem;
		${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
		${({ theme }) => theme.common.wh('auto', 'auto')};

		& div {
			white-space: nowrap;
			padding: 0 0.3rem;
			border-radius: 4px;
			font-family: NotoSansKR-Regular;
			font-size: 1.5rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.5rem;
			letter-spacing: normal;
			text-align: center;
			color: #fff;
			min-height: 1.8rem;
			${({ theme }) => theme.common.flex()};
			${({ theme }) => theme.common.wh('auto', '100%')};

			&.action {
				background-color: #82affa;
			}
			&.display {
				background-color: #fe80ab;
			}
			&.scenario {
				min-width: 1.8rem;
				background-color: #bf80fe;
			}
			&.delete {
				min-width: 1.8rem;
				background-color: tomato;
			}
		}
	}

	.scenarioName {
		position: absolute;
		left: 4rem;
		top: 0.7rem;
		font-size: 1.5rem;
		color: black;
	}

	.btnContainer {
		${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
		${({ theme }) => theme.common.wh('fit-content', 'auto')};

		text-align: center;
		button {
			width: 100%;
			color: gray;
			font-weight: 800;
			//font-size: 1.7rem;
			&:hover {
				color: black;
			}
		}
	}
	@media (max-width: 1000px) {
		& button {
			${({ theme }) => theme.common.wh('auto', '3.2rem')};
		}

		.info {
			overflow: hidden;
			padding: 0.7rem 0.4rem 0.7rem 0.4rem;
			border-radius: 4px;
			border: solid 1px #ebebe9;
			background-color: #fff;
			font-family: NotoSansKR-Regular;
			font-size: 1.2rem;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			min-height: 3.2rem;
			max-height: 15.2rem;
			gap: 0.7rem;
			color: ${({ theme }) => theme.color.gray_a8};

			${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
			${({ theme }) => theme.common.wh('16.4rem', '100%')};

			.main {
				padding-left: 0.8rem;
				${({ theme }) =>
					theme.common.flex('row', 'flex-start', 'space-between')};
				${({ theme }) => theme.common.wh('100%', 'auto')};
			}

			.sub {
				overflow-x: hidden;
				overflow-y: auto;
				gap: 0.4rem;
				${({ theme }) =>
					theme.common.flex('column', 'flex-start', 'flex-start')};
				${({ theme }) => theme.common.wh('100%', 'auto')};
			}
		}

		.detail {
			${({ theme }) => theme.common.flex('row', 'flex-start', 'space-between')};
			${({ theme }) => theme.common.wh('100%', '100%')};
		}

		.name {
			min-width: 7.4rem;
		}

		.motion {
			//z-index: 1;
			gap: 0.4rem;
			${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
			${({ theme }) => theme.common.wh('auto', 'auto')};

			& div {
				white-space: nowrap;
				padding: 0 0.3rem;
				border-radius: 4px;
				font-family: NotoSansKR-Regular;
				font-size: 1.2rem;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 0.5rem;
				letter-spacing: normal;
				text-align: center;
				color: #fff;
				min-height: 1.8rem;
				${({ theme }) => theme.common.flex()};
				${({ theme }) => theme.common.wh('auto', '100%')};

				&.action {
					background-color: #82affa;
				}
				&.display {
					background-color: #fe80ab;
				}
				&.scenario {
					min-width: 1.8rem;
					background-color: #bf80fe;
				}
				&.delete {
					min-width: 1.8rem;
					background-color: tomato;
				}
			}
		}

		.scenarioName {
			position: absolute;
			left: 4rem;
			top: 0.7rem;
			font-size: 1.2rem;
			color: black;
		}

		.btnContainer {
			${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
			${({ theme }) => theme.common.wh('fit-content', 'auto')};

			text-align: center;
			button {
				width: 100%;
				color: gray;
				font-weight: 800;
				&:hover {
					color: black;
				}
			}
		}
	}
`;

export const SubMotion = styled.div`
	${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
	${({ theme }) => theme.common.wh('100%', '3.2rem')};

	.number {
		font-family: NotoSansKR-Regular;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		text-align: center;
		color: #707070;
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('2rem', '100%')};
	}

	.info {
		white-space: nowrap;
		border-radius: 4px;
		border: solid 1px #ebebe9;
		background-color: #fff;
		padding-left: 1.2rem;

		font-family: NotoSansKR-Regular;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #353535;

		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('15rem', '100%')};
		//('11.5rem', '100%')
	}

	& button {
		//padding: 0.02rem 0.06rem;
		border-radius: 6px;
		border: solid 1px #ebebe9;
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('1.5rem', '2.5rem')};
	}

	img {
		${({ theme }) => theme.common.wh('90%')};
	}

	@media (max-width: 1000px) {
		.number {
			font-size: 1.2rem;
		}

		.info {
			font-size: 1.2rem;
			${({ theme }) => theme.common.wh('11.5rem', '100%')};
		}

		& button {
			${({ theme }) => theme.common.wh('1.2rem', '2rem')};
		}
	}
`;

export const PlayImage = css`
	//background-color: #61dafb;
	padding: 0.5rem 0.2rem 0.5rem 0;
	${({ theme }) => theme.common.wh('100%')};
`;
export const AddListImage = css`
	padding: 0.6em;
	//background-color: #61dafb;
	${({ theme }) => theme.common.wh('100%')};
`;
