import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const global = createGlobalStyle`
  ${reset}; 

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    
    
    ::-webkit-scrollbar {
      width: 0.4em;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  html {
    //width: 100%;
    //height: 100%;
    /* font-size: 100%; */
    user-select: none;
    font-size: ${({ theme }) => (theme.isBigMode ? '100%' : '62.5%')};
  }
  
  body {
    font-size: 1.6rem;
    font-family: NotoSansKR-Regular;
    background-color: ${({ theme }) => theme.color.background};
  }

  button {
    &:hover {
      class: 'hovered';
    }
  }

  input {

  }

  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    color: transparent;
  }

  @keyframes shimmer {
    from {
      background-position: -200% 0;
    } 
    to {
      background-position: 200% 0;
    }
  }
`;

export default global;
