import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { reaction, runInAction } from 'mobx';

import * as S from './SaveMotion.style';

import { RootStoreContext } from '../../../../App';

import Image from '../../../image/Image';

import useMotionQuery from '../../../../hooks/queries/useMotionQuery';

import { ACTIONS, TAPS } from '../../../../data/motion';

const Motion = ({ motion }) => {
	const { likuStore, motionStore } = useContext(RootStoreContext);
	const { data } = useMotionQuery(motion.uuid);

	return (
		<S.Motion>
			<div className='start'>
				<button
					onClick={() => {
						const tempData = { ...data };
						console.log('data@#@#', tempData);
						if (data?.speech && !data?.speech?.TTS) {
							tempData.speech.TTS = motion?.name;
						} else {
							likuStore.playJson(tempData);
						}
					}}>
					<Image srcArray={S.play_list} custom={S.PlayImage} />
				</button>
				{/*{motion.type === 'SCENARIO' ? (*/}
				{/*	<button*/}
				{/*		onClick={() => {*/}
				{/*			const tempData = { ...data };*/}
				{/*			console.log('시나리오*******', tempData);*/}
				{/*			//motionStore.setModifyMotion(motion, tempData);*/}
				{/*		}}>*/}
				{/*		{'클릭'}*/}
				{/*	</button>*/}
				{/*) : (*/}
				{/*	<></>*/}
				{/*)}*/}
				{motion.type === 'CUSTOM' ? (
					<button
						onClick={() => {
							const tempData = { ...data };
							console.log('data*******', tempData);
							motionStore.setModifyMotion(motion, tempData);
						}}>
						{'수정'}
					</button>
				) : (
					<></>
				)}
			</div>
			<div className='info'>
				<div className='main'>
					<span className='name'>{motion.name}</span>
					{motion.type === 'CUSTOM' && (
						<div className='motion'>
							<div className='action'>
								{ACTIONS[data?.action?.action_name]?.name ||
									ACTIONS[data?.action?.action_name]}
							</div>
							<div className='display'>
								{data?.display?.display_name === 'defaultArray'
									? '표정 자동'
									: data?.display?.display_name}
							</div>
						</div>
					)}
					{motion.type === 'SCENARIO' && (
						<div className='motion'>
							<div className='scenario'>표현 묶음</div>
						</div>
					)}
				</div>
				{motion.type === 'SCENARIO' && (
					<div className='sub'>
						<span className='scenarioName'>{motion?.scenario?.name}</span>
						{motion.isDetail &&
							motion?.motions?.map((motion, index) => {
								return (
									<S.SubMotion key={`${motion?.info}-${index}`}>
										<span className='number'>{index + 1}</span>
										<div className='info'>{motion?.info}</div>
										<button>
											<Image srcArray={S.adjustable_list} />
										</button>
									</S.SubMotion>
								);
							})}
					</div>
				)}
			</div>
			<div className='btnContainer'>
				<button
					onClick={() => {
						console.log('myMotion', motion, data?.name);
						if (motion?.motions?.length) {
							motion?.motions?.map((tempMotion) => {
								if (tempMotion?.speech && !tempMotion?.speech?.TTS) {
									tempMotion.speech.TTS = data?.name;
								}
								motionStore.setPlayList(tempMotion);
							});
						} else {
							if (motion?.speech && !motion?.speech?.TTS) {
								motion.speech.TTS = data?.name;
							}
							motionStore.setPlayList(motion);
						}
					}}>
					<Image srcArray={S.add_list_list} custom={S.AddListImage} />
				</button>
				{motion.type === 'CUSTOM' || motion.type === 'SCENARIO' ? (
					<button
						onClick={() => {
							console.log('delMotion', motion);
							if (motion.type === 'SCENARIO') {
								motionStore.deleteScenario(motion.scenario.id);
							} else {
								motionStore.deleteMotion(motion.uuid);
							}
						}}>
						{'x'}
					</button>
				) : (
					<></>
				)}
			</div>
		</S.Motion>
	);
};

const SaveMotion = observer(() => {
	const { motionStore } = useContext(RootStoreContext);

	const [isZoom, setIsZoom] = useState(false);
	const [isDetail, setIsDetail] = useState(false);
	const [choseTap, setChoseTap] = useState('BASIC');
	const [motionList, setMotionList] = useState([]);
	const [scenarioList, setScenarioList] = useState([]);
	const [render, setRender] = useState(false);

	const updateMotionList = (_choseTap = 'BASIC') => {
		const list = motionStore.getMotions(_choseTap);
		console.log('list', list, _choseTap);
		console.log('list', motionStore.scenarios);
		setMotionList(list);
		setScenarioList(motionStore.scenarios);
	};

	useEffect(() => {
		const dispose = reaction(
			() => motionStore.motions,
			() => {
				console.log('reaction updateMotionList');
				updateMotionList(choseTap);
				setRender(!render);
			},
		);

		return () => dispose();
	}, [choseTap]);

	useEffect(() => {
		console.log('list choseTap', choseTap);
		updateMotionList(choseTap);
	}, [choseTap, render]);

	useEffect(() => {
		if (motionStore.isModified) {
			runInAction(() => {
				motionStore.isModified = false;
			});
			setMotionList([]);
			setRender(!render);

			console.log('CHANGE MOTIONS');
		}
	}, [motionStore.isModified]);

	const taps = TAPS.map(({ key, name }) => (
		<S.Tap key={key} click={key === choseTap} onClick={() => setChoseTap(key)}>
			{name}
		</S.Tap>
	));

	const motions = motionList?.map((motion, index) => (
		<Motion
			chooseTap={choseTap}
			key={`${motion.uuid}_${index}`}
			motion={motion}
		/>
	));

	const scenarios = scenarioList?.map((scenario, index) => {
		if (scenario?.motions?.length === 0) return;
		return (
			<Motion
				chooseTap={choseTap}
				key={`${scenario.uuid}_${index}`}
				motion={{ ...scenario, type: 'SCENARIO', isDetail: isDetail }}
			/>
		);
	});

	return (
		<S.SaveMotionBox className='light' isDetail={isDetail}>
			<div className='title'>
				{/*<div className='tooltip'>*/}
				{/*	저장된 표현*/}
				{/*	<span className='tooltiptext tooltip-right'>툴팁</span>*/}
				{/*</div>*/}
				저장된 표현
				<button className='toggleZoom' onClick={() => setIsDetail(!isDetail)}>
					<Image srcArray={isDetail ? S.zoom_out_list : S.zoom_in_list} />
				</button>
			</div>
			<div className='content'>
				<S.TapBar>{taps}</S.TapBar>
				<S.MotionWrap>
					{choseTap === 'CUSTOM' && scenarios}
					{motions}
				</S.MotionWrap>
			</div>
		</S.SaveMotionBox>
	);
});

export default SaveMotion;
