import styled, { css } from 'styled-components';

import search from '../../../assets/image/search/search.webp';
import search2x from '../../../assets/image/search/search@2x.webp';
import search3x from '../../../assets/image/search/search@3x.webp';

const search_list = [search, search2x, search3x];

export { search_list };

const searchBarWidthResponsive = css`
	${({ theme }) => theme.device.mobile} {
		width: 44rem;
	}

	${({ theme }) => theme.device.tablet} {
		width: 38rem;
	}

	${({ theme }) => theme.device.desktop} {
		width: 52rem;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
`;

export const SeachInputContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const SearchBarWrapper = styled.div`
	width: 100%;
	height: 4rem;
	border-radius: 2rem;
	margin: auto;
	text-align: left;
	background-color: ${({ theme }) => theme.color.gray_f5};
	padding: 8px 20px;
	display: none;
	align-items: center;
	overflow: hidden;
	${({ theme }) => theme.device.mobile} {
		display: flex;
	}
	transition: all 0.3s;
	filter: drop-shadow(0 0 1px black);
	&:focus-within {
		filter: drop-shadow(0 0 3px black);
	}

	${searchBarWidthResponsive}
`;

export const SearchInput = styled.input`
	width: 100%;
	border: none;
	font-family: 'NotoSansKR-Regular';
	background-color: transparent;
	outline: none;
	caret-color: ${({ theme }) => theme.color.bold};
	caret-shape: 'underscore';
	flex: 1;
	overflow: hidden;
	&::selection,
	&:focus {
		border: none;
	}
	&::selection {
		background-color: ${({ theme }) => theme.color.gold};
		color: white;
	}
	&::placeholder {
		color: ${({ theme }) => theme.color.gray_91};
	}
`;

export const SearchButton = styled.div`
	position: absolute;
	right: 0;
	width: 6rem;
	height: 100%;
	padding: 0.8rem;
	background-color: ${({ theme }) => theme.color.gold};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

// export const SearchSuggestions = styled.div`
// 	position: relative;
// 	display: ${({ focused }) => (focused ? 'flex' : 'none')};
// 	flex-direction: column;
// 	justify-content: center;
// 	position: absolute;
// 	top: calc(100% + 2rem);
// 	left: 75%;

// 	background-color: rgba(255, 255, 255, 0.9);
// 	& > div:hover {
// 		background-color: rgba(200, 200, 200, 0.9);
// 	}
// `;

// export const PortalWord = styled.p`
// 	font-size: 3rem;
// 	font-weight: 800;
// 	white-space: nowrap;
// `;

export const SearchDetailBoxWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const BlackBackground = styled.div`
	display: ${({ isDetailPanelOn }) => (isDetailPanelOn ? 'block' : 'none')};
	position: fixed;
	top: 100px;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100vw;
	left: 0;
	top: 150px;
	z-index: -9999;
`;
