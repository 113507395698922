import { makeAutoObservable, runInAction } from 'mobx';

/**
 * Play 페이지의 필터관련 정보를 담은 스토어
 */
class FilterStore {
	rootStore;
	/** filter 하고자 하는 tag 묶음 */
	filterList = [];
	/** search 에 사용되는 검색어 */
	searchWord = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		this.filterList = [];
		this.searchWord = null;
	}

	resetFilterList() {
		runInAction(() => {
			this.filterList = [];
		});
	}

	/**
	 * - filterList에서 매개변수로 toggle해주는 함수
	 *
	 * @param {string} _filterName FilterList에서 toggle할 필터태그 이름
	 */
	toggleFilter(_filterName) {
		runInAction(() => {
			const index = this.filterList.indexOf(_filterName);
			if (index > -1) {
				// _filterName이 filterList에 존재하면 제거
				this.filterList.splice(index, 1);
			} else {
				// 존재하지 않으면 추가
				this.filterList.push(_filterName);
			}
		});
	}

	setSerachWord(_searchWord) {
		this.serachWord = _searchWord;
	}
}

export default FilterStore;
