import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as S from './AlarmPage.style';
import { getUsers } from '../../services/apis/UserApi';
import { RootStoreContext } from '../../App';
import Image from '../../components/image/Image';
import AlarmContent from './alarmContent/AlarmContent';
import UserSelect from './userSelect/UserSelect';
import AlarmMakeModal from './alarmMakeModal/AlarmMakeModal';
import AlarmFixModal from './alarmMakeModal/AlarmFixModal';
import { getAlarmList } from '../../services/apis/CustomApi';
import DefaultAlarmMakeModal from './alarmMakeModal/DefaultAlarmMakeModal';
import DefaultAlarmFixModal from './alarmMakeModal/DefaultAlarmFixModal';
import DefaultAlarmContent from './alarmContent/DefaultAlarmContent';

const AlarmPage = observer(() => {
	const { authStore, alarmStore } = useContext(RootStoreContext);
	const [users, setUsers] = useState([]);
	const [makeModal, setMakeModal] = useState(false);
	const [fixModal, setFixModal] = useState(false);
	const [defaultMakeModal, setDefaultMakeModal] = useState(false);
	const [defaultFixModal, setDefaultFixModal] = useState(false);

	const getList = async () => {
		const list = await getAlarmList();
		list.sort((a, b) => a.id - b.id);
		alarmStore.setAlarmList(list);
	};

	useEffect(() => {
		if (!authStore?.auth?.collectionName) return;
		(async () => {
			const users = await getUsers({
				collectionName: authStore?.auth?.collectionName,
			});
			setUsers(users);
			alarmStore.setUsers(users);
			await getList();
		})();
	}, [authStore?.auth?.collectionName, alarmStore?.selectedUser]);

	return (
		<S.Container>
			{makeModal && (
				<S.MakeAlarmModal>
					<AlarmMakeModal setMakeModal={setMakeModal} onLoad={getList} />
				</S.MakeAlarmModal>
			)}
			{fixModal && (
				<S.MakeAlarmModal>
					<AlarmFixModal
						defaultValue={fixModal}
						setFixModal={setFixModal}
						onLoad={getList}
					/>
				</S.MakeAlarmModal>
			)}
			{defaultMakeModal && (
				<S.MakeAlarmModal>
					<DefaultAlarmMakeModal
						setMakeModal={setDefaultMakeModal}
						onLoad={getList}
					/>
				</S.MakeAlarmModal>
			)}
			{defaultFixModal && (
				<S.MakeAlarmModal>
					<DefaultAlarmFixModal
						defaultValue={defaultFixModal}
						setFixModal={setDefaultFixModal}
						onLoad={getList}
					/>
				</S.MakeAlarmModal>
			)}
			<S.UserBox>
				<UserSelect users={users}></UserSelect>
			</S.UserBox>
			<S.AlarmBox>
				<S.AlarmTitle>
					<div>
						<Image srcArray={[S.calendar]}></Image>
						<p>복약 알람</p>
					</div>
				</S.AlarmTitle>
				{Array.isArray(alarmStore?.alarmList) &&
					alarmStore?.alarmList
						?.filter((alarm) => {
							if (alarm.category === 'medicine') {
								if (
									alarmStore?.selectedUser === '전체' ||
									alarmStore?.selectedUser === null
								) {
									return true;
								} else if (alarmStore?.selectedUser === '공통') {
									return alarm?.face_users?.length === users.length;
								}
								return alarm?.face_users
									?.map((user) => user.id)
									?.includes(alarmStore?.selectedUser?.id);
							}
						})
						?.map((alarm, index) => {
							return (
								<AlarmContent
									onClick={() => {
										setFixModal(alarm);
									}}
									key={`meal_${index}`}
									index={index}
									alarm={alarm}
									users={users}
									getList={getList}
								/>
							);
						})}
				<S.MakeAlarmBox
					onClick={() => {
						setMakeModal(true);
					}}>
					새로운 알람 추가 +
				</S.MakeAlarmBox>
			</S.AlarmBox>
			<S.AlarmBox>
				<S.AlarmTitle>
					<div>
						<Image srcArray={[S.calendar]}></Image>
						<p>기본알람</p>
					</div>
				</S.AlarmTitle>
				{Array.isArray(alarmStore?.alarmList) &&
					alarmStore?.alarmList
						?.filter((alarm) => {
							if (alarm.category === 'schedule') {
								if (
									alarmStore?.selectedUser === '전체' ||
									alarmStore?.selectedUser === null
								) {
									return true;
								} else if (alarmStore?.selectedUser === '공통') {
									return alarm?.face_users?.length === users.length;
								}
								return alarm?.face_users
									?.map((user) => user.id)
									?.includes(alarmStore?.selectedUser?.id);
							}
						})
						?.map((alarm, index) => {
							return (
								<DefaultAlarmContent
									onClick={() => {
										setDefaultFixModal(alarm);
									}}
									key={`schedule_${index}`}
									index={index}
									alarm={alarm}
									users={users}
									getList={getList}
								/>
							);
						})}
				<S.MakeAlarmBox
					onClick={() => {
						setDefaultMakeModal(true);
					}}>
					새로운 알람 추가 +
				</S.MakeAlarmBox>
			</S.AlarmBox>
			{/*<S.AlarmBox style={{ visible: 'hidden' }}>*/}
			{/*	<S.AlarmTitle>*/}
			{/*		<div>*/}
			{/*			<Image srcArray={[S.play]}></Image>*/}
			{/*			<p>콘텐츠 예약</p>*/}
			{/*		</div>*/}
			{/*	</S.AlarmTitle>*/}
			{/*	<S.MakeAlarmBox*/}
			{/*		onClick={() => {*/}
			{/*			setMakeModal(true);*/}
			{/*		}}>*/}
			{/*		새로운 알람 추가 +*/}
			{/*	</S.MakeAlarmBox>*/}
			{/*</S.AlarmBox>*/}
		</S.Container>
	);
});

export default AlarmPage;
