import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	width: fit-content;
	display: flex;
	border-radius: 12px;
	border: 1px solid #f0f0f0;
	padding: 3px 4px;
	font-family: 'NotoSansKR-Regular';
	white-space: nowrap;
	font-size: 16px;
	color: #707070;
	gap: 1rem;
`;

export const Input = styled.input``;

export const Ul = styled.ul`
	z-index: 10;
	position: absolute;
	top: 115%;
	left: 0;
	height: 100px;
	overflow-y: scroll;
	background-color: white;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	border-right: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
	border-left: 1px solid #e0e0e0;

	display: ${({ open }) => (open ? 'block' : 'none')};

	& > li {
		border-bottom: 1px dashed #e0e0e0;
		padding: 1rem 2rem;
	}

	& > li:hover {
		background-color: #707070;
		color: #fff;
	}

	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

export const TimeBox = styled.div`
	position: relative;
`;

export const Viewer = styled.div`
	border-radius: 12px;
	background-color: #ebebe9;
	width: 100%;
	height: 34px;
	padding: 5px 13px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
