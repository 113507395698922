// import styled, { keyframes } from 'styled-components';
import styled, { css } from 'styled-components';

import search from '../../../assets/image/search/search-color.webp';
import search2x from '../../../assets/image/search/search-color@2x.webp';
import search3x from '../../../assets/image/search/search-color@3x.webp';

const search_list = [search, search2x, search3x];

export { search_list };

export const Container = styled.div`
	position: absolute;
	border-radius: 2rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
	transition: all 1.5s;
	bottom: ${({ click }) => (click ? '2rem' : '4.4rem')};
	min-height: ${({ click, isNew }) =>
		!click ? '0rem' : isNew ? '17rem' : '32rem'};
	${({ theme }) => theme.common.wh('100%', 'auto')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'flex-start')};
`;

export const Context = styled.div`
	transition: all 1.5s;
	width: 100%;
`;

export const Top = styled.div`
	transition: all 1.5s;
	border-bottom: 1.5px solid ${({ theme }) => theme.color.regular};
	${({ theme, click }) => theme.common.wh('100%', click ? '4.8rem' : '0')};
	${({ theme }) => theme.common.flex('row', 'center', 'space-between')};

	input {
		flex: 1;
		outline: 0;
		border: none;
		padding-right: 1rem;
		${({ theme }) => theme.common.wh('auto', '100%')};
	}

	img {
		padding: 1.2rem;
		${({ theme }) => theme.common.wh('auto', '100%')};
	}
`;
// const sizeAnimation = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(-5%);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

export const NewLiku = styled.div`
	padding: 1.55rem 2.65rem 8rem 2.25rem;
	box-sizing: border-box;
	${({ theme }) => theme.common.wh('100%', '18.8rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'space-between')};
`;

export const RegistrButton = styled.button`
	margin: 0 auto;
	border-radius: 12px;
	font-family: NotoSansKR-Bold;
	font-size: 1.2rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	${({ theme }) => theme.common.wh('8rem', '2.4rem')};
	${({ theme }) => theme.common.flex()};
	${({ theme, disable }) =>
		disable
			? `
						border: solid 1px ${theme.color.gray_f4};
						color: ${theme.color.gray_f4};
						background-color: #fff;
					`
			: `
						border: none;
						color: #fff;
						background-color: ${theme.color.gold};
			`}
`;

export const Example = styled.span`
	font-family: NotoSansKR-Light;
	font-size: 1.2rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.13;
	letter-spacing: normal;
	text-align: left;
	color: #ababab;
	${({ theme }) => theme.common.wh('100%', '1rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
`;

export const SerialInput = styled.input`
	border-radius: 1rem;
	border: solid 1px #e4e4e4;
	background-color: #fff;
	padding: 1.2rem 2.5rem 1.1rem 2.5rem;
	box-sizing: border-box;
	font-family: NotoSansKR-Light;
	font-size: 1.2rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	outline: none;
	color: ${({ theme }) => theme.color.subTitle};
	${({ theme }) => theme.common.wh('100%', '3.8rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'space-between')};
`;

export const LikuWrap = styled.div`
	overflow-y: auto;
	display: grid;
	gap: 1.5rem;
	transition: all 1.5s;
	max-height: ${({ click }) => (click ? '24rem;' : '0')};
	padding: ${({ click }) => (click ? '1.6rem 1.85rem 5rem 1.95rem;' : '0')};
	${({ theme }) => theme.common.wh('100%')};
`;

export const LikuButton = styled.div`
	border: none;
	border-radius: 1rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16),
		inset -1px -1px 2px 0 rgba(0, 0, 0, 0.16);
	background-color: #f5f5f5;
	//margin-bottom: 1.5rem;
	//min-height: 4.5rem;
	padding: 0 2rem;
	box-sizing: border-box;
	${({ theme }) => theme.common.wh('100%', '4.5rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'space-between')};

	div {
		${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
	}

	span {
		font-family: Helvetica-Regular;
		font-size: 1.2rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #444b54;
		margin: 0 0 1% 0.88rem;
		&.version {
			font-size: 1rem;
		}
	}

	button {
		background-color: transparent;
		border: none;
		border-radius: 50px;
		aspect-ratio: 1 / 1;
		${({ theme }) => theme.common.wh('auto', '100%')};
	}
`;

export const Liku = css`
	object-fit: cover;
	${({ theme }) => theme.common.wh('1.55rem', '1.25rem')};
`;

export const AddButton = styled.button`
	background-color: #f7f7f9;
	border: none;
	border-radius: 1.6rem;
	margin-right: 1rem;
	font-family: NotoSansKR-Bold;
	font-size: 1.2rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #444b54;
	${({ theme }) => theme.common.wh('8rem', '3.2rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;
