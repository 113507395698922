import { makeAutoObservable, runInAction, when } from 'mobx';

import * as Cookie from '../../utils/Cookie';

import { Auth } from './Auth';

class AuthStore {
	rootStore;
	auth = null;
	auto;

	constructor(root) {
		makeAutoObservable(this);

		this.rootStore = root;
		when(() => !!this.auth);
		// this.login();
	}

	isLogin() {
		const isToken = Cookie.get('token');
		const isProfile = Cookie.get('profile');
		if (!isToken && !isProfile) Cookie.removeAll();

		return isToken && isProfile;
	}

	isEmail(email) {
		const emailRegex = new RegExp(
			/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/,
		);
		const result = emailRegex.test(email);

		return result;
	}

	isPassword(password) {
		// const EngRegex = new RegExp(/[a-zA-Z]/);
		// const NumRegex = new RegExp(/[0-9]/);
		// const SpecialRegex = new RegExp(/[$@$!%*?&]/);
		// const isEngPassword = EngRegex.test(password);
		// const isNumPassword = NumRegex.test(password);
		// const isSpecialPassword = SpecialRegex.test(password);
		//
		// const passwordLength = password.length;
		// const result =
		// 	passwordLength >= 8 &&
		// 	passwordLength <= 20 &&
		// 	((isNumPassword && isEngPassword) ||
		// 		(isEngPassword && isSpecialPassword) ||
		// 		(isSpecialPassword && isNumPassword));
		//
		// return result;

		const passwordLength = password.length;
		return passwordLength > 0 ? true : false;
	}

	// async existsEmail(email) {
	// 	Cookie.set(
	// 		'token',
	// 		'eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1NDg4OTY5MzIsImlhdCI6MTY4NDg5NjkzMiwianRpIjoidG9yb29jQHRvcm9vYy5jb20ifQ.VtjgjnLHaWqQxea5AzaNV8O17_c67aL9JSJdN29ROvDACTfR4eUx9ezkZWphlNO_8hdV-qV_0KCiqiI2GbAcZw',
	// 		{ path: '/', maxAge: 86400 },
	// 	);
	// 	const result = await AuthApi.findMemberByEmail(email);
	// 	Cookie.remove('token');
	//
	// 	return result;
	// }

	isAuthInput(authData) {
		const { email, password } = authData;

		const isEmail = this.isEmail(email);
		const isPassword = this.isPassword(password);

		if (isEmail && isPassword) return true;

		return false;
	}

	logout() {
		this.auth = null;
		Cookie.removeAll();
		localStorage.removeItem('profiles');
	}

	async loadAuth(auth = {}) {
		if (Object.keys(auth).length > 0) {
			Cookie.set('id', auth.id, { path: '/', maxAge: 86400 });
			Cookie.set('usage', auth.usage, { path: '/', maxAge: 86400 });
			Cookie.set('name', auth.name, { path: '/', maxAge: 86400 });
			Cookie.set('role', auth.roles, { path: '/', maxAge: 86400 });
			Cookie.set('token', auth.token, { path: '/', maxAge: 86400 });
			Cookie.set('collectionName', auth.collectionName, {
				path: '/',
				maxAge: 86400,
			});
			Cookie.set('type', auth.type, { path: '/', maxAge: 86400 });
			// Cookie.set('profiles', auth.profiles, { path: '/', maxAge: 86400 });
			localStorage.setItem('profiles', JSON.stringify(auth.profiles));
			if (auth.profile) {
				Cookie.set('profile', auth.profile, { path: '/', maxAge: 86400 });
			}
			if (auth.parentId) {
				Cookie.set('parentId', auth.parentId, { path: '/', maxAge: 86400 });
			}
		} else {
			auth.id = Cookie.get('id');
			auth.usage = Cookie.get('usage');
			auth.name = Cookie.get('name');
			auth.role = Cookie.get('role');
			auth.token = Cookie.get('token');
			auth.collectionName = Cookie.get('collectionName');
			// auth.profiles = Cookie.get('profiles');
			auth.profiles = JSON.parse(localStorage.getItem('profiles'));
			auth.profile = Cookie.get('profile');
			auth.parentId = Cookie.get('parentId');
			Cookie.refresh();
		}

		runInAction(() => {
			this.auth = new Auth(this, auth);
		});
		await this.rootStore.likuStore.loadLiku();
		await this.rootStore.motionStore.loadMotion();
	}

	updateAuth(data) {
		this.auth.setProfile(data.profile);
		this.loadAuth({ ...this.auth, profile: data.profile });
	}

	updateProfiles(profile) {
		const newProfiles = [...this.auth.profiles, profile];
		this.auth.setProfiles(newProfiles);
	}
}

export default AuthStore;
