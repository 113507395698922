import axios from 'axios';
import * as Cookie from '../../utils/Cookie';

// const BASE_URL = 'https://api.likuwithme.com/liku';
// const BASE_URL = 'http://3.38.179.203:80/liku';
// const BASE_URL = 'https://test.api.likuwithme.com/liku';
// const BASE_URL = 'http://43.200.2.139:81/liku';
const BASE_URL =
	'http://alb-ec2-instances-with-asg-1108758483.ap-northeast-2.elb.amazonaws.com/liku';
// const TEST_URL = 'http://43.202.13.88/liku';
// const BASE_URL = TEST_URL;
const url = new URL(BASE_URL);
const BASE_ORIGIN = `${url.protocol}//${url.host}`;

const axiosApi = (options) => {
	const api = axios.create({
		baseURL: `${BASE_URL}`,
		headers: {
			'Access-Control-Allow-Origin': BASE_ORIGIN,
			'Access-Control-Allow-Credentials': 'true',
		},
		// withCredentials: true,
		...options,
	});
	return api;
};

const axiosAuthApi = (options) => {
	const api = axios.create({
		baseURL: `${BASE_URL}`,
		headers: {
			Authorization: `Bearer ${Cookie.get('token')}`,
			'Access-Control-Allow-Origin': BASE_ORIGIN,
			'Access-Control-Allow-Credentials': 'true',
		},
		// withCredentials: true,
		...options,
	});
	interceptors(api);
	return api;
};

const axiosJsonAuthApi = (options) => {
	const api = axios.create({
		baseURL: `${BASE_URL}`,
		headers: {
			Authorization: `Bearer ${Cookie.get('token')}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': BASE_ORIGIN,
			'Access-Control-Allow-Credentials': 'true',
		},
		...options,
	});
	interceptors(api);
	return api;
};

const interceptors = (instance) => {
	instance.interceptors.request.use(
		(config) => {
			const token = Cookie.get('token');

			config.headers = {
				authorization: token ? `Bearer ${token}` : null,
			};
			return config;
		},
		(error) => Promise.reject(error.response),
	);

	// 응답 인터셉터 추가
	instance.interceptors.response.use(
		(response) => {
			// 정상 응답 처리
			return response;
		},
		async (error) => {
			// 401 Unauthorized 오류 처리
			if (error.response && error.response.status === 401) {
				// 로그인 페이지로 리디렉션
				Cookie.removeAll();
				window.location = '/login';
			}

			// 다른 오류들은 그대로 전달
			return Promise.reject(error);
		},
	);
	return instance;
};

export const defaultApi = axiosApi();
export const authApi = axiosAuthApi();
export const authJsonApi = axiosJsonAuthApi();

export const createParams = (request) => {
	const params = new URLSearchParams();
	for (let key of Object.keys(request)) {
		if (request[key]) {
			// console.log('key', key);
			// console.log('request', request[key]);
			params.append(key, request[key]);
		}
	}

	return params;
};
