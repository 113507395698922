import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import * as S from './CategoryDetailBox.style';
import CategoryItem from './CategoryItem';

import { RootStoreContext } from '../../../App';
import useTagListQuery from '../../../hooks/queries/useTagQuery';
import useTagClickHandler from '../../../hooks/useTagClickHandler';
import {
	kidTags,
	schoolTags,
	seniorTags,
	categoryTags,
	languageTags,
	englishTags,
	artTags,
	musicTags,
	scienceTags,
	historyTags,
	likuFestivalTags,
	bodyTags,
	mindTags,
	emotionTags,
	safeTags,
} from '../../../data/categoryTagId';
import { useRemoveQueryParam } from '../../../hooks/useQueryParams';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useLocation } from 'react-router-dom';

const CategoryDetailBox = observer(
	({
		isHovered,
		setIsHovered,
		categoryID,
		setIsMouseOnBlackBackground,
		setMainCategory,
	}) => {
		const { authStore } = useContext(RootStoreContext);

		const location = useLocation();
		const searchParams = new URLSearchParams(location.search);
		const categoryRefId = 'categoryFilterWheel';

		const tagClickHandler = useTagClickHandler('normal');
		const { data: tags } = useTagListQuery();

		const removeQueryParam = useRemoveQueryParam();
		const queryParams = useQueryParams();
		const uuid = queryParams.get('uuid');

		const [isSenior, setIsSenior] = useState(false);
		const [recommendedTags, setRecommendedTags] = useState([]);
		const [selectedTag, setSelectedTag] = useState([]);

		useEffect(() => {
			const isSenior = authStore.auth?.role?.some(
				(role) => role.name === 'senior',
			);
			setIsSenior(isSenior);
		}, [authStore.auth]);

		useEffect(() => {
			if (!tags) return;
			if (isSenior) {
				setRecommendedTags(seniorTags);
			} else {
				setRecommendedTags([
					...kidTags,
					...schoolTags,
					...categoryTags,
					...seniorTags,
				]);
			}
		}, [isSenior, tags]);

		const renderRecommendTags = () => {
			recommendedTags?.map((tag, index) => {
				return (
					<div
						className={checkTagContain(tag.id) !== -1 ? 'clicked' : ''}
						key={index}
						onClick={() => {
							tagClickHandler(tag, 'search');
						}}>
						{tag?.name}
					</div>
				);
			});
		};

		// 프로그램 태그에서 중복된 내용 지우기
		const alllProgramTags = [...kidTags, ...schoolTags, ...seniorTags]; // 프로그램 영역에 넣을 태그들 넣기
		const uniqueTags = [...new Set(alllProgramTags.map((tag) => tag.name))];
		console.log('[uniqueTags]', uniqueTags);
		const programTags = uniqueTags.map((tagName) => {
			const { id } = alllProgramTags.find((tag) => tag.name === tagName);

			return { name: tagName, id };
		});

		const tagList = {
			프로그램: programTags,
			카테고리: categoryTags,
			연령: [
				{ name: '5세', id: 77 },
				{ name: '6세', id: 78 },
				{ name: '7세', id: 79 },
			], // 필요한 내용 넣기
			월: [
				{ name: '3월', id: 109 },
				{ name: '4월', id: 110 },
				{ name: '5월', id: 111 },
				{ name: '6월', id: 112 },
				{ name: '7월', id: 113 },
				{ name: '8월', id: 114 },
				{ name: '9월', id: 115 },
				{ name: '10월', id: 116 },
				{ name: '11월', id: 117 },
				{ name: '12월', id: 118 },
				{ name: '1월', id: 107 },
				{ name: '2월', id: 108 },
			],
		};

		const checkTagContain = (_tagId) => {
			const currentSearchIds = searchParams.getAll('search');
			const index = currentSearchIds.indexOf(_tagId?.toString());
			return index;
		};

		const renderTags = (tagType) => {
			const tagsList = tagList[tagType];

			// console.log('[tagList]', tagList);

			return tagsList?.map((tag, index) => (
				<div
					className={checkTagContain(tag.id) !== -1 ? 'tags clicked' : 'tags'}
					key={index}
					onClick={() => {
						tagClickHandler(tag, 'search');
					}}>
					{tag.name}
				</div>
			));
		};

		// 1: 언어, 2: 영어, 3: 미술, 4: 음악, 5: 과학, 6: 역사, 7: 리쿠 행사
		let itemLists = {
			1: languageTags,
			2: englishTags,
			3: artTags,
			4: musicTags,
			5: scienceTags,
			6: safeTags,
			7: historyTags,
			8: likuFestivalTags,
		};

		const seniorLists = {
			1: bodyTags,
			2: mindTags,
			3: emotionTags,
		};

		const renderCategory = (categoryID) => {
			if (authStore.auth?.role?.some((role) => role.name === 'senior')) {
				itemLists = seniorLists;
			}

			return itemLists[categoryID]?.map((item, index) => {
				return (
					<CategoryItem
						key={index}
						item={item}
						content={item['name']}
						checkTagContain={checkTagContain}
						setMainCategory={setMainCategory}
						categoryID={categoryID}
					/>
				);
			});
		};

		return (
			<S.Container
				className='category'
				isHovered={isHovered}
				renderCategory={renderCategory}>
				<S.CategoryContainer>
					<S.CategoryItemsWrapper>
						{renderCategory(categoryID)}
					</S.CategoryItemsWrapper>
				</S.CategoryContainer>
				<S.BlackBackground
					onMouseEnter={() => {
						setIsHovered(false);
						setIsMouseOnBlackBackground(() => {
							setIsMouseOnBlackBackground(true);
						});
					}}
				/>
			</S.Container>
		);
	},
);

export default CategoryDetailBox;
