import { makeAutoObservable, runInAction } from 'mobx';

class GreetStore {
	rootStore;
	greetingList = null;
	askResponseList = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		this.greetingList = null;
		this.askResponseList = null;
	}

	setGreetingList(_list) {
		runInAction(() => {
			this.greetingList = _list;
		});
	}

	setAskResponseList(_list) {
		runInAction(() => {
			this.askResponseList = _list;
		});
	}
}

export default GreetStore;
