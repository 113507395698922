/**
 * custom Hook
 * @module Hook
 */

import { useState } from 'react';

export const useClick = (initialValue) => {
	const [value, setValue] = useState(initialValue);
	const [timer, setTimer] = useState(null);

	const handleClick = (_value) => {
		if (timer) {
			clearTimeout(timer);
		}

		const newTimer = setTimeout(() => {
			if (typeof _value === 'boolean') {
				setValue(_value);
			} else {
				setValue(!value);
			}
		}, 100);

		setTimer(newTimer);
	};

	return {
		click: value,
		onClick: handleClick,
	};
};
