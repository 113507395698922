import { observer, React } from 'mobx-react';
import * as S from './HistoryWordsSection.style';
import TooltipItem from '../../../../tooltipItem/TooltipItem';
import SearchWordItem from './searchWordItem/SearchWordItem';

const HistoryWordsSection = observer(
	({
		titleContent,
		isDetailPanelOn,
		searchWordList,
		handleWordHistoryItemClick,
		removeSearchInput,
		deleteAllHistoryWords,
	}) => {
		return (
			<S.Container isClicked={isDetailPanelOn}>
				<S.TitleSection>
					<S.SectionTitle>{titleContent}</S.SectionTitle>
					<S.ClearAllHistoryWordItems
						className='clearAllHistoryWord'
						onClick={deleteAllHistoryWords}
						style={{ marginLeft: '20px' }}>
						🗑
					</S.ClearAllHistoryWordItems>
					<TooltipItem
						anchorSelect='.clearAllHistoryWord'
						toolTipPlace='top-end'
						toolTipStyle={{
							backgroundColor: 'rgba(255, 0, 0, 0.8)',
							borderRadius: '20px',
							zIndex: '99',
						}}
						toolTipContent={'검색 기록 모두 지우기'}
					/>
				</S.TitleSection>
				{isDetailPanelOn && searchWordList?.length > 0 && (
					<S.ContentSection>
						<S.HistoryWordItemRow>
							{searchWordList?.map((word, index) => (
								<SearchWordItem
									key={`${word}-${index}`}
									word={word}
									index={index}
									onItemClick={handleWordHistoryItemClick}
									onRemove={removeSearchInput}
								/>
							))}
						</S.HistoryWordItemRow>
					</S.ContentSection>
				)}
			</S.Container>
		);
	},
);

export default HistoryWordsSection;

{
	/* <div className='container'>
				<S.InputSearchBoxLeft>
					<S.InputSearchBoxItem>
						<div className='title'>
							<span>인기 태그 검색</span>
						</div>
						<div className='tags'>
							{[
								{ name: '유치원' },
								{ name: '구연동화' },
								{ name: '교육과정' },
								{ name: '노래' },
								{ name: '크리스마스' },
							].map((tag, index) => {
								return <S.Tag key={`tags-${index}`}>{tag.name}</S.Tag>;
							})}
						</div>
					</S.InputSearchBoxItem>
					<S.InputSearchBoxItem>
						<div className='title'>
							<span>최근 검색어</span>
							<span className='delete'>
								전체삭제 <Image srcArray={S.trash_can_list} alt={'휴지통'} />
							</span>
						</div>
						<div className='searchWord'>
							{[{ name: '생일' }, { name: '콩쥐팥쥐' }, { name: '땅속' }].map(
								(word, index) => {
									return (
										<S.Word key={`word-${index}`}>
											<span>{word.name}</span>
											<span>{'X'}</span>
										</S.Word>
									);
								},
							)}
						</div>
					</S.InputSearchBoxItem>
				</S.InputSearchBoxLeft>
				<S.InputSearchBoxRight>
					<S.InputSearchBoxItem>
						<Image srcArray={S.app_list} />
						<p>땅속에는 무엇이 있을까?</p>
					</S.InputSearchBoxItem>
					<S.InputSearchBoxItem>
						<Image srcArray={S.app_list} />
						<p>땅속 식물</p>
					</S.InputSearchBoxItem>
				</S.InputSearchBoxRight>
			</div> */
}
