import { authApi, createParams } from './index';

export const getLikuAll = async () => {
	try {
		const { data } = await authApi.get('/likus/serials');

		console.log('getLikuAll', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getLikuInfo = async (serial) => {
	try {
		const params = createParams({ serial });
		const { data } = await authApi.get('/likus', { params });

		console.log('getLikuInfo', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const addLiku = async (id, serial) => {
	try {
		const { data } = await authApi.post(`/admin/members/${id}/liku/${serial}`);

		console.log('addLiku', data);
		return data;
	} catch (error) {
		return error.response.data;
	}
};

export const deleteLiku = async (id, serial) => {
	try {
		const { data } = await authApi.delete(
			`/admin/members/${id}/liku/${serial}`,
		);

		console.log('deleteLiku', data);
		return data;
	} catch (error) {
		return error.response.data;
	}
};
