import song_liku from '../assets/json/song/song_liku.json';
//동요
import song_shark from '../assets/json/song/kids/song_shark.json';
import song_crocodile from '../assets/json/song/kids/song_crocodile.json';
import song_gogo from '../assets/json/song/kids/song_gogo.json';
import song_gil from '../assets/json/song/kids/song_gil.json';
import song_three_bear from '../assets/json/song/kids/song_threebear.json';
import song_justleave from '../assets/json/song/kids/song_justleave.json';
import song_stopmoment from '../assets/json/song/kids/song_stopmoment.json';
import song_stopit from '../assets/json/song/kids/song_stopit.json';
import song_mimic from '../assets/json/song/kids/song_mimic.json';
import song_head_shoulder from '../assets/json/song/kids/song_headshoulder.json';
import song_tomato from '../assets/json/song/kids/song_tomato.json';
import song_candyfloss from '../assets/json/song/kids/song_candyfloss.json';
import song_baby_bear from '../assets/json/song/kids/song_babybear.json';
import song_frog from '../assets/json/song/kids/song_frog.json';
import song_milk from '../assets/json/song/kids/song_milksong.json';
import song_ing_ing_ing from '../assets/json/song/kids/song_inging.json';
import song_zoo from '../assets/json/song/kids/song_zoo.json';
import song_funny from '../assets/json/song/kids/song_funny.json';
import song_tongtong from '../assets/json/song/kids/song_tongtong.json';
import song_greatman from '../assets/json/song/kids/song_greatman.json';
import song_scarecrow from '../assets/json/song/kids/song_scarecrow.json';
import song_bday from '../assets/json/song/kids/song_bday';
import song_autumn_road from '../assets/json/song/kids/song_autumnroad.json';
import song_baby_snowman from '../assets/json/song/kids/song_babysnowman.json';
import song_leafboat from '../assets/json/song/kids/song_leafboat.json';
import song_faster_slower from '../assets/json/song/kids/song_fasterslower.json';
import song_doggaebee from '../assets/json/song/kids/song_doggaebee.json';
import song_sneak from '../assets/json/song/kids/song_sneak.json';
import song_into_the_forest from '../assets/json/song/kids/song_intotheforest.json';
import song_walking_in_forest from '../assets/json/song/kids/song_walkinginforest.json';
import song_morning_in_forest from '../assets/json/song/kids/song_morninginforest.json';
import song_fun_camp from '../assets/json/song/kids/song_funcamp.json';
import song_baby_squirrel from '../assets/json/song/kids/song_babysquirrel.json';
import song_baby_goat from '../assets/json/song/kids/song_babygoat.json';
import song_altogether from '../assets/json/song/kids/song_altogether.json';
import song_coldandhungry from '../assets/json/song/kids/song_coldandhungry.json';
import song_love_you from '../assets/json/song/kids/song_loveyou.json';
import song_global_family from '../assets/json/song/kids/song_globalfamily.json';
import song_foodfestival from '../assets/json/song/kids/song_foodfestival.json';
import song_iam_okay from '../assets/json/song/kids/song_iamokay.json';
import song_santa from '../assets/json/song/kids/song_santa.json';
import song_autumn from '../assets/json/song/kids/song_1autumn.json';
import song_ant from '../assets/json/song/kids/song_1ant.json';
import song_spider from '../assets/json/song/kids/song_1spider.json';
import song_rain from '../assets/json/song/kids/song_1rain.json';
import song_house from '../assets/json/song/kids/song_1house.json';
import song_fire_car from '../assets/json/song/kids/song_1firecar.json';
import song_bean from '../assets/json/song/kids/song_1bean.json';
import song_leaves from '../assets/json/song/kids/song_1leaves.json';
import song_beetle from '../assets/json/song/kids/song_1beetle.json';
import song_water_and_stone from '../assets/json/song/kids/song_1waterandstone.json';
import song_snowman from '../assets/json/song/kids/song_1snowman.json';
import song_organize from '../assets/json/song/kids/song_1organize.json';
import song_hospital from '../assets/json/song/kids/song_1hospital.json';
import song_globe from '../assets/json/song/kids/song_1globe.json';
import song_loving from '../assets/json/song/kids/song_1loving.json';
import song_goblin from '../assets/json/song/kids/song_1goblin.json';
import song_building from '../assets/json/song/kids/song_1building.json';
import song_market_play from '../assets/json/song/kids/song_1marketplay.json';
import song_market_party from '../assets/json/song/kids/song_1marketparty.json';
import song_ice from '../assets/json/song/kids/song_1ice.json';
import song_cosmos from '../assets/json/song/kids/song_1cosmos.json';
import song_jaljaljal from '../assets/json/song/kids/song_1jaljaljal.json';
import song_class from '../assets/json/song/kids/song_1class.json';
import song_abc from '../assets/json/song/eng/song_abc.json';
import song_fireman from '../assets/json/song/eng/song_fireman.json';
import song_hush from '../assets/json/song/eng/song_hush.json';
import song_youngstar from '../assets/json/song/eng/song_youngstar.json';
import song_goodmorning from '../assets/json/song/eng/song_goodmorning.json';

//영어 동요
import song_eng_teapot from '../assets/json/song/eng/song_engTeaPot.json';
import song_eng_lou from '../assets/json/song/eng/song_engLou.json';
import song_eng_come_play from '../assets/json/song/eng/song_engComePlay.json';
import song_eng_apple_tree from '../assets/json/song/eng/song_engAppleTree.json';
import song_eng_baloon from '../assets/json/song/eng/song_engBaloon.json';
import song_eng_lets_stop from '../assets/json/song/eng/song_engLetsStop.json';
import song_eng_compagnie from '../assets/json/song/eng/song_engCompagnie.json';
import song_eng_lolly from '../assets/json/song/eng/song_engLolly.json';
import song_eng_clap from '../assets/json/song/eng/song_engClap.json';
import song_eng_boston from '../assets/json/song/eng/song_engBoston.json';
import song_eng_roll_ball from '../assets/json/song/eng/song_engRollBall.json';
import song_eng_sailor from '../assets/json/song/eng/song_engSailor.json';
import song_eng_handkerchief from '../assets/json/song/eng/song_engHandkerchief.json';
import song_eng_london from '../assets/json/song/eng/song_engLondon.json';
import song_eng_humpty from '../assets/json/song/eng/song_engHumpty.json';
import song_eng_whats_this from '../assets/json/song/eng/song_engWhatsThis.json';
import song_eng_what_time from '../assets/json/song/eng/song_engWhatTime.json';
import song_eng_big_little from '../assets/json/song/eng/song_engBigLittle.json';
import song_eng_weather from '../assets/json/song/eng/song_engWeather.json';
import song_eng_baby_clothes from '../assets/json/song/eng/song_engBabyClothes.json';
import song_eng_peas_beans from '../assets/json/song/eng/song_engPeasBeans.json';
import song_eng_days_of_week from '../assets/json/song/eng/song_engDaysofWeek.json';
import song_eng_months from '../assets/json/song/eng/song_engMonths.json';
import song_eng_sally_sun from '../assets/json/song/eng/song_engSallySun.json';
import song_eng_nulberry_bush from '../assets/json/song/eng/song_engNulberryBush.json';
import song_eng_flower_bird from '../assets/json/song/eng/song_engFlowerBird.json';
import song_eng_road from '../assets/json/song/eng/song_engRoad.json';
import song_eng_apples_pears from '../assets/json/song/eng/song_engApplesPears.json';
import song_eng_twinkle_star from '../assets/json/song/eng/song_engLittleStar.json';
import song_eng_strawberry_jam from '../assets/json/song/eng/song_engStrawberryJam.json';
import song_eng_lazy_mary from '../assets/json/song/eng/song_engMary.json';
import song_eng_reach_sky from '../assets/json/song/eng/song_engSky.json';
import song_eng_winter from '../assets/json/song/eng/song_engWinter.json';
import song_eng_marry_lamb from '../assets/json/song/eng/song_engLamb.json';
import song_eng_happy from '../assets/json/song/eng/song_engHappy.json';
import song_eng_angry from '../assets/json/song/eng/song_engAngry.json';
import song_eng_village from '../assets/json/song/eng/song_engVillage.json';
import song_eng_ten_indians from '../assets/json/song/eng/song_engTenIndians.json';
import song_eng_hand from '../assets/json/song/eng/song_engHand.json';
import song_eng_wearing from '../assets/json/song/eng/song_engWearing.json';
import song_eng_boat from '../assets/json/song/eng/song_engBoat.json';
import song_eng_where from '../assets/json/song/eng/song_engWhere.json';
import song_eng_finger_band from '../assets/json/song/eng/song_engFingerBand.json';
import song_eng_head_shoulders from '../assets/json/song/eng/song_engHeadSoulders.json';
import song_eng_elephant from '../assets/json/song/eng/song_engElephant.json';
import song_eng_together from '../assets/json/song/eng/song_engTogether.json';
import song_eng_bingo from '../assets/json/song/eng/song_engBingo.json';
import song_eng_bus from '../assets/json/song/eng/song_engBus.json';
import song_eng_rain from '../assets/json/song/eng/song_engRain.json';
import song_eng_yellow from '../assets/json/song/eng/song_engYellow.json';

//국악 동요
import song_seollal from '../assets/json/song/kor/song_tradSeollal.json';
import song_ganggangsullae from '../assets/json/song/kor/song_tradGanggangsullae.json';
import song_kwaejina from '../assets/json/song/kor/song_tradKwaejina.json';
import song_arirang2 from '../assets/json/song/kor/song_tradArirang2.json';
import song_gunbam2 from '../assets/json/song/kor/song_tradGunbam2.json';
import song_daemun from '../assets/json/song/kor/song_tradDaemun.json';
import song_yeouya from '../assets/json/song/kor/song_tradYeouya.json';
import song_igeori from '../assets/json/song/kor/song_tradIgeori.json';
import song_nilliliya from '../assets/json/song/kor/song_tradNilliliya.json';
import song_pungnyeonga from '../assets/json/song/kor/song_tradPungnyeonga.json';

//트로트
import song_andongyukehseo from '../assets/json/song/trot/song_andongyukehseo.json';
import song_galmuri from '../assets/json/song/trot/song_galmuri.json';
import song_makguli_hanjan from '../assets/json/song/trot/song_makgulihanjan.json';
import song_sarangah from '../assets/json/song/trot/song_sarangah.json';
import song_teshyung from '../assets/json/song/trot/song_teshyung.json';
import song_bulhyojaneonupnida from '../assets/json/song/trot/song_bulhyojaneonoopnida.json';
import song_dongbanja from '../assets/json/song/trot/song_dongbanja.json';
import song_ebyeolui_busanjeonggeojang from '../assets/json/song/trot/song_ebyeoluibusanjeonggeojang.json';
import song_galdaewasoonjeong from '../assets/json/song/trot/song_galdaewasoonjeong.json';
import song_tteugeoun_annyeong from '../assets/json/song/trot/song_tteugeounannyeong.json';
import song_boritgogae from '../assets/json/song/trot/song_boritgogae.json';
import song_engdoo from '../assets/json/song/trot/song_hongsea.json';
import song_hongsea from '../assets/json/song/trot/song_hongsea.json';
import song_ilpyeondanshim from '../assets/json/song/trot/song_ilpyeondanshim.json';
import song_neanaigaeottaseo from '../assets/json/song/trot/song_neanaigaeottaeseo.json';
import song_ohdonglip from '../assets/json/song/trot/song_ohdonglip.json';
import song_salang_eileongeongayo from '../assets/json/song/trot/song_salangeileongeongayo.json';
import song_youngdongbruce from '../assets/json/song/trot/song_youngdongbruce.json';
import song_apart from '../assets/json/song/trot/song_apart.json';
import song_myeotmeterapedadugo from '../assets/json/song/trot/song_myeotmeterapedadugo.json';
import song_brown from '../assets/json/song/trot/song_brown.json';
import song_teahouse from '../assets/json/song/trot/song_teahouse.json';
import song_flowerwind from '../assets/json/song/trot/song_flowerwind.json';
import song_manlife from '../assets/json/song/trot/song_manlife.json';
import song_southtrain from '../assets/json/song/trot/song_southtrain.json';
import song_nest from '../assets/json/song/trot/song_nest.json';
import song_loverope from '../assets/json/song/trot/song_loverope.json';
import song_kiss from '../assets/json/song/trot/song_kiss.json';
import song_tackle from '../assets/json/song/trot/song_tackle.json';
import song_haeundae from '../assets/json/song/trot/song_haeundae.json';
import song_sooni from '../assets/json/song/trot/song_sooni.json';

import song_gandaeyo from '../assets/json/song/trot/song_gandaeyo.json';
import song_gunbae from '../assets/json/song/trot/song_gunbae.json';
import song_thankyou from '../assets/json/song/trot/song_thankyou.json';
import song_gohyang from '../assets/json/song/trot/song_gohyang.json';
import song_likestar from '../assets/json/song/trot/song_likestar.json';
import song_mujung from '../assets/json/song/trot/song_mujung.json';
import song_misco from '../assets/json/song/trot/song_misco.json';
import song_gabcho from '../assets/json/song/trot/song_gabcho.json';
import song_reallygood from '../assets/json/song/trot/song_reallygood.json';
import song_man from '../assets/json/song/trot/song_man.json';
import song_mokpo from '../assets/json/song/trot/song_mokpo.json';
import song_namchone from '../assets/json/song/trot/song_namchone.json';
import song_shoeShineBoy from '../assets/json/song/trot/song_shoeShineBoy.json';
import song_silla from '../assets/json/song/trot/song_silla.json';
import song_sooeun from '../assets/json/song/trot/song_sooeun.json';
import song_tenMinutes from '../assets/json/song/trot/song_tenMinutes.json';
import song_ulleungdo from '../assets/json/song/trot/song_ulleungdo.json';
import song_yellow from '../assets/json/song/trot/song_yellow.json';
import song_beanpole from '../assets/json/song/trot/song_beanpole.json';
import song_boarder from '../assets/json/song/trot/song_boarder.json';
import song_collapsed from '../assets/json/song/trot/song_collapsed.json';
import song_doomangang from '../assets/json/song/trot/song_doomangang.json';
import song_four from '../assets/json/song/trot/song_four.json';
import song_gapdol from '../assets/json/song/trot/song_gapdol.json';
import song_guemsoon from '../assets/json/song/trot/song_guemsoon.json';
import song_halfThousand from '../assets/json/song/trot/song_halfThousand.json';
import song_hongdo from '../assets/json/song/trot/song_hongdo.json';
import song_jinddo from '../assets/json/song/trot/song_jinddo.json';
import song_loveRunsAway from '../assets/json/song/trot/song_loveRunsAway.json';
import song_loveisseedoftear from '../assets/json/song/trot/song_loveisseedoftear.json';
import song_mother from '../assets/json/song/trot/song_mother.json';
import song_littledoll from '../assets/json/song/trot/song_littledoll.json';
import song_forever from '../assets/json/song/trot/song_forever.json';
import song_sister from '../assets/json/song/trot/song_sister.json';
import song_bravewoman from '../assets/json/song/trot/song_bravewoman.json';
import song_youaremyman from '../assets/json/song/trot/song_youaremyman.json';
import song_reminisce from '../assets/json/song/trot/song_reminisce.json';
import song_dontcry from '../assets/json/song/trot/song_dontcry.json';
import song_lost30years from '../assets/json/song/trot/song_lost30years.json';
import song_you from '../assets/json/song/trot/song_you.json';
import song_yoke from '../assets/json/song/trot/song_yoke.json';
import song_floatingweeds from '../assets/json/song/trot/song_floatingweeds.json';
import song_anoldschoolpoet from '../assets/json/song/trot/song_anoldschoolpoet.json';
import song_why from '../assets/json/song/trot/song_why.json';
import song_abalone from '../assets/json/song/trot/song_abalone.json';
import song_life from '../assets/json/song/trot/song_life.json';
import song_missingyou from '../assets/json/song/trot/song_missingyou.json';
import song_ladyatbeach from '../assets/json/song/trot/song_ladyatbeach.json';
import song_reunion from '../assets/json/song/trot/song_reunion.json';

export const GROUPS = {
	ALL: '전체',
	KIDS: '동요',
	ENG_KIDS: '영어 동요',
	KOR_TRAD_KIDS: '국악 동요',
	TROT: '트로트',
};

export const MUSICS = [
	// 동요
	{ group: 'KIDS', name: '리쿠 송', data: song_liku },
	{ group: 'KIDS', name: '상어 가족', data: song_shark },
	{ group: 'KIDS', name: '악어 떼', data: song_crocodile },
	{ group: 'KIDS', name: '간다간다', data: song_gogo },
	{ group: 'KIDS', name: '건너가는 길', data: song_gil },
	{ group: 'KIDS', name: '곰 세 마리', data: song_three_bear },
	{ group: 'KIDS', name: '그냥 두고 나갔더니', data: song_justleave },
	{ group: 'KIDS', name: '그대로 멈춰라', data: song_stopmoment },
	{ group: 'KIDS', name: '그러면 안돼', data: song_stopit },
	{ group: 'KIDS', name: '나처럼 해봐요', data: song_mimic },
	{ group: 'KIDS', name: '머리 어깨 무릎 발', data: song_head_shoulder },
	{ group: 'KIDS', name: '멋쟁이 토마토', data: song_tomato },
	{ group: 'KIDS', name: '솜사탕', data: song_candyfloss },
	{ group: 'KIDS', name: '예쁜 아기 곰', data: song_baby_bear },
	{ group: 'KIDS', name: '올챙이와 개구리', data: song_frog },
	{ group: 'KIDS', name: '우유송', data: song_milk },
	{ group: 'KIDS', name: '잉잉잉', data: song_ing_ing_ing },
	{ group: 'KIDS', name: '작은 동물원', data: song_zoo },
	{ group: 'KIDS', name: '참 재미있었지', data: song_funny },
	{ group: 'KIDS', name: '통통통', data: song_tongtong },
	{ group: 'KIDS', name: '한국을 빛낸 100명의 위인들', data: song_greatman },
	{ group: 'KIDS', name: '허수아비 아저씨', data: song_scarecrow },
	{ group: 'KIDS', name: '생일 축하 노래', data: song_bday },
	{ group: 'KIDS', name: '가을 길', data: song_autumn_road },
	{ group: 'KIDS', name: '꼬마 눈 사람', data: song_baby_snowman },
	{ group: 'KIDS', name: '나뭇잎 배', data: song_leafboat },
	{ group: 'KIDS', name: '더 빠른 것 더 느린 것', data: song_faster_slower },
	{ group: 'KIDS', name: '도깨비 나라', data: song_doggaebee },
	{ group: 'KIDS', name: '몰래 들어와서는', data: song_sneak },
	{ group: 'KIDS', name: '밀림으로', data: song_into_the_forest },
	{ group: 'KIDS', name: '숲 속을 걸어요', data: song_walking_in_forest },
	{ group: 'KIDS', name: '숲 속의 아침', data: song_morning_in_forest },
	{ group: 'KIDS', name: '신나는 캠프', data: song_fun_camp },
	{ group: 'KIDS', name: '아기 다람쥐 또미', data: song_baby_squirrel },
	{ group: 'KIDS', name: '아기 염소', data: song_baby_goat },
	{ group: 'KIDS', name: '우리 모두 다 같이', data: song_altogether },
	{
		group: 'KIDS',
		name: '춥지 않을까 배고프지 않을까',
		data: song_coldandhungry,
	},
	{ group: 'KIDS', name: '사랑해요', data: song_love_you },
	{ group: 'KIDS', name: '지구촌 한 가족', data: song_global_family },
	{ group: 'KIDS', name: '세계 음식 잔치', data: song_foodfestival },
	{
		group: 'KIDS',
		name: '괜찮아요 (`뽀로로와 노래해요`)',
		data: song_iam_okay,
	},
	{ group: 'KIDS', name: '산타할아버지', data: song_santa },
	{ group: 'KIDS', name: '가을은', data: song_autumn },
	{ group: 'KIDS', name: '개미심부름', data: song_ant },
	{ group: 'KIDS', name: '거미가 줄을 타고 올라갑니다', data: song_spider },
	{ group: 'KIDS', name: '구슬비', data: song_rain },
	{ group: 'KIDS', name: '그런 집 보았니', data: song_house },
	{ group: 'KIDS', name: '꼬마 불 자동차', data: song_fire_car },
	{ group: 'KIDS', name: '나는 콩', data: song_bean },
	{ group: 'KIDS', name: '낙엽을 밟으며', data: song_leaves },
	{ group: 'KIDS', name: '데굴데굴 쇠똥구리', data: song_beetle },
	{ group: 'KIDS', name: '돌과 물', data: song_water_and_stone },
	{ group: 'KIDS', name: '멋진 눈사람', data: song_snowman },
	{ group: 'KIDS', name: '모두 제자리', data: song_organize },
	{ group: 'KIDS', name: '병원과 소방차', data: song_hospital },
	{ group: 'KIDS', name: '빙글빙글 지구본', data: song_globe },
	{ group: 'KIDS', name: '사랑해요', data: song_loving },
	{ group: 'KIDS', name: '산도깨비', data: song_goblin },
	{ group: 'KIDS', name: '세계의 여러 가지 집', data: song_building },
	{ group: 'KIDS', name: '시장 놀이', data: song_market_play },
	{ group: 'KIDS', name: '시장 잔치', data: song_market_party },
	{ group: 'KIDS', name: '얼음 과자', data: song_ice },
	{ group: 'KIDS', name: '코스모스', data: song_cosmos },
	{ group: 'KIDS', name: '잘잘잘', data: song_jaljaljal },
	{ group: 'KIDS', name: '형님반에 간다네', data: song_class },

	// 	영어 동요
	{ group: 'ENG_KIDS', name: 'ABC_song', data: song_abc },
	{ group: 'ENG_KIDS', name: '소방관', data: song_fireman },
	{ group: 'ENG_KIDS', name: '자장자장', data: song_hush },
	{ group: 'ENG_KIDS', name: '여보게', data: song_youngstar },
	{ group: 'ENG_KIDS', name: '아침인사', data: song_goodmorning },
	{ group: 'ENG_KIDS', name: 'I am little tea pot', data: song_eng_teapot },
	{ group: 'ENG_KIDS', name: 'Skip to my Lou', data: song_eng_lou },
	{
		group: 'ENG_KIDS',
		name: 'Come and play with me',
		data: song_eng_come_play,
	},
	{
		group: 'ENG_KIDS',
		name: 'Can you climb and apple tree',
		data: song_eng_apple_tree,
	},
	{ group: 'ENG_KIDS', name: 'My baloon', data: song_eng_baloon },
	{ group: 'ENG_KIDS', name: 'Now lets stop', data: song_eng_lets_stop },
	{ group: 'ENG_KIDS', name: 'Viva la compagnie', data: song_eng_compagnie },
	{ group: 'ENG_KIDS', name: 'Hey Lolly Lolly', data: song_eng_lolly },
	{ group: 'ENG_KIDS', name: 'Clap along with me', data: song_eng_clap },
	{ group: 'ENG_KIDS', name: 'Tort to Boston', data: song_eng_boston },
	{ group: 'ENG_KIDS', name: 'Roll that ball', data: song_eng_roll_ball },
	{ group: 'ENG_KIDS', name: 'A sailor went to sea', data: song_eng_sailor },
	{
		group: 'ENG_KIDS',
		name: 'Drop the handkerchief',
		data: song_eng_handkerchief,
	},
	{ group: 'ENG_KIDS', name: 'London Bridge', data: song_eng_london },
	{ group: 'ENG_KIDS', name: 'Humpty Dumpty', data: song_eng_humpty },
	{ group: 'ENG_KIDS', name: 'What_s this', data: song_eng_whats_this },
	{ group: 'ENG_KIDS', name: 'What time', data: song_eng_what_time },
	{ group: 'ENG_KIDS', name: 'Big little', data: song_eng_big_little },
	{ group: 'ENG_KIDS', name: 'How_s the weather', data: song_eng_weather },
	{ group: 'ENG_KIDS', name: 'Baby_s clothes', data: song_eng_baby_clothes },
	{ group: 'ENG_KIDS', name: 'Oats Peas Beans', data: song_eng_peas_beans },
	{ group: 'ENG_KIDS', name: 'Days of the week', data: song_eng_days_of_week },
	{ group: 'ENG_KIDS', name: 'Twelve months', data: song_eng_months },
	{
		group: 'ENG_KIDS',
		name: 'Sally go round the Sun',
		data: song_eng_sally_sun,
	},
	{
		group: 'ENG_KIDS',
		name: 'Here we go round the nulberry bush',
		data: song_eng_nulberry_bush,
	},
	{ group: 'ENG_KIDS', name: 'Snow flower bird', data: song_eng_flower_bird },
	{ group: 'ENG_KIDS', name: 'Be careful on the road', data: song_eng_road },
	{
		group: 'ENG_KIDS',
		name: 'Peaches Apples and Pears',
		data: song_eng_apples_pears,
	},
	{
		group: 'ENG_KIDS',
		name: 'Twinkle, Twinkle, Little Star',
		data: song_eng_twinkle_star,
	},
	{ group: 'ENG_KIDS', name: 'Strawberry Jam', data: song_eng_strawberry_jam },
	{ group: 'ENG_KIDS', name: 'Lazy Mary', data: song_eng_lazy_mary },
	{ group: 'ENG_KIDS', name: 'Reach For The Sky', data: song_eng_reach_sky },
	{ group: 'ENG_KIDS', name: 'Winter Time', data: song_eng_winter },
	{
		group: 'ENG_KIDS',
		name: 'Marry Had A Little Lamb',
		data: song_eng_marry_lamb,
	},
	{ group: 'ENG_KIDS', name: 'If You_re Happy', data: song_eng_happy },
	{ group: 'ENG_KIDS', name: 'If You_re Angry', data: song_eng_angry },
	{ group: 'ENG_KIDS', name: 'Round The Village', data: song_eng_village },
	{ group: 'ENG_KIDS', name: 'Ten Little Indians', data: song_eng_ten_indians },
	{ group: 'ENG_KIDS', name: 'Shake My Hand', data: song_eng_hand },
	{ group: 'ENG_KIDS', name: 'What Are You Wearing', data: song_eng_wearing },
	{ group: 'ENG_KIDS', name: 'You are Rocking The Boat', data: song_eng_boat },
	{ group: 'ENG_KIDS', name: 'Oh Where Oh Where', data: song_eng_where },
	{ group: 'ENG_KIDS', name: 'The Finger Band', data: song_eng_finger_band },
	{
		group: 'ENG_KIDS',
		name: 'Head Shoulders Knees And Toes',
		data: song_eng_head_shoulders,
	},
	{
		group: 'ENG_KIDS',
		name: 'One Elephant Went Out To Play',
		data: song_eng_elephant,
	},
	{
		group: 'ENG_KIDS',
		name: 'The More We Get Together',
		data: song_eng_together,
	},
	{ group: 'ENG_KIDS', name: 'Bingo', data: song_eng_bingo },
	{ group: 'ENG_KIDS', name: 'People On The Bus', data: song_eng_bus },
	{ group: 'ENG_KIDS', name: 'Rain Rain Go Away', data: song_eng_rain },
	{
		group: 'ENG_KIDS',
		name: 'Who Is Wearing Yellow Today',
		data: song_eng_yellow,
	},

	//국악 동요
	{ group: 'KOR_TRAD_KIDS', name: '설날', data: song_seollal },
	{ group: 'KOR_TRAD_KIDS', name: '강강술래', data: song_ganggangsullae },
	{ group: 'KOR_TRAD_KIDS', name: '쾌지나 칭칭나네', data: song_kwaejina },
	{ group: 'KOR_TRAD_KIDS', name: '아리랑', data: song_arirang2 },
	{ group: 'KOR_TRAD_KIDS', name: '군밤타령', data: song_gunbam2 },
	{ group: 'KOR_TRAD_KIDS', name: '대문놀이', data: song_daemun },
	{ group: 'KOR_TRAD_KIDS', name: '여우야 여우야', data: song_yeouya },
	{ group: 'KOR_TRAD_KIDS', name: '이거리 저거리 각거리', data: song_igeori },
	{ group: 'KOR_TRAD_KIDS', name: '닐리리야', data: song_nilliliya },
	{ group: 'KOR_TRAD_KIDS', name: '풍년가', data: song_pungnyeonga },

	//트로트
	{ group: 'TROT', name: '안동역에서', data: song_andongyukehseo },
	{ group: 'TROT', name: '갈무리', data: song_galmuri },
	{ group: 'TROT', name: '막걸리 한잔', data: song_makguli_hanjan },
	{ group: 'TROT', name: '사랑아', data: song_sarangah },
	{ group: 'TROT', name: '테스형', data: song_teshyung },
	{ group: 'TROT', name: '불효자는 웁니다', data: song_bulhyojaneonupnida },
	{ group: 'TROT', name: '동반자', data: song_dongbanja },
	{
		group: 'TROT',
		name: '이별의 부산정거장',
		data: song_ebyeolui_busanjeonggeojang,
	},
	{ group: 'TROT', name: '갈대의 순정', data: song_galdaewasoonjeong },
	{ group: 'TROT', name: '뜨거운 안녕', data: song_tteugeoun_annyeong },
	{ group: 'TROT', name: '보릿고개', data: song_boritgogae },
	{ group: 'TROT', name: '앵두', data: song_engdoo },
	{ group: 'TROT', name: '홍시', data: song_hongsea },
	{ group: 'TROT', name: '일편단심 민들레야', data: song_ilpyeondanshim },
	{ group: 'TROT', name: '내 나이가 어때서', data: song_neanaigaeottaseo },
	{ group: 'TROT', name: '오동잎', data: song_ohdonglip },
	{
		group: 'TROT',
		name: '사랑이 이런건가요',
		data: song_salang_eileongeongayo,
	},
	{ group: 'TROT', name: '영동 부르스', data: song_youngdongbruce },
	{ group: 'TROT', name: '아파트', data: song_apart },
	{
		group: 'TROT',
		name: '몇 미터 앞에다 두고',
		data: song_myeotmeterapedadugo,
	},
	{ group: 'TROT', name: '갈색 추억', data: song_brown },
	{ group: 'TROT', name: '그 겨울의 찻집', data: song_teahouse },
	{ group: 'TROT', name: '꽃바람 여인', data: song_flowerwind },
	{ group: 'TROT', name: '남자의 인생', data: song_manlife },
	{ group: 'TROT', name: '남행열차', data: song_southtrain },
	{ group: 'TROT', name: '둥지', data: song_nest },
	{ group: 'TROT', name: '사랑의 밧줄', data: song_loverope },
	{ group: 'TROT', name: '카스바의 여인', data: song_kiss },
	{ group: 'TROT', name: '태클을 걸지마', data: song_tackle },
	{ group: 'TROT', name: '해운대 연가', data: song_haeundae },

	{ group: 'TROT', name: '18세 순이', data: song_sooni },
	{ group: 'TROT', name: '간대요 글쎄', data: song_gandaeyo },
	{ group: 'TROT', name: '건배', data: song_gunbae },
	{ group: 'TROT', name: '고맙소', data: song_thankyou },
	{ group: 'TROT', name: '고향역', data: song_gohyang },
	{ group: 'TROT', name: '내 마음 별과 같이', data: song_likestar },
	{ group: 'TROT', name: '무정 부르스', data: song_mujung },
	{ group: 'TROT', name: '미스고', data: song_misco },
	{ group: 'TROT', name: '잡초', data: song_gabcho },
	{ group: 'TROT', name: '정말 좋았네', data: song_reallygood },
	{ group: 'TROT', name: '사내', data: song_man },
	{ group: 'TROT', name: '목포의 눈물', data: song_mokpo },
	{ group: 'TROT', name: '산 넘어 남촌에는', data: song_namchone },
	{ group: 'TROT', name: '슈샤인 보이', data: song_shoeShineBoy },
	{ group: 'TROT', name: '신라의 달밤', data: song_silla },
	{ group: 'TROT', name: '수은등', data: song_sooeun },
	{ group: 'TROT', name: '십분내로', data: song_tenMinutes },
	{ group: 'TROT', name: '울릉도 트위스트', data: song_ulleungdo },
	{ group: 'TROT', name: '노란 샤쓰의 사나이', data: song_yellow },
	{ group: 'TROT', name: '키다리 미스터 김', data: song_beanpole },
	{ group: 'TROT', name: '하숙생', data: song_boarder },
	{ group: 'TROT', name: '무너진 사랑탑', data: song_collapsed },
	{ group: 'TROT', name: '눈물 젖은 두만강', data: song_doomangang },
	{ group: 'TROT', name: '네 박자', data: song_four },
	{ group: 'TROT', name: '갑돌이와 갑순이', data: song_gapdol },
	{ group: 'TROT', name: '굳세어라 금순아', data: song_guemsoon },
	{ group: 'TROT', name: '한오백년', data: song_halfThousand },
	{ group: 'TROT', name: '홍도야 우지마라', data: song_hongdo },
	{ group: 'TROT', name: '진또배기', data: song_jinddo },
	{ group: 'TROT', name: '사랑은 늘 도망가', data: song_loveRunsAway },
	{ group: 'TROT', name: '사랑은 눈물의 씨앗', data: song_loveisseedoftear },
	{ group: 'TROT', name: '어머니', data: song_mother },
	{ group: 'TROT', name: '꼬마인형', data: song_littledoll },
	{ group: 'TROT', name: '영영', data: song_forever },
	{ group: 'TROT', name: '누이', data: song_sister },
	{ group: 'TROT', name: '당돌한 여자', data: song_bravewoman },
	{ group: 'TROT', name: '너는 내 남자', data: song_youaremyman },
	{ group: 'TROT', name: '추억으로 가는 당신', data: song_reminisce },
	{ group: 'TROT', name: '울긴 왜 울어', data: song_dontcry },
	{ group: 'TROT', name: '잃어버린 30년', data: song_lost30years },
	{ group: 'TROT', name: '님', data: song_you },
	{ group: 'TROT', name: '멍에', data: song_yoke },
	{ group: 'TROT', name: '부초', data: song_floatingweeds },
	{ group: 'TROT', name: '옛시인의 노래', data: song_anoldschoolpoet },
	{ group: 'TROT', name: '와 그랍니까', data: song_why },
	{ group: 'TROT', name: '전복 먹으러 갈래', data: song_abalone },
	{ group: 'TROT', name: '인생', data: song_life },
	{ group: 'TROT', name: '임 그리워', data: song_missingyou },
	{ group: 'TROT', name: '해변의 여인', data: song_ladyatbeach },
	{ group: 'TROT', name: '해후', data: song_reunion },
];
