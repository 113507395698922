import React, { useEffect, useRef, useState } from 'react';

import * as S from './DockLikuUnConnect.style';

import LikuList from './DockLikuList';

import { useClick } from '../../../hooks/useClick';
import { Container, Context } from './DockLiku.style';

const DockLikuUnConnect = () => {
	const { click: isLink, onClick: setIsLink } = useClick(false);
	const { click: isNew, onClick: setIsNew } = useClick(false);
	const [diff, setDiff] = useState({ x: 0, y: 0, width: 0 });
	const contentRef = useRef(null);
	const connectBtnRef = useRef(null);

	useEffect(() => {
		const { x: parentX, y: parentY } =
			contentRef.current.getBoundingClientRect();
		const { x, y, width } = connectBtnRef.current.getBoundingClientRect();
		const diffX = x - parentX;
		const diffY = y - parentY;

		setDiff({ x: diffX, y: diffY, width: width });
	}, [connectBtnRef.current, contentRef.current]);

	const setLink = () => {
		if (isNew) setIsNew();
		setIsLink();
	};

	return (
		<Container ref={contentRef}>
			<LikuList
				isLink={isLink}
				isNew={isNew}
				setIsLink={setIsLink}
				setIsNew={setIsNew}
			/>
			<Context>
				<S.LinkButton onClick={setLink} ref={connectBtnRef}>
					{isLink ? '로봇을 선택해주세요.' : '로봇 연결하기'}
				</S.LinkButton>
				{!isLink && (
					<S.LinkInfo diff={diff}>
						<p className='sub'>
							<span className='highlight'>더 많은 기능</span>을 사용하려면
						</p>
						<p className='main'>로봇을 연결해 주세요.</p>
					</S.LinkInfo>
				)}
			</Context>
		</Container>
	);
};

export default DockLikuUnConnect;
