import styled from 'styled-components';
import { Box } from './DockLikuController.style';

export const RobotStateBox = styled(Box)`
	min-height: 4rem;
	${({ theme }) => theme.common.wh('100%', '4rem')};

	.title {
		//padding: 0.8rem
		border-radius: 12px;
	}

	.wrap {
		gap: 0.9rem;
		${({ theme }) => theme.common.flex()};
	}

	.stateWrap {
		border-radius: 4px;
		background-color: #000;
		${({ theme }) => theme.common.flex()};
	}
`;

export const StateButton = styled.button`
	background-color: transparent;
	border: none;
	border-radius: 4px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	font-family: NotoSansKR-Regular;
	font-size: 1.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: center;
	color: ${({ theme }) => theme.color.subTitle};

	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('5.75rem', '2.75rem')};

	${({ theme, click }) =>
		click &&
		`background-color: #fff;
		 color: #000;
		 border: solid 1px ${theme.color.gray_35}`};

	@media (max-width: 1000px) {
		font-size: 1.2rem;
		${({ theme }) => theme.common.wh('4.6rem', '2.2rem')};
	}
`;

export const SelectBox = styled.div`
	position: relative;
	z-index: 9999;
	border: none;
	border-radius: 4px;
	font-family: NotoSansKR-Regular;
	font-size: 1.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	transition: max-height 1.5s ease;
	background-color: ${({ theme }) => theme.color.gray_35};
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
	${({ theme }) => theme.common.wh('5rem', '3rem')};

	.selected {
		position: absolute;
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('100%')};
	}

	ul {
		position: absolute;
		//left: 0;
		//top: 2.4rem;
		border-radius: 4px;
		overflow-y: auto;
		transition: all 0.2s ease;
		background-color: ${({ theme }) => theme.color.gray_35};
		max-height: ${({ click }) => (click ? '12rem' : '0rem')};
		${({ theme }) => theme.common.flex('column', 'flex-start', 'flex-start')};
		${({ theme }) => theme.common.wh('100%', 'auto')};
	}

	li {
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('100%', '2.4rem')};

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			background-color: ${({ theme }) => theme.color.gray_20};
		}
	}

	@media (max-width: 1000px) {
		font-size: 1.2rem;
		${({ theme }) => theme.common.wh('4rem', '2.4rem')};
	}
`;

export const VolumeButton = styled.button`
	background-color: transparent;
	border: none;
	border-radius: 4px;
	font-family: NotoSansKR-Regular;
	font-size: 1.2rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	background-color: ${({ theme }) => theme.color.gray_35};
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('4rem', '2.4rem')};
`;
