import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from '../components/layout/Layout';
import PublicRouter from './PublicRouter';
import PrivateRouter from './PrivateRouter';
import {
	MainPage,
	LoginPage,
	PlayPage,
	PlayListPage,
	ChatBotPage,
	PlayerPage,
	CustomPage,
	SchoolPage,
	ManagePage,
	StoragePage,
	NoticePage,
} from '../pages';

import LoadingImage from '../assets/image/loading/loading.gif';
import AienMainPage from '../pages/aienMainPage/AienMainPage';
import ManageLayout from '../components/layout/ManageLayout';
import AlarmPage from '../pages/alarm/AlarmPage';

const Router = () => {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div
						className='loading'
						style={{
							position: 'fixed',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							textAlign: 'center',
						}}>
						<img src={LoadingImage} width={32} />
						<br />
						Loading...
					</div>
				}>
				<Routes>
					<Route element={<PrivateRouter />}>
						<Route element={<Layout />}>
							<Route path='/' element={<MainPage />} />
							<Route path='/play' element={<PlayPage />} />
							<Route path='/play/list' element={<PlayListPage />} />

							<Route path='/player' element={<PlayerPage />} />
							<Route path='/school' element={<SchoolPage />} />
							<Route path='/storage' element={<StoragePage />} />
							<Route path='/notice' element={<NoticePage />} />
							<Route path='/aien' element={<AienMainPage />} />
							<Route path='/aien/setp1' element={<AienMainPage />} />
							<Route path='/aien/setp2' element={<AienMainPage />} />
							<Route path='/aien/setp3' element={<AienMainPage />} />
						</Route>
						<Route element={<ManageLayout />}>
							<Route path='/manage' element={<ManagePage />} />
							<Route path='/custom' element={<CustomPage />} />
							<Route path='/custom/chatbot' element={<ChatBotPage />} />
							<Route path='/alarm' element={<AlarmPage />} />
						</Route>
					</Route>
					<Route element={<PublicRouter />}>
						<Route path='/login' element={<LoginPage />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default Router;
