import AuthStore from './domain/AuthStore';
import LikuStore from './domain/LikuStore';
import MotionStore from './domain/MotionStore';
import MqttStore from './domain/MqttStore';
import PlayerControlStore from './domain/PlayerControlStore';
import GreetStore from './domain/GreetStore';
import AlarmStore from './domain/AlarmStore';
import ModeStore from './ui/ModeStore';
import VideoStore from './ui/VideoStore';
import FilterStore from './ui/FilterStore';
import InteractiveStore from './domain/InteractiveStore';
import BirthdayStore from './domain/BirthdayStore';
import StorageStore from './domain/StorageStore';
import MetaverseStore from './domain/MetaverseStore';
import VideoV2Store from './ui/VideoV2Store';
import InteractiveV2Store from './domain/InteractiveV2Store';
import PlayerControlV2Store from './domain/PlayerControlV2Store';

// import UiStore from './ui/UiStroe';

class RootStore {
	constructor() {
		this.authStore = new AuthStore(this);
		this.likuStore = new LikuStore(this);
		this.motionStore = new MotionStore(this);
		this.mqttStore = new MqttStore(this);
		this.greetStore = new GreetStore(this);
		this.modeStore = new ModeStore(this);
		this.videoStore = new VideoStore(this);
		this.videoV2Store = new VideoV2Store(this);
		this.playerControlStore = new PlayerControlStore(this);
		this.playerControlV2Store = new PlayerControlV2Store(this);
		this.filterStore = new FilterStore(this);
		this.interactiveStore = new InteractiveStore(this);
		this.interactiveV2Store = new InteractiveV2Store(this);
		this.birthdayStore = new BirthdayStore(this);
		this.storageStore = new StorageStore(this);
		this.metaverseStore = new MetaverseStore(this);
		this.alarmStore = new AlarmStore(this);
		this.videoV2Store = new VideoV2Store(this);
		// this.uiStore = new UiStore(this);
	}
}

export default RootStore;
