import styled from 'styled-components';

export const CustomCursor = styled.div`
	position: fixed;
	width: ${({ isButtonHovered }) => (isButtonHovered ? '50px' : '10px')};
	height: ${({ isButtonHovered }) => (isButtonHovered ? '50px' : '10px')};
	border-radius: 50%;
	background-color: rgba(86, 249, 96, 0.8);
	transition: transform 0.2s ease-in-out, width 0.2s ease-in-out,
		height 0.2s ease-in-out;
	pointer-events: none;
	backdrop-filter: invert(0.8) sepia(100%) brightness(150%) contrast(200%);
	z-index: 99999;
`;
