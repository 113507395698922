import { makeAutoObservable } from 'mobx';
import {
	deleteFaceMemoryPhoto,
	deleteFaceMemoryPhotoAll,
	getFaceMemoryPhoto,
	postFaceMemoryPhoto,
} from '../../services/apis/FaceMemoryPhotoApi';
import { api } from '../../hooks/queries/useUrlQuery';
import { authApi, defaultApi } from '../../services/apis';

class MetaverseStore {
	rootStore;
	selectPhotoType = null;
	firstPhotos = [];
	memoryPhotos = [];
	newPhotos = [];
	wholePhoto = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;

		this.selectPhotoType = 'memory';
		this.firstPhotos = [];
		this.memoryPhotos = [];
		this.newPhotos = [];
		this.wholePhoto = null;
	}

	async init() {
		const photos = await this.loadPhotos();

		this.setFirstPhotos(photos);
		this.setMemoryPhotos(photos);

		// this.rootStore.mqttStore.playerMqtt.responsesFacePhotoDB(
		// 	this.setLikuNewPhotos.bind(this),
		// );
		this.rootStore.mqttStore.playerMqtt.setFacePhotos(
			this.setPhotos.bind(this, 'updated'),
			this.setWholePhoto.bind(this),
		);
		this.rootStore.mqttStore.playerMqtt.requestFacePhoto('memory');
	}

	toggleSelectPhotoType(value) {
		this.selectPhotoType = value;
	}

	async loadPhotos() {
		const liku = this.rootStore.likuStore.thisLiku.serial;
		const data = await getFaceMemoryPhoto(liku);

		return data?.content;
	}

	setFirstPhotos(photos) {
		this.firstPhotos = photos;
		console.log('setFirstPhotos', photos);
	}

	setMemoryPhotos(photos) {
		this.memoryPhotos = photos;
		console.log('setMemoryPhotos', photos.length);
	}

	setNewPhotos(photos) {
		this.newPhotos = photos;
		console.log('setNewPhotos', photos.length);
	}

	setWholePhoto(photo) {
		if (photo !== null) {
			if (this.wholePhoto) {
				URL.revokeObjectURL(this.wholePhoto);
			}

			const image = URL.createObjectURL(
				new Blob([photo], { type: 'image/png' }),
			);
			this.wholePhoto = image;

			this.setPhotos(this.selectPhotoType);
		}
	}

	getSelectPhots() {
		if (this.selectPhotoType === 'memory') {
			return this.memoryPhotos;
		} else {
			return this.newPhotos;
		}
	}

	findNewPhotos(updatedPhotos) {
		const initialPhotos = [
			...this.firstPhotos,
			...this.memoryPhotos,
			...this.newPhotos,
		];
		return updatedPhotos.filter(
			(photo) => !initialPhotos.some((item) => item.id === photo.id),
		);
	}

	async setPhotos(type) {
		const photos = await this.loadPhotos();
		console.log('setPhotos photos', photos);

		const newPhotos = this.findNewPhotos(photos);
		console.log('newPhotos', newPhotos);

		// if (type === 'new') {
		// 	this.setNewPhotos([...this.newPhotos, ...newPhotos]);
		// } else {
		// 	// type === 'memory' || 'updated'
		// 	this.setMemoryPhotos([...this.memoryPhotos, ...newPhotos]);
		// }
		if (type === 'memory') {
			this.setMemoryPhotos([...this.memoryPhotos, ...newPhotos]);
		} else {
			// type === 'new' || 'updated'
			this.setNewPhotos([...this.newPhotos, ...newPhotos]);
		}
	}

	takePhoto() {
		this.rootStore.mqttStore.playerMqtt?.requestFacePhoto('new');
	}

	async deletePhoto(type, facePhoto) {
		const liku = this.rootStore.likuStore.thisLiku.serial;

		let index = null;
		if (type === 'memory') {
			index = this.memoryPhotos.findIndex(
				(photo) => photo.path === facePhoto.path,
			);
			this.memoryPhotos.splice(index, 1);
		} else if (type === 'new') {
			index = this.newPhotos.findIndex(
				(photo) => photo.path === facePhoto.path,
			);
			this.newPhotos.splice(index, 1);
		}

		if (!('bucket' in facePhoto)) {
			await deleteFaceMemoryPhoto(liku, facePhoto.id);
		}
	}

	async applyToDB() {
		const liku = this.rootStore.likuStore.thisLiku?.serial;

		if (this.selectPhotoType === 'memory') {
			console.log('applyToDB');
			const promises = this.newPhotos.map(async (photo) => {
				if (!('bucket' in photo)) {
					const data = await deleteFaceMemoryPhoto(liku, photo.id);
					return data;
				}
			});
			await Promise.all(promises);

			const addedValues = this.memoryPhotos.filter(
				(photo) => !this.firstPhotos.some((item) => item.path === photo.path),
			);

			const promises2 = addedValues.map(async (photo) => {
				console.log('applyToDB addedValues map!!!', photo);
				if ('bucket' in photo) {
					console.log('applyToDB path', photo.path);
					// const img = await authApi.get(photo.path);
					// const img = await api(photo.path);

					try {
						const img = await defaultApi.get(photo.path, {
							responseType: 'blob',
							withCredentials: true,
						});
						console.log('img', img);

						// const imgBlob = await img?.blob();
						// // const imgBlob = await fetch(photo.path)
						// // 	.then((response) => response.blob())
						// // 	.then((blob) => {
						// // 		return blob;
						// // 		// 여기서 blob은 이미지의 Blob 데이터입니다.
						// // 		// 필요한 작업을 수행하세요.
						// // 	});
						const imgBlob = URL.createObjectURL(photo.path);

						console.log('img', imgBlob);
						const formData = new FormData();
						formData.append('file', imgBlob);

						const data = await postFaceMemoryPhoto(liku, formData);
						return data;
					} catch (error) {
						console.error(error);
					}
				}
				// const img = await api(photo.path);
				// try {
				// 	const imgBlob = await img?.blob();
				// 	const formData = new FormData();
				// 	formData.append('file', imgBlob);

				// 	const data = await postFaceMemoryPhoto(liku, formData);

				// 	return data;
				// } catch (error) {
				// 	console.log(error);
				// }
			});
			await Promise.all(promises2);
		} else {
			const promises = this.memoryPhotos.map(async (photo) => {
				if (!('bucket' in photo)) {
					const data = await deleteFaceMemoryPhoto(liku, photo.id);
					return data;
				}
			});
			await Promise.all(promises);

			const addedValues = this.newPhotos.filter(
				(photo) => !this.firstPhotos.some((item) => item.path === photo.path),
			);

			const promises2 = addedValues.map(async (photo) => {
				if ('bucket' in photo) {
					const img = await authApi.get(photo.path);
					console.log('img', img);
					const imgBlob = await img.blob();
					const formData = new FormData();
					formData.append('file', imgBlob);

					const data = await postFaceMemoryPhoto(liku, formData);
					return data;
				}
			});
			await Promise.all(promises2);
		}
	}

	async deletePhotoAll() {
		const liku = this.rootStore.likuStore.thisLiku.serial;
		const data = await deleteFaceMemoryPhotoAll(liku);
	}

	clear() {
		this.rootStore.mqttStore.playerMqtt?.unsetMetaverse();
		// this.setPhotos([]);
	}
}

export default MetaverseStore;
