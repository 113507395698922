import { makeAutoObservable, runInAction } from 'mobx';

import { contentsStart, contentsStop } from '../../services/apis/ContentsApi';

import KorToEn from '../../utils/KorToEn';
import { sleep } from '../../utils/Sleep';

const DOWNLOAD_TIMOUT = 10;

class PlayerControlV2Store {
	rootStore;

	executionUuid = null;

	state = {};

	isStart = false;

	isLoading = false;

	isDownloading = false;
	isPortalReady = false;
	downloadTimout = DOWNLOAD_TIMOUT;
	downloadProgress = 0;

	isSoundMode = false;
	isArucoMode = false;
	isTouchMode = false;

	soundInput = null;
	arucoInput = null;
	touchInput = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
	}

	getIsStart() {
		return this.isStart;
	}

	getIsLoading() {
		return this.isLoading;
	}

	async playerReady() {
		console.log('playReady');
		this.state = {
			connect: false,
			isNext: false,
			download: 0,
			join: false,
			voice: false,
			check: false,
		};

		const liku = this.rootStore.likuStore.thisLiku;
		if (liku) {
			await new Promise((resolve) => {
				const isGetLanguage = () => {
					if (this.rootStore.likuStore.thisLiku?.language) {
						resolve();
					} else {
						setTimeout(isGetLanguage, 100);
					}
				};
				isGetLanguage();
			});

			console.log(
				'this.rootStore.likuStore.thisLiku?.language ',
				this.rootStore.likuStore.thisLiku?.language,
			);
			if (this.rootStore.likuStore.thisLiku?.language !== 'ko') {
				this.rootStore.mqttStore.likuMqtt.setLanguage(
					'ko',
					this.setContentsConnect.bind(this),
				);
			} else {
				console.log('asdfasdf');
				this.setContentsConnect();
			}
		}
	}

	setState(data) {
		console.log('this.state', JSON.stringify(this.state));
		this.state = {
			...this.state,
			...data,
		};
	}

	setContentsConnect() {
		console.log('setContentsConnect');
		if (!this.state?.connect) {
			this.rootStore.mqttStore.playerMqtt.setContentsConnect(
				this.setState.bind(this, { connect: true }),
			);
		}
	}

	async start() {
		const liku = this.rootStore.likuStore.thisLiku;
		if (!this.isStart && liku?.uuid && this.state.connect) {
			this.isStart = true;
			this.isLoading = true;

			const contentsInfo = {
				title: this.rootStore.interactiveV2Store.contents?.title,
				// serials: [[liku?.serial, this.rootStore.authStore.auth.collectionName]],
				serials: [[liku?.serial, '리쿠']],
			};

			const uuid = await contentsStart(contentsInfo);
			runInAction(() => {
				this.executionUuid = uuid;
			});

			this.rootStore.mqttStore.playerMqtt.setContentsMode(
				this.rootStore.authStore.auth.id,
				this.executionUuid,
				this.check.bind(this),
			);

			let timeout = 2;
			let retry = false;

			this.rootStore.mqttStore.playerMqtt.isContentsReady();

			const contentsReadyInterval = setInterval(async () => {
				const isJoin = this.state.join;

				if (isJoin) {
					clearInterval(contentsReadyInterval);
				} else if (timeout > 0) {
					timeout -= 1;
				} else if (!retry) {
					retry = true;
					timeout = 2;

					this.rootStore.mqttStore.playerMqtt.isContentsReady();
					clearInterval(contentsReadyInterval);
				} else {
					clearInterval(contentsReadyInterval);
				}
			}, 1000);
		}
	}

	check(data) {
		const type = data.data;
		if (!type) return;
		switch (type) {
			case 'join':
				this.checkJoin(data);
				break;
			case 'voice':
				this.checkDownloadAndVoice(data);
				break;
			case 'done':
				this.isNext(data);
				break;
			default:
				if (!isNaN(type)) {
					this.setDownloadProgress(data);
				}
				break;
		}
	}

	async checkJoin(data) {
		console.log('checkJoin', JSON.stringify(data));
		if (data.uuid === this.executionUuid) {
			// if (data.role === this.rootStore.authStore.auth.collectionName) {
			this.setState({ join: true });
			// }

			const isJoin = this.state.join;
			if (isJoin) {
				console.log(
					'download Finish Join!!!!',
					JSON.stringify(this.rootStore.interactiveV2Store.contents),
				);
				this.rootStore.mqttStore.playerMqtt.contentsCheck(
					this.executionUuid,
					this.rootStore.interactiveV2Store.contents.uuid,
				);

				this.checkDownloadAndVoice(data);
			}
		}
	}

	checkDownloadAndVoice(data) {
		if (data.uuid === this.executionUuid) {
			if (this.isDownloading) {
				// if (data.role === this.rootStore.authStore.auth.collectionName) {
				this.setState({ voice: true });
				// }
			} else {
				this.isDownloading = true;

				let retry = false;
				const downloadingVoiceInterval = setInterval(() => {
					const isVoice = this.state.voice;

					if (isVoice) {
						console.log('Finish DownloadAndVoice!!!!');
						this.isDownloading = false;
						this.isLoading = false;
						clearInterval(downloadingVoiceInterval);
						this.play();
					} else if (this.downloadTimout > 0) {
						this.downloadTimout -= 1;
					} else if (!retry) {
						retry = true;
						this.downloadTimout = DOWNLOAD_TIMOUT;
						this.rootStore.mqttStore.playerMqtt.contentsCheck(
							this.executionUuid,
							this.rootStore.interactiveV2Store.content.uuid,
						);
					} else {
						// this.errorModal = true;
						clearInterval(downloadingVoiceInterval);
					}
				}, 1000);
			}
		}
	}

	setDownloadProgress(data) {
		if (data.uuid === this.executionUuid) {
			this.downloadTimout = DOWNLOAD_TIMOUT;

			// if (data.role === this.rootStore.authStore.auth.collectionName) {
			this.setState({ download: parseInt(data.data) });
			// }
			this.downloadProgress = parseInt(data.data);

			// this.downloadProgress = parseInt(
			// 	(sumDownload / (Object.keys(this.roles).length * 100)) * 100,
			// );
		}
	}

	async isNext(data) {
		if (
			data.uuid === this.executionUuid &&
			this.rootStore.videoV2Store.isPlay
		) {
			this.setState({ isNext: true });

			const isNext = this.state.isNext;
			if (isNext) {
				this.rootStore.interactiveV2Store.setNextPool();
				this.setState({ isNext: false });
			}

			// const isNotNext = Object.keys(this.roles).find(
			// 	(role) => this.roles[role].isNext === false,
			// );

			// if (!isNotNext) {
			// 	await this.rootStore.interactiveV2Store.checkCondition();
			// }
		}
	}

	play() {
		console.log('contents Play!!!');
		this.rootStore.videoV2Store.togglePlay(true);
		this.rootStore.interactiveV2Store.setNextPool();
		// this.likuPlay();
		// const isInteractive = this.rootStore.videoStore.isInteractive();
		// if (isInteractive) {
		// 	console.log('isInteractive');
		// 	this.rootStore.interactiveStore.getCurrentNode();
		// } else {
		// 	this.rootStore.videoStore.setSceneNumber();
		// 	// this.likuPlay();
		// }
		//
		// const isAruco = this.rootStore.videoStore.isAruco();
		// if (isAruco) {
		// 	this.rootStore.mqttStore.playerMqtt.setArucoMode(false);
		// }
	}

	pause() {
		console.log('contents Pause!!!');
		this.rootStore.videoV2Store.togglePlay(false);
		this.rootStore.interactiveV2Store.setCurrentIndex(
			this.rootStore.interactiveV2Store.currentIndex - 1,
		);
		this.likuStop();
		// const isAruco = this.rootStore.videoStore.isAruco();
		// if (isAruco) {
		// 	console.log('isAruco', this.rootStore.videoStore.content?.aruco);
		// 	this.rootStore.mqttStore.playerMqtt.setArucoMode(
		// 		this.rootStore.videoStore.content?.aruco,
		// 	);
		// } else {
		// 	console.log('!isAruco stop');
		// 	this.likuStop();
		// }
	}

	stop() {
		console.log('contents Stop!!!');
		this.rootStore.videoStore.togglePlay(true);
		this.end();
	}

	likuPlay() {
		const { currentDefault } =
			this.rootStore.interactiveV2Store.getCurrentNodes();
		console.log('likuPlay', JSON.stringify(currentDefault));
		if (!currentDefault) return;

		if (this.isStart) {
			const isBirthday = this.rootStore.interactiveV2Store.isBirthday();
			if (isBirthday) {
				const curTTS = currentDefault?.data?.motion?.tts;
				const transTTS = curTTS
					.replaceAll('[month]', new Date().getMonth() + 1)
					.replaceAll(
						'[names]',
						this.rootStore.birthdayStore.selectedChildList
							.map((child) => child.name)
							.join(',,,, '),
					);
				if (currentDefault?.data?.motion?.tts) {
					currentDefault.data.motion.tts = transTTS;
				}
			}

			const message = {
				action: {
					action_name: 'None',
					action_type: 'overlap',
					pitch: 0,
					yaw: 0,
				},
				display: {
					display_name: 'None',
					delay: 0,
					playtime: 1,
					playspeed: 1,
				},
				speech: {
					speech_name: 'emptyp01.mp3',
					TTS: '',
					delay: 0,
					repeat: 1,
				},
			};

			if (currentDefault?.data?.motion?.action) {
				message.action.action_name = currentDefault?.data?.motion?.action;
			}
			if (currentDefault?.data?.motion?.display) {
				message.display.display_name = currentDefault?.data?.motion?.display;
			}
			if (currentDefault?.data?.motion?.voiceFile) {
				message.speech.speech_name = `${
					this.rootStore.interactiveV2Store.contents.uuid
				}_${this.rootStore.interactiveV2Store.contents.uuid}_${
					currentDefault?.data?.motion?.voiceFile.split('.')[0]
				}`;
				message.speech.TTS = '';
			} else if (currentDefault?.data?.motion?.tts) {
				message.speech.speech_name = 'TTS_output';
				message.speech.TTS = currentDefault?.data?.motion?.tts;
			}

			const englishName = KorToEn('리쿠');
			this.rootStore.mqttStore?.playerMqtt.playMotion(
				this.executionUuid,
				englishName,
				message,
			);
		} else {
			this.stop();
		}
	}

	likuStop() {
		this.rootStore.mqttStore.playerMqtt.defaultStop();
	}

	async end() {
		if (this.isStart) {
			this.isStart = false;
			this.rootStore.interactiveV2Store.initIndex(-1);

			const liku = this.rootStore.likuStore.thisLiku;
			const serials = [[liku?.serial, '리쿠']];
			const contentsInfo = {
				title: this.rootStore.interactiveV2Store.contents.title,
				uuid: this.executionUuid,
				serials,
			};
			await contentsStop(contentsInfo);

			this.rootStore.mqttStore.playerMqtt.unsetContentsMode(
				this.rootStore.authStore.auth.id,
				this.executionUuid,
			);

			// const isMetaverse = this.rootStore.videoStore.isMetaverse();
			// const isAruco = this.rootStore.videoStore.isAruco();
			//
			// if (isMetaverse) {
			// 	this.rootStore.mqttStore.playerMqtt.unsetMetaverse();
			// }
			// if (isAruco) {
			// 	this.rootStore.mqttStore.playerMqtt.setArucoMode();
			// }
		}
	}

	setIsLoading(value) {
		this.isLoading = value;
	}

	setIsArucoMode(value) {
		this.isArucoMode = value;
		if (!this.isArucoMode) {
			this.setArucoInput(false, null);
		}
	}

	setIsTouchMode(value) {
		this.isTouchMode = value;
		if (!this.isTouchMode) {
			this.setTouchInput(null);
		}
	}

	setIsSoundMode(value) {
		this.isSoundMode = value;
		if (!this.isSoundMode) {
			this.setTouchInput(null);
		}
	}

	setArucoInput(isNext, value) {
		if (value) {
			const arucoColor = Object.values(value).toString();
			this.arucoInput = arucoColor;

			if (isNext) {
				this.rootStore.interactiveV2Store.setConditions('aruco', arucoColor);
				this.setIsArucoMode(false);
			} else {
				this.rootStore.interactiveV2Store.setNextPool();
			}
		} else {
			this.arucoInput = null;
		}
	}

	setSoundInput(value) {
		this.soundInput = value;
		this.rootStore.interactiveV2Store.setConditions('sound', value);
	}

	setTouchInput(value) {
		this.touchInput = value;
		// this.rootStore.interactiveV2Store.setConditions('touch', value);
	}

	async setTouchAruco() {
		const aruco = this.rootStore.interactiveV2Store?.aruco;

		const arucoSplit = this.arucoInput.split(',');
		const actionColor = arucoSplit[1];
		const displayColor = arucoSplit[2];
		const speechColor = arucoSplit[0];

		const message = {
			action: {
				action_name: aruco['2'][actionColor],
				action_type: 'overlap',
				pitch: 0,
				yaw: 0,
			},
			display: {
				display_name: aruco['3'][displayColor],
				delay: 0,
				playtime: 1,
				playspeed: 1,
			},
			speech: {
				// speech_name: "emptyp01.mp3",
				speech_name: 'TTS_output',
				TTS: aruco['1'][speechColor],
				delay: 0,
				repeat: 1,
			},
		};

		const englishName = KorToEn('리쿠');
		this.rootStore.mqttStore?.playerMqtt.playMotion(
			this.rootStore.playerControlV2Store.executionUuid,
			englishName,
			message,
		);

		await sleep(5000);
		this.rootStore.interactiveV2Store.setConditions('touch', this.touchInput);
		this.setIsTouchMode(false);
	}

	clear() {
		this.end();
		this.rootStore.videoV2Store.clear();
		this.rootStore.interactiveV2Store.clear();

		this.roles = {};
		this.executionUuid = null;
		this.isStart = false;
		this.isLoading = false;
		this.isDownloading = false;
		this.isPortalReady = false;
		this.downloadProgress = 0;
	}
}

export default PlayerControlV2Store;
