import styled, { css } from 'styled-components';

const searchBarWidthResponsive = () => css`
	${({ theme }) => theme.device.mobile} {
		width: ${({ theme }) => theme.size.mobile};
	}

	${({ theme }) => theme.device.tablet} {
		width: ${({ theme }) => theme.size.tablet};
	}

	${({ theme }) => theme.device.desktop} {
		width: ${({ theme }) => theme.size.desktop};
	}
`;

export const Container = styled.div`
	width: 100vw;
	& * {
		box-sizing: border-box;
	}
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: center;
	top: 7.5rem;
	left: 0;
	right: 0;

	pointer-events: ${({ isClicked }) => (isClicked ? 'all' : 'none')};
	transition: 0.3s height ease-in-out, 0.1s width ease-in-out,
		0.3s opacity ease-in-out;
	opacity: ${({ isClicked }) => (isClicked ? '1' : '0')};
	background-color: white;
	/* border-radius: 14px; */
	border: 2px solid rgba(230, 230, 230, 1);
	box-shadow: 2px 2px 2px rgba(160, 160, 160, 0.7);

	@media screen and (max-width: ${({ theme }) => theme.size.mobile}) {
		display: none;
	}
	z-index: 1000;
`;

export const LayerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}
	/* flex-wrap: wrap; */
	padding: 10px;
	${searchBarWidthResponsive()}
`;

export const LeftLayer = styled.div`
	flex: 30%;
	display: inline-block;
	padding: 10px;
`;

export const RightLayer = styled.div`
	flex: 70%;
	display: inline-block;
	padding: 10px;
`;
