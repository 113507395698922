export const TAPS = [
	{
		key: 'BASIC',
		name: '기본',
	},
	{
		key: 'ENTERTAINING',
		name: '엔터테인',
	},
	{
		key: 'WALKING',
		name: '걷기',
	},
	{
		key: 'CUSTOM',
		name: '내가 만든 표현',
	},
];

export const ACTIONS = {
	defaultArray: {
		name: '액션 자동',
		action: ['point1', 'scroll2', 'general4', 'general9', 'touch', 'pointing'],
	},
	greet_arm: '인사(오른손)',
	greeting2: '인사(양손)',
	highfive: '하이파이브',
	applaud: '박수',
	pose_picture1: '사진포즈(한손)',
	pose_picture2: '사진포즈(양손)',
	handshakeleft: '악수(오른손)',
	fighting: '화이팅(양손)',
	SelfStandUp: '일어나기',
	SelfSitDown: '앉기',
	flutter: '사랑해',
	stretching_arm: '기지개',
	head_test: '목 스트레칭',
	dance_likusong: '댄스1',
	ApproachApp: '걷기(앞)',
	RecedeApp: '걷기(뒤)',
	user_input: '기타',
};

export const DISPLAYS = {
	defaultArray: '표정 자동',
	Blink: 'blink',
	Joy: 'joy',
	Shy: 'shy',
	Dizzy: 'dizzy',
	Calm: 'calm',
	Cry: 'cry',
	Disgust: 'disgust',
	// user_input: 'user_input',
};
