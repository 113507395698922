import styled from 'styled-components';

import arrow_right from '../../../../assets/image/arrow_right/arrow_right.webp';
import arrow_right2x from '../../../../assets/image/arrow_right/arrow_right@2x.webp';
import arrow_right3x from '../../../../assets/image/arrow_right/arrow_right@3x.webp';
import attendance_time3x from '../../../../assets/image/attendance/attendance_time@3x.webp';

const arrow_right_list = [arrow_right, arrow_right2x, arrow_right3x];

export { arrow_right_list };

export const Container = styled.div`
	position: relative;
	background-color: #fff;
	max-width: 10rem;
	margin: 0rem 1rem;
	${({ theme }) => theme.common.wh('100%')};
	${({ theme }) => theme.common.flex()};
	background-image: url(${attendance_time3x});
	background-position: center;
	background-size: 100% 120%;
	background-repeat: no-repeat;

	button {
		border: none;
		background-color: transparent;
		padding: 0.5rem 2rem 0.5rem 2rem;

		${({ theme }) => theme.common.wh('100%')};
		${({ theme }) => theme.common.flex('row', 'center', 'space-between')};

		img {
			transform: rotate(90deg);
			display: inline-block; /* inline 요소로 표시되도록 함 */
			${({ theme }) => theme.common.wh('auto', '50%')};
		}
	}
`;

export const OptionsWrap = styled.ul`
	position: absolute;
	top: 2.8rem;
	left: 0;
	list-style-type: none;
	//margin-top: 0.1rem;
	z-index: 1;
	overflow: hidden;
	transition: 0.3s ease-in;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	font-size: 1.4rem;
	max-height: ${({ click }) => (click ? '20rem' : '0rem')};
	//border: 1px solid ${({ theme }) => theme.color.border};
	${({ theme }) => theme.common.wh('100%', 'auto')};
	${({ theme }) => theme.common.flex('column', 'center', 'space-between')};

	.times {
		border-bottom: 1px solid ${({ theme }) => theme.color.border};
		${({ theme }) => theme.common.wh('100%')};
		${({ theme }) => theme.common.flex('row', 'flex-start', 'space-between')};
	}
	.time {
		//overflow: hidden;
		overflow-y: scroll;
		max-height: 17rem;
		${({ theme }) => theme.common.wh('50%', '100%')};
		${({ theme }) => theme.common.flex('column', 'center', 'space-between')};

		//:hover {
		//	overflow-y: scroll;
		//}
	}
	.buttons {
		${({ theme }) => theme.common.wh('100%', '3rem')};
		${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
	}

	li {
		position: relative;
		padding: 0.7rem;
		transition: 0.1s;
		background-color: #fff;
		${({ theme }) => theme.common.wh('100%', 'auto')};

		&:last-child {
			border: none;
		}

		:hover {
			background-color: ${({ theme }) => theme.color.lightGreen};
		}
	}

	.click {
		background-color: ${({ theme }) => theme.color.lemon};
	}
	button {
		font-size: 1.2rem;
		background-color: #fff;
		padding: 0;
		${({ theme }) => theme.common.wh('100%')};
		${({ theme }) => theme.common.flex()};
	}
`;
