import React from 'react';
import * as S from './AienMainPage.style';
import CardHoverOverlay from '../../components/cardHoverEffect/CardHoverOverlay';

// import Image from '../../components/image/Image';

const MainPageButton = ({
	onClick, // 클릭 후 나타나는 효과
	subTitle, // 버튼에 표시할 부제목
	buttonTitle, // 버튼의 이름
	buttonDescription, // 버튼에 표시할 내용
	imageSrc, // 버튼에 삽입할 이미지
	alt, // 버튼에 대한 설명
	backColor, // 배경 색깔
	style, // 버튼의 ui타입지정
}) => {
	return (
		<S.Buttonlayer uiStyle={style} backColor={backColor}>
			<S.Button onClick={onClick} uiStyle={style}>
				{/* 버튼에 마우스 호버 시 나타나는 효과 (컴포넌트) */}
				<CardHoverOverlay
					uiStyle={style}
					subTitle={subTitle}
					title={buttonTitle}
					backColor={backColor}
					description={buttonDescription}
					imageSrc={imageSrc}
				/>
			</S.Button>
		</S.Buttonlayer>
	);
};

export default MainPageButton;
