import { authApi } from './index';

export const getFaceMemoryPhoto = async (serial) => {
	try {
		const { data } = await authApi.get(
			`/contents/metaverse/liku/${serial}/photos`,
		);

		console.log('getFaceMemoryPhoto', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const postFaceMemoryPhoto = async (serial, request) => {
	try {
		const { data } = await authApi.post(
			`/contents/metaverse/liku/${serial}/photos`,
			request,
		);

		console.log('postFaceMemoryPhoto', data);
	} catch (error) {
		console.error(error);
	}
};

export const deleteFaceMemoryPhoto = async (serial, faceId) => {
	try {
		const { data } = await authApi.delete(
			`/contents/metaverse/liku/${serial}/photos/${faceId}`,
		);

		console.log('deleteFaceMemoryPhoto', data);
	} catch (error) {
		console.error(error);
	}
};

export const deleteFaceMemoryPhotoAll = async (serial) => {
	try {
		const { data } = await authApi.delete(
			`/contents/metaverse/liku/${serial}/photos`,
		);

		console.log('deleteFaceMemoryPhotoAll', data);
	} catch (error) {
		console.error(error);
	}
};
