import React, { useState, useRef } from 'react';

import * as S from './Header.style';

import Image from '../image/Image';
import { useNavigate, useLocation } from 'react-router';
import { RootStoreContext } from '../../App';
import { observer } from 'mobx-react';

import useClickOutside from '../../hooks/useClickOutside';

import SearchComponent from './search/SearchComponent';
import CategoryComponent from './category/CategoryComponent';
import SearchDetailBox from './search/SearchDetailBox';
import { clock } from '../../pages/alarm/alarmContent/AlarmContent.style';

const Header = observer(() => {
	const { likuStore, authStore } = React.useContext(RootStoreContext);
	const navigate = useNavigate();
	const location = useLocation();
	const [profileOpen, setProfileOpen] = useState(false);

	const renderLogo = () => {
		if (location.pathname === '/') {
			return <></>;
		} else if (
			location.pathname === '/play' ||
			location.pathname === '/player'
		) {
			return (
				<div
					className='pageLogo'
					onClick={() => {
						navigate('/play');
					}}>
					<Image srcArray={S.play_list} alt={'Play'} />
					<span className='icon span'>플레이</span>
				</div>
			);
		} else if (location.pathname === '/school') {
			return (
				<div
					className='pageLogo'
					onClick={() => {
						navigate('/school');
					}}>
					<Image srcArray={S.school_list} alt={'School'} />
					<span className='icon span'>스쿨</span>
				</div>
			);
		} else if (location.pathname === '/custom') {
			return (
				<div
					className='pageLogo'
					onClick={() => {
						navigate('/custom');
					}}>
					<Image srcArray={S.custom_list} alt={'Custom'} />
					<span className='icon span'>커스텀</span>
				</div>
			);
		} else if (location.pathname === '/manage') {
			return (
				<div
					className='pageLogo'
					onClick={() => {
						navigate('/manage');
					}}>
					<Image srcArray={S.manage_list} alt={'Manage'} />
					<span className='icon span'>사용자 관리</span>
				</div>
			);
		} else if (location.pathname === '/alarm') {
			return (
				<div
					className='pageLogo'
					onClick={() => {
						navigate('/alarm');
					}}>
					<Image srcArray={[clock]} alt={'Alarm'} />
					<span className='icon span'>알림</span>
				</div>
			);
		}
	};

	const categoryMenuUI = () => {
		if (location.pathname === '/play') {
			return (
				<S.CategorySection>
					<CategoryComponent />
				</S.CategorySection>
			);
		}
	};

	const searchMenuUI = () => {
		if (location.pathname === '/play') {
			return <SearchDetailBox />;
		}
	};

	// 요소 외부 부분 클릭 처리
	const elementRefForMouseClick = useRef(null);

	useClickOutside({
		elementRef: elementRefForMouseClick,
		stateVariable: profileOpen,
		stateFunction: setProfileOpen,
	});

	return (
		<S.Container>
			<S.TopContentsSection>
				<S.Left>
					<div onClick={() => navigate('/')}>
						<Image srcArray={S.torooc_list} alt={'Torooc'} />
					</div>
					<div>{renderLogo()}</div>
				</S.Left>
				<S.Center>
					{
						<S.SearchSection>
							<SearchComponent />
						</S.SearchSection>
					}
				</S.Center>
				<S.Right>
					<S.ProfileWrap>
						{/*<Image alt='Alarm' srcArray={S.alarm_list} />*/}
						<S.Profile
							ref={elementRefForMouseClick}
							onClick={() => {
								setProfileOpen((state) => !state);
							}}>
							<Image
								alt='Profile'
								custom={S.ProfileImage}
								srcArray={S.profile_list}
							/>
							<S.ProfileModal open={profileOpen}>
								<S.ProfileModalName>
									<span className='bold'>
										{authStore.auth?.name || authStore.auth?.profile?.name}
									</span>
									{' 님'}
								</S.ProfileModalName>
								<S.LogoutButton
									onClick={() => {
										console.log('logout!!!!');
										authStore.logout();
										likuStore.setThisLiku();
										navigate('/login');
									}}>
									로그아웃
								</S.LogoutButton>
								{/* <S.ModeToggleButtonInProfile isBig={modeStore.isBigMode}>
									<span>{'큰글씨'}</span>
									<div
										onClick={() => {
											modeStore.setBigMode(!modeStore.isBigMode);
										}}>
										{modeStore.isBigMode ? 'ON' : 'OFF'}
									</div>
								</S.ModeToggleButtonInProfile> */}
							</S.ProfileModal>
						</S.Profile>
					</S.ProfileWrap>
				</S.Right>
			</S.TopContentsSection>
			{categoryMenuUI()}
		</S.Container>
	);
});

export default Header;
