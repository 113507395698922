import { observer, React } from 'mobx-react';
import * as S from './ProgramSection.style';
import TooltipItem from '../../../../tooltipItem/TooltipItem';

const ProgramSection = observer(
	({ titleContent, isDetailPanelOn, contentData }) => {
		return (
			<S.Container isClicked={isDetailPanelOn}>
				<S.LeftLayer>
					<S.TitleSection>
						<S.SectionTitle>{titleContent}</S.SectionTitle>
					</S.TitleSection>
				</S.LeftLayer>
				<S.RightLayer>
					<S.ContentSection>{contentData}</S.ContentSection>
				</S.RightLayer>
			</S.Container>
		);
	},
);

export default ProgramSection;
