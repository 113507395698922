import React from 'react';
import * as S from './Spinner.style';

const Spinner = ({ color, count = 11 }) => {
	return (
		<S.Container>
			<S.Spinner>
				{[...Array(count)].map((_, index) => {
					return (
						<S.SpinnerElement
							key={index}
							color={color}
							count={count}
							index={index}
							// deg={(360 / 11) * index}
						/>
					);
				})}
			</S.Spinner>
		</S.Container>
	);
};

export default Spinner;
