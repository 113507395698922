import styled from 'styled-components';

export const DefaultImage = styled.img`
	//aspect-ratio: 1 / 1;
	object-fit: fill !important;

	${({ theme }) => theme.common.wh('auto', '100%')};
	${({ custom }) => custom && custom};
	opacity: 0;
	transition: opacity 0.7s ease-in;
	&.isLoaded {
		opacity: 1;
	}
`;
