import React, { useEffect, useRef, useState } from 'react';
import * as S from './timeInput.style';
import useClickOutside from '../../hooks/useClickOutside';

const YEARS = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
	'32',
	'33',
	'34',
	'35',
	'36',
	'37',
	'38',
	'39',
	'40',
	'41',
	'42',
	'43',
	'44',
	'45',
	'46',
	'47',
	'48',
	'49',
	'50',
	'51',
	'52',
	'53',
	'54',
	'55',
	'56',
	'57',
	'58',
	'59',
	'60',
	'61',
	'62',
	'63',
	'64',
	'65',
	'66',
	'67',
	'68',
	'69',
	'70',
	'71',
	'72',
	'73',
	'74',
	'75',
	'76',
	'77',
	'78',
	'79',
	'80',
	'81',
	'82',
	'83',
	'84',
	'85',
	'86',
	'87',
	'88',
	'89',
	'90',
	'91',
	'92',
	'93',
	'94',
	'95',
	'96',
	'97',
	'98',
	'99',
];

const MONTHS = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

const DAYS = [
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
];

const dateNow = new Date();

const DateInput = ({
	value = dateNow.getFullYear() +
		'-' +
		(+dateNow.getMonth() + 1) +
		'-' +
		dateNow.getDate(),
	id,
	setValue,
}) => {
	const [year, setYear] = useState(value.split('-')[0]);
	const [month, setMonth] = useState(value.split('-')[1]);
	const [day, setDay] = useState(value.split('-')[2]);
	const [yearFocus, setYearFocus] = useState(false);
	const [monthFocus, setMonthFocus] = useState(false);
	const [dayFocus, setDayFocus] = useState(false);

	const yearRef = useRef(null);
	const monthRef = useRef(null);
	const dayRef = useRef(null);

	useClickOutside({
		elementRef: yearRef,
		stateVariable: yearFocus,
		stateFunction: setYearFocus,
	});
	useClickOutside({
		elementRef: monthRef,
		stateVariable: monthFocus,
		stateFunction: setMonthFocus,
	});
	useClickOutside({
		elementRef: dayRef,
		stateVariable: dayFocus,
		stateFunction: setDayFocus,
	});

	return (
		<S.Container>
			<input type='hidden' value={`${year}-${month}-${day}`} />
			<S.TimeBox>
				<S.Viewer
					ref={yearRef}
					onClick={() => {
						setYearFocus(!yearFocus);
					}}>
					{year + '년'}
				</S.Viewer>
				<S.Ul open={yearFocus}>
					{YEARS.map((year, index) => (
						<li
							key={year + index}
							onClick={() => {
								setYear('20' + year);
								setYearFocus(false);
								setValue(id, `20${year}-${month}-${day}`);
							}}>
							{'20' + year}
						</li>
					))}
				</S.Ul>
			</S.TimeBox>
			<S.TimeBox>
				<S.Viewer
					ref={monthRef}
					onClick={() => {
						setMonthFocus(!monthFocus);
					}}>
					{month + '월'}
				</S.Viewer>
				<S.Ul open={monthFocus}>
					{MONTHS.map((month, index) => (
						<li
							key={month + index}
							onClick={() => {
								setMonth(month);
								setMonthFocus(false);
								setValue(id, `${year}-${month}-${day}`);
							}}>
							{month}
						</li>
					))}
				</S.Ul>
			</S.TimeBox>
			<S.TimeBox>
				<S.Viewer
					ref={dayRef}
					onClick={() => {
						setDayFocus(!dayFocus);
					}}>
					{day + '일'}
				</S.Viewer>
				<S.Ul open={dayFocus}>
					{DAYS.map((day, index) => (
						<li
							key={day + index}
							onClick={() => {
								setDay(day);
								setDayFocus(false);
								setValue(id, `${year}-${month}-${day}`);
							}}>
							{day}
						</li>
					))}
				</S.Ul>
			</S.TimeBox>
		</S.Container>
	);
};

export default DateInput;
