import React from 'react';
import { observer } from 'mobx-react';

import * as S from './DockLikuList.style';
import { RootStoreContext } from '../../../App';

import Image from '../../image/Image';

import LikuImage from '../../../assets/image/likuicon/likuicon.webp';
import Liku2Image from '../../../assets/image/likuicon/likuicon@2x.webp';
import Liku3Image from '../../../assets/image/likuicon/likuicon@3x.webp';

const DockLikuList = observer(({ isLink, isNew, setIsLink, setIsNew }) => {
	const { likuStore } = React.useContext(RootStoreContext);
	const [liku, setLiku] = React.useState('');

	const addLiku = React.useCallback(async () => {
		const result = await likuStore.addLiku(liku);
		if (result)
			setIsNew((prevIsNew) => !prevIsNew); // 이전 상태를 받아와서 업데이트
		else setLiku('');
	}, [liku, setIsNew, likuStore]);

	const connectLiku = React.useCallback(
		(liku) => {
			likuStore.setThisLiku(liku);
			setIsLink();
		},
		[isLink],
	);

	const likus = likuStore.likus?.map((liku) => (
		<S.LikuButton key={liku} onClick={() => connectLiku(liku)}>
			<div>
				<Image
					alt='liku'
					srcArray={[LikuImage, Liku2Image, Liku3Image]}
					custom={S.Liku}
				/>
				<span>{liku}</span>
				{/* <span className='version'>v4.0.0</span> */}
			</div>
			<button
				onClick={(event) => {
					event.stopPropagation();
					likuStore.deleteLiku(liku);
				}}>
				삭제
			</button>
		</S.LikuButton>
	));

	return (
		<S.Container click={isLink} isNew={isNew}>
			{isNew ? (
				<S.NewLiku>
					<S.Example>입력 예시 : 2011K504</S.Example>
					<S.SerialInput
						placeholder='시리얼 넘버를 입력해주세요.'
						value={liku}
						onChange={(e) => setLiku(e.target.value.toUpperCase())}
					/>
					<S.RegistrButton disable={liku === ''} onClick={() => addLiku()}>
						등록하기
					</S.RegistrButton>
				</S.NewLiku>
			) : (
				<>
					<S.Top click={isLink}>
						<Image srcArray={S.search_list} />
						<input type='text' />
						<S.AddButton onClick={() => setIsNew()}>로봇 추가</S.AddButton>
					</S.Top>
					<S.LikuWrap click={isLink}>{likus}</S.LikuWrap>
				</>
			)}
			{/*</S.Context>*/}
		</S.Container>
	);
});

export default DockLikuList;
