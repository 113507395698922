import React, { useEffect, useRef, useState } from 'react';
import * as S from './timeInput.style';
import useClickOutside from '../../hooks/useClickOutside';

const HOURS = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
];

const MINUTES = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
	'32',
	'33',
	'34',
	'35',
	'36',
	'37',
	'38',
	'39',
	'40',
	'41',
	'42',
	'43',
	'44',
	'45',
	'46',
	'47',
	'48',
	'49',
	'50',
	'51',
	'52',
	'53',
	'54',
	'55',
	'56',
	'57',
	'58',
	'59',
];

const TimeInput = ({ value = '09:30', id, setValue }) => {
	const [hour, setHour] = useState(value?.split(':')[0]);
	const [minute, setMinute] = useState(value?.split(':')[1]);
	const [hourFocus, setHourFocus] = useState(false);
	const [minuteFocus, setMinuteFocus] = useState(false);
	const hourRef = useRef(null);
	const minuteRef = useRef(null);
	useClickOutside({
		elementRef: hourRef,
		stateVariable: hourFocus,
		stateFunction: setHourFocus,
	});

	useClickOutside({
		elementRef: minuteRef,
		stateVariable: minuteFocus,
		stateFunction: setMinuteFocus,
	});

	// console.log('hour', hour);

	return (
		<S.Container>
			{/*<S.Input type='hidden' {...register} value={hour + ':' + minute} />*/}
			<S.TimeBox>
				<S.Viewer
					ref={hourRef}
					onClick={(e) => {
						setHourFocus((state) => !state);
					}}>
					{hour + '시'}
				</S.Viewer>
				<S.Ul open={hourFocus}>
					{HOURS.map((h) => (
						<li
							key={h}
							onClick={() => {
								setHour(h);
								setValue(id, h + ':' + minute);
							}}>
							{h}
						</li>
					))}
				</S.Ul>
			</S.TimeBox>
			<S.TimeBox>
				<S.Viewer
					ref={minuteRef}
					onClick={() => {
						setMinuteFocus((state) => !state);
					}}>
					{minute + '분'}
				</S.Viewer>
				<S.Ul open={minuteFocus}>
					{MINUTES.map((m) => (
						<li
							key={m}
							onClick={() => {
								setMinute(m);
								setValue(id, hour + ':' + m);
							}}>
							{m}
						</li>
					))}
				</S.Ul>
			</S.TimeBox>
		</S.Container>
	);
};

export default TimeInput;
