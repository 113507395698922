export const categoryTags = [
	{ name: '재미 톡톡', id: 43 },
	{ name: '영어 동화', id: 47 },
	{ name: '생각 톡톡', id: 40 },
	{ name: '안전 교육', id: 36 },
	{ name: '이야기 나누기', id: 80 },
	{ name: '특별한 날', id: 3 },
	{ name: '영어야 놀자', id: 41 },
	{ name: '동시', id: 38 },
	{ name: '달콤아트', id: 39 },
	{ name: '한국사', id: 48 },
	{ name: '동요', id: 30 },
	{ name: '기후와 환경', id: 49 },
	{ name: '한자야 놀자', id: 2 },
	{ name: '스페셜 데이', id: 141 },
	{ name: '재미난 이야기', id: 140 },
	// { name: '테스트', id: 81 },
];

export const samsungTags = [
	{
		name: '삼성병원',
		id: 83,
	},
];

export const headerCategories = [
	{
		sort: 'kid',
		name: '어린이',
		value: ['언어', '미술', '음악', '과학', '안전', '영어', '리쿠행사'],
	},
	{
		sort: 'school',
		name: '초등',
		value: ['언어', '영어', '미술', '음악', '과학'],
	},
	{
		sort: 'senior',
		name: '어르신',
		value: ['건강', '음악', '상식', '시', '역사', '영어', '한자'],
	},
];

export const kidTags = [
	{ name: '동시', id: 38, category: '언어' },
	{ name: '생각톡톡', id: 40, category: '언어' },
	{ name: '이야기나누기', id: 80, category: '언어' },
	{ name: '재미톡톡', id: 43, category: '언어' },
	{ name: '특별한날', id: 3, category: '언어' },
	{ name: '한글아놀자', id: 17, category: '언어' },
	{ name: '한자야놀자', id: 2, category: '언어' },
	{ name: '달콤아트', id: 39, category: '미술' },
	{ name: '색종이접기', id: 146, category: '미술' },
	{ name: '동요', id: 30, category: '음악' },
	{ name: '딩동댕피아노', id: 159, category: '음악' },
	{ name: '클래식 감상', id: 143, category: '음악' },
	{ name: '과학원리', id: 147, category: '과학' },
	{ name: '동물상식', id: 5, category: '과학' },
	{ name: '융합과학', id: 42, category: '과학' },
	{ name: '안전교육', id: 36, category: '안전' },
	{ name: '아이엔', id: 55, category: '영어' },
	{ name: '영어동화', id: 47, category: '영어' },
	{ name: '영어야놀자', id: 41, category: '영어' },
	{ name: '부모님참여수업', id: 53, category: '리쿠행사' },
	{ name: '생일파티', id: 166, category: '리쿠행사' },
];

export const schoolTags = [
	{ name: '재미톡톡', id: 43, category: '언어' },
	{ name: '특별한날', id: 3, category: '언어' },
	{ name: '한자야놀자', id: 2, category: '언어' },
	{ name: '영어동화', id: 47, category: '영어' },
	{ name: '영어야놀자', id: 41, category: '영어' },
	{ name: '달콤아트', id: 39, category: '미술' },
	{ name: '색종이접기', id: 146, category: '미술' },
	{ name: '동요', id: 30, category: '음악' },
	{ name: '딩동댕피아노', id: 159, category: '음악' },
	{ name: '클래식 감상', id: 143, category: '음악' },
	{ name: '과학원리', id: 147, category: '과학' },
	{ name: '동물상식', id: 5, category: '과학' },
	{ name: '기후와환경', id: 49, category: '과학' },
];

export const seniorTags = [
	{ name: '건강상식', id: 6, category: '신체' },
	{ name: '과학상식', id: 147, category: '인지' },
	{ name: '동물상식', id: 5, category: '인지' },
	{ name: '한 편의시낭송', id: 142, category: '정서' },
	{ name: '클래식감상', id: 143, category: '정서' },
	{ name: '명화감상', id: 39, category: '정서' },
	{ name: '색종이접기', id: 146, category: '정서' },
	{ name: '영어동화', id: 47, category: '인지' },
	{ name: '영어야놀자', id: 41, category: '인지' },
	{ name: '재미난이야기', id: 140, category: '정서' },
	{ name: '특별한날', id: 3, category: '인지' },
	{ name: '한국사', id: 48, category: '인지' },
	{ name: '한자야놀자', id: 2, category: '인지' },
];

// 시니어 카테고리 패널 관련
export const bodyTags = [{ name: '건강상식', id: 6, category: ['senior'] }];

export const mindTags = [
	{ name: '과학상식', id: 147, category: ['senior'] },
	{ name: '동물상식', id: 5, category: ['senior'] },
	{ name: '영어동화', id: 47, category: ['senior'] },
	{ name: '영어야놀자', id: 41, category: ['senior'] },
	{ name: '특별한날', id: 3, category: ['senior'] },
	{ name: '한국사', id: 48, category: ['senior'] },
	{ name: '한자야놀자', id: 2, category: ['senior'] },
];

export const emotionTags = [
	{ name: '한편의 시 낭송', id: 142, category: ['senior'] },
	{ name: '클래식 감상', id: 143, category: ['senior'] },
	{ name: '명화 감상', id: 39, category: ['senior'] },
	{ name: '색종이접기', id: 146, category: ['senior'] },
	{ name: '재미난 이야기', id: 140, category: ['senior'] },
];

// 카테고리 패널 관련
export const languageTags = [
	{ name: '동시', id: 38, category: ['kid'] },
	{ name: '생각톡톡', id: 40, category: ['kid'] },
	{ name: '이야기 나누기', id: 80, category: ['kid'] },
	{ name: '재미톡톡', id: 43, category: ['kid'] },
	{ name: '특별한 날', id: 3, category: ['kid'] },
	{ name: '한글아놀자', id: 17, category: ['kid'] },
	{ name: '한자야놀자', id: 2, category: ['kid'] },
];

export const englishTags = [
	{ name: '아이엔', id: 55, category: ['kid'] },
	{ name: '영어동화', id: 47, category: ['kid'] },
	{ name: '영어야놀자', id: 41, category: ['kid'] },
];

export const artTags = [
	{ name: '달콤아트', id: 39, category: ['kid'] },
	{ name: '색종이접기', id: 146, category: ['kid'] },
];

export const musicTags = [
	{ name: '동요', id: 30, category: ['kid'] },
	{ name: '딩동댕피아노', id: 159, category: ['kid'] },
	{ name: '클래식감상', id: 143, category: ['kid', 'senior'] },
];

export const scienceTags = [
	{ name: '과학원리', id: 147, category: ['kid'] },
	{ name: '동물상식', id: 5, category: ['kid'] },
	{ name: '융합과학', id: 42, category: ['kid'] },
	{ name: '기후와환경', id: 49, category: ['kid'] },
];

export const safeTags = [{ name: '안전 교육', id: 36, category: ['kid'] }];

export const historyTags = [{ name: '한국사', id: 48, category: ['kid'] }];

export const likuFestivalTags = [
	{ name: '부모님 참여 수업', id: 53, category: ['kid'] },
	{ name: '생일 파티', id: 166, category: ['kid'] },
];

export const premiumTags = [
	{ name: '아이엔', id: 55 },
	{ name: '생각톡톡', id: 40 },
	{ name: '융합과학', id: 42 },
];

// 재미톡톡 : 43
// 영어동화 : 47 , 91
// 생각톡톡 : 40 , 92
// 안전교육 : 36, 120
// 이야기나누기 : 80
// 특별한 날 : 3
// 영어야 놀자 : 41
// 동시 : 38
// 달콤아트 : 39, 133
// 한국사 : 48
// 동요 : 30
// 기후와 환경 : 49
// 한자야 놀자 : 2
// 스페셜데이 : 141
// 재미난 이야기 : 140
// 테스트 : 81
