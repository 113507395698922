import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import * as S from './MakeMotion.style';
import * as CS from './DockLikuController.style';

import { RootStoreContext } from '../../../../App';

import { ACTIONS, DISPLAYS } from '../../../../data/motion';

import { useClick } from '../../../../hooks/useClick';
import { runInAction } from 'mobx';

const MakeMotion = observer(() => {
	const { motionStore } = React.useContext(RootStoreContext);

	const [name, setName] = useState('');
	const [action, setAction] = useState(Object.keys(ACTIONS)[0]);
	const [display, setDisplay] = useState(Object.keys(DISPLAYS)[0]);
	const [tts, setTts] = useState('');

	const { click: isActionList, onClick: setIsActionList } = useClick(false);
	const { click: isDisplayList, onClick: setIsDisplayList } = useClick(false);

	const [isModify, setModify] = useState(false);

	const actions = Object.keys(ACTIONS).map((a) => {
		if (
			action !== a &&
			a !== 'SelfStandUp' &&
			a !== 'SelfSitDown' &&
			a !== 'user_input' &&
			a !== 'ApproachApp' &&
			a !== 'RecedeApp'
		) {
			return (
				<li key={a} onClick={() => setAction(a)}>
					{typeof ACTIONS[a] !== 'object' ? ACTIONS[a] : ACTIONS[a].name}
				</li>
			);
		}
	});

	const displays = Object.keys(DISPLAYS).map((d) => {
		if (display !== d) {
			return (
				<li key={d} onClick={() => setDisplay(d)}>
					{DISPLAYS[d]}
				</li>
			);
		}
	});

	useEffect(() => {
		console.log('isActionList', isActionList);
	}, [isActionList, isDisplayList]);

	useEffect(() => {
		if (motionStore.uuidModify.length > 0) {
			console.log('reaction modify', motionStore.uuidModify);
			console.log('modify DATA', motionStore.dataModify);
			console.log('modify ACTION', motionStore.dataModify.action.action_name);
			console.log(
				'modify DISPLAY',
				motionStore.dataModify.display.display_name,
			);

			setName(motionStore.motionModify.name);
			setTts(motionStore.dataModify.speech.TTS);
			setAction(motionStore.dataModify.action.action_name);
			setDisplay(motionStore.dataModify.display.display_name);
			setModify(true);
			runInAction(() => {
				motionStore.uuidModify = '';
			});
		}
	}, [motionStore.uuidModify]);

	const resetMotion = () => {
		setName('');
		setTts('');
		setAction(Object.keys(ACTIONS)[0]);
		setDisplay(Object.keys(DISPLAYS)[0]);
		setModify(false);
	};

	const onChangeTts = (event) => {
		if (event.target.scrollHeight < 172) {
			event.target.style.height = 'auto'; // 높이 초기화
			if (event.target.scrollHeight > 21.5) {
				event.target.style.height = `${event.target.scrollHeight}px`; // 콘텐츠에 맞게 높이 조절
			}
		}

		setTts(event.target.value);
	};

	const addMotion = () => {
		if (name !== '') {
			// console.log('addMotion', isModify);

			if (isModify) {
				motionStore.motionModify.name = name;
				motionStore.dataModify.speech.TTS = tts;
				motionStore.dataModify.action.action_name = action;
				motionStore.dataModify.display.display_name = display;
				motionStore.modifyMotion();
				setModify(false);
			} else {
				motionStore.setMotion({
					name,
					motion: {
						action,
						display,
						speech: tts,
					},
				});
			}
			resetMotion();
		} else {
			alert('이름을 입력해주세요.');
		}
	};

	const playMotion = () => {
		console.log('name@#@#', name);
		motionStore.playMotion({
			name,
			motion: {
				action,
				display,
				speech: tts,
			},
		});
	};

	return (
		<S.MakeExpressionBox className='light'>
			<div className='title'>
				{isModify ? '표현 수정' : '표현 만들기'}
				<input
					placeholder='표현 이름을 입력하세요.'
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
			</div>
			<div className='content'>
				<textarea
					type='text'
					rows={1}
					placeholder='로봇이 말할 내용을 입력하세요.'
					value={tts}
					onChange={onChangeTts}
				/>
				<div className='rowWrap'>
					<S.SelectBox click={isActionList} onClick={() => setIsActionList()}>
						<span className='selected action'>
							{typeof ACTIONS[action] !== 'object'
								? ACTIONS[action]
								: ACTIONS[action].name}
						</span>
						<ul>{actions}</ul>
					</S.SelectBox>
					<S.SelectBox click={isDisplayList} onClick={() => setIsDisplayList()}>
						<span className='selected display'>{DISPLAYS[display]}</span>
						<ul>{displays}</ul>
					</S.SelectBox>
				</div>
				<div className='rowWrap'>
					<CS.Button onClick={() => addMotion()}>표현 저장</CS.Button>
					<CS.Button onClick={() => playMotion()}>바로 실행</CS.Button>
				</div>
			</div>
		</S.MakeExpressionBox>
	);
});

export default MakeMotion;
