import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { RootStoreContext } from '../App';
import { observer } from 'mobx-react';
import Header from '../components/header/Header';
import Dock from '../components/dock/Dock';
import Footer from '../components/footer/Footer';
import CustomCursor from '../components/customCursor/CustomCursor';

const PrivateRouter = observer(() => {
	const store = useContext(RootStoreContext);

	useEffect(() => {
		const isLogin = store.authStore.isLogin();
		if (isLogin) {
			store.authStore.loadAuth();
		}
	}, [store.authStore]);

	return store.authStore.isLogin() ? (
		<>
			{/* <CustomCursor /> */}
			<Header />
			<Outlet />
			<Dock />
			<Footer />
		</>
	) : (
		<Navigate to='/login' />
	);
});

export default PrivateRouter;
