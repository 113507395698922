import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from './Layout.style';

const Layout = () => {
	return (
		<Container>
			<Outlet />
		</Container>
	);
};

export default Layout;
