import styled from 'styled-components';

import clock from '../../../assets/image/alarm/clock@3x.webp';
import calendar from '../../../assets/image/alarm/calendar.webp';
import calendar_day from '../../../assets/image/alarm/calendar-day.webp';

export { clock, calendar, calendar_day };

export const Container = styled.div`
	font-family: 'NotoSansKR-Bold';
	position: relative;
	border: 1px solid #ebebe9;
	border-radius: 12px;
	margin-bottom: 1.5rem;
	cursor: pointer;
	&:hover .title {
		background-color: #dcdcdc;
	}

	.title {
		display: flex;
		border-radius: 12px 12px 0 0;
		justify-content: space-between;
		background-color: #ebebe9;
		padding: 0.5rem 1.5rem;
		p {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.5rem;
			color: ${({ theme }) => theme.color.gray_70};
		}
	}

	.option {
		//display: flex;
		//justify-content: start;
		//column-gap: 1rem;
		//align-items: center;
		padding: 0rem 1.5rem;

		div {
			padding: 1rem 0;
			display: flex;
			justify-content: start;
			column-gap: 1rem;
			align-items: center;
			//padding: 1rem 1.5rem;
			img {
				width: 24px;
				height: 24px;
			}
		}
	}

	.time {
		//display: flex;
		//justify-content: start;
		//column-gap: 1rem;
		//align-items: center;
		////padding: 1rem 1.5rem;
		//img {
		//	width: 24px;
		//	height: 24px;
		//}
	}

	hr {
		//margin-left: 10%;
		width: 100%;
		border-top: 1px dashed ${({ theme }) => theme.color.gray_70};
	}

	.user {
		padding: 1rem 1.5rem;
		& > div {
			font-family: 'NotoSansKR-Regular';
			font-size: 1.6rem;
			width: fit-content;
			padding: 0.6rem 0.8rem;
			border: 1px solid ${({ theme }) => theme.color.grass};
			color: ${({ theme }) => theme.color.grass};
			border-radius: 1.2rem;
		}
	}

	.switch_wrapper {
		position: relative;
	}

	.switch_input {
		position: absolute;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.switch_label {
		position: relative;
		cursor: pointer;
		display: inline-block;
		width: 48px;
		height: 20px;
		background: #fff;
		border: 2px solid ${({ theme }) => theme.color.blue};
		border-radius: 20px;
		transition: 0.2s;
	}
	.switch_label:hover {
		background: ${({ theme }) => theme.color.sky};
	}
	.onf_btn {
		position: absolute;
		top: 1px;
		left: 1px;
		display: inline-block;
		width: 14px;
		height: 14px;
		border-radius: 20px;
		background: ${({ theme }) => theme.color.blue};
		transition: 0.2s;
	}

	.switch_input:checked + .switch_label {
		background: ${({ theme }) => theme.color.lightBlue};
		border: 2px solid ${({ theme }) => theme.color.lightBlue};
	}

	.switch_input:checked + .switch_label:hover {
		background: ${({ theme }) => theme.color.blue};
	}

	/* move */
	.switch_input:checked + .switch_label .onf_btn {
		left: 28px;
		background: #fff;
		box-shadow: 1px 2px 3px #00000020;
	}
`;
