import React from 'react';
import * as S from './SearchWordItem.style';

import TooltipItem from '../../../../../tooltipItem/TooltipItem';

const SearchWordItem = ({ word, index, onItemClick, onRemove }) => {
	return (
		<S.Container key={`${word}-${index}`}>
			<S.ContainerWrapper>
				<S.HistoryWordName onClick={() => onItemClick(index)}>
					<p className={`${word}-${index}`}>{word}</p>
				</S.HistoryWordName>
				<S.HistoryWordDeleteButton onClick={() => onRemove(index)}>
					×
				</S.HistoryWordDeleteButton>
				<TooltipItem
					anchorSelect={`.${word}-${index}`}
					toolTipPlace={'top-end'}
					toolTipStyle={{
						zIndex: '99',
						backgroundColor: 'green',
						borderRadius: '20px',
					}}
					toolTipContent={word}
				/>
			</S.ContainerWrapper>
		</S.Container>
	);
};

export default SearchWordItem;
