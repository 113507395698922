import styled from 'styled-components';

const goldColor = ({ theme }) => theme.color.gold;
const pinkColor = ({ theme }) => theme.color.pink;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: 100px;
	padding: 0;
	/* background-color: ${({ isItemHovered }) =>
		isItemHovered ? '#fed242' : 'rgba(235, 235, 235, 1)'};
	border: 1px solid rgba(220, 220, 220, 1); */

	& .main {
		&:hover {
			background-color: goldColor;
			transition: all 0.3s;
			/* filter: brightness(95%); */
		}
	}

	& .sub {
		// background-color: rgba(217, 229, 255, 1);

		&:hover {
			background-color: pinkColor;
			transition: all 0.3s;
			/* filter: brightness(95%); */
		}
	}
`;

export const ModalContainer = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(33, 33, 33, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	animation: fade-in forwards 0.3s;
	@keyframes fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

export const TitleContent = styled.div`
	padding: 0;
	margin: 0;

	& > span {
		padding: 0;
		margin: 0;
		font-family: 'NotoSansKR-Bold';
		font-size: 1.6rem;
		padding: 0.35rem 1.7rem;
		border-radius: 1.5rem;
	}

	& .main {
		color: ${({ isItemHovered }) =>
			isItemHovered ? 'rgba(0, 0, 0, 1)' : 'rgba(100, 100, 100, 1)'};
		background-color: ${({ isItemHovered }) =>
			isItemHovered ? goldColor : 'rgba(255, 255, 255, 0)'};
	}

	& .sub {
		color: ${({ isItemHovered }) =>
			isItemHovered ? 'rgba(0, 0, 0, 1)' : 'rgba(100, 100, 100, 1)'};
		background-color: ${({ isItemHovered }) =>
			isItemHovered ? pinkColor : 'rgba(255, 255, 255, 0)'};
	}

	transition: all 0.3s;
`;
