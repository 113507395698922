import { authApi, createParams } from './index';

export const getUsers = async (request) => {
	console.log('getUsers', request);
	try {
		const params = createParams(request);
		const { data } = await authApi.get('/portal/collection/name/face-users', {
			params,
		});

		console.log('getUsers result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getProfiles = async ({ profiles: _profiles }) => {
	console.log('getProfiles', _profiles);
	try {
		let profileData = [];
		const promises = _profiles?.map(async (_profile) => {
			const { data } = await authApi.get(
				'/portal/profile/id/face-users?id=' + _profile?.id,
			);
			if (data?.length) {
				profileData = [...profileData, ...data];
			}
		});

		const results = await Promise.all(promises);

		// for (let i = 0; i < _profiles?.length; ++i) {
		// 	// console.log('for:', i);
		// 	const { data } = await authApi.get(
		// 		'/portal/profile/id/face-users?id=' + _profiles[i]?.id,
		// 	);
		// 	console.log('getProfiles', data);
		// 	if (data?.length) {
		// 		profileData = [...profileData, ...data];
		// 	}
		// }

		console.log('getProfiles result', profileData);
		return profileData;
	} catch (error) {
		console.error(error);
	}
};

export const getUsersParent = async (parentId) => {
	try {
		const { data } = await authApi
			.get(`/portal/collection/all/faceusers/${parentId}`)
			.catch((error) => {
				return false;
			});

		console.log('getUsersParent result', data);
		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const createUser = async (request) => {
	try {
		console.log('createUser', JSON.stringify(request));
		const { data } = await authApi.post(
			'/portal/collection/face-users',
			request,
		);

		console.log('createUser result', data);
		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const updateUser = async (request) => {
	try {
		const { data } = await authApi.put(
			'/portal/collection/face-users',
			request,
		);

		console.log('updateUser result', data);
		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const deleteUser = async (request) => {
	try {
		const { data } = await authApi.delete('/portal/collection/face-users', {
			data: request,
		});

		// console.log('getUserFace result', data);
		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

// const UserApi = {
// 	getExpression: async () => {
// 		try {
// 			const { data } = defaultApi.get('');
// 			return data;
// 		} catch (error) {
// 			console.error(error);
// 		}
// 	},
// };
//
// export default UserApi;
