import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import * as S from './UserSelect.style';
import SelectedUser from './SelectedUser';
import DropdownUser from './DropdownUser';
import { RootStoreContext } from '../../../App';

const UserSelect = observer(({ users }) => {
	const { alarmStore } = useContext(RootStoreContext);
	const [selectedUser, setSelectedUser] = useState('전체');
	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		if (selectedUser === '전체') {
			alarmStore.setSelectedUser('전체');
		} else if (selectedUser === '공통') {
			alarmStore.setSelectedUser('공통');
		} else {
			alarmStore.setSelectedUser(selectedUser);
		}
	}, [selectedUser]);

	return (
		<S.Container>
			<div className='dropdown'>
				<p>대상</p>
				<div className='selected' onClick={() => setClicked((state) => !state)}>
					<SelectedUser
						selectedUser={selectedUser}
						clicked={clicked}></SelectedUser>
				</div>
			</div>
			<S.DropdownUserContainer
				className={`dropdown-contents ${clicked ? 'clicked' : ''}`}>
				<S.UserSelectAllBox>
					<div
						className={selectedUser === '전체' ? 'selected' : ''}
						onClick={() => {
							setSelectedUser('전체');
						}}>
						전체
					</div>
					<div
						className={selectedUser === '공통' ? 'selected' : ''}
						onClick={() => {
							setSelectedUser('공통');
						}}>
						공통
					</div>
				</S.UserSelectAllBox>
				{users.map((user, index) => {
					return (
						<DropdownUser
							key={index}
							user={user}
							onClick={() => {
								setSelectedUser(user);
								setClicked(false);
							}}></DropdownUser>
					);
				})}
			</S.DropdownUserContainer>
		</S.Container>
	);
});

export default UserSelect;
