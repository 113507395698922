import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import * as S from './DockLikuConnect.style';

import { RootStoreContext } from '../../../App';
import Image from '../../image/Image';
import { useLocation } from 'react-router-dom';

const DockLikuConnect = observer(({ controller, music }) => {
	const { likuStore } = useContext(RootStoreContext);

	const [isHovering, setIsHovering] = useState(false);
	const controllerRef = useRef(null);
	const musicRef = useRef(null);

	const location = useLocation();

	const disconnect = () => {
		likuStore.setThisLiku();
		controller.onClick(false);
		music.onClick(false);
	};

	useEffect(() => {
		controller.onClick(false);
		music.onClick(false);
	}, [location.pathname]);

	return (
		<S.Content>
			<S.LikuWrap>
				{isHovering && (
					<S.Serial>
						<p>{likuStore.thisLiku.serial}</p>
						<p>v{likuStore.thisLiku?.version?.likuVer || ''}</p>
					</S.Serial>
				)}
				<S.Mind
					onMouseOver={() => setIsHovering(true)}
					onMouseOut={() => setIsHovering(false)}>
					<div></div>
					<div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					{isHovering && (
						<S.DisconnectButton onClick={() => disconnect()}>
							{/*<div>*/}
							<Image srcArray={S.unlink_list} />
							연결해제
							{/*</div>*/}
						</S.DisconnectButton>
					)}
				</S.Mind>
			</S.LikuWrap>
			<S.Controls>
				<S.Menu
					ref={musicRef}
					className='music'
					click={music.click}
					onClick={() => music.onClick()}>
					<Image srcArray={S.music_list} />
				</S.Menu>
				<S.Menu
					ref={controllerRef}
					className='controller'
					click={controller.click}
					onClick={() => controller.onClick()}>
					<Image srcArray={S.controller_list} />
				</S.Menu>
			</S.Controls>
			{/* <S.Line /> */}
			{/* <S.Menu className='favorites'>
				<Image srcArray={S.favorites_list} />
			</S.Menu> */}
		</S.Content>
	);
});

export default DockLikuConnect;
