import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	color: rgba(99, 99, 99, 1);
	height: 2rem;
	width: 150px;

	/* flex-basis: 33.33%; // 한 줄에 표시될 요소의 개수
	justify-content: space-between; // 요소들 사이의 거리를 동일하게
	margin-bottom: 1rem; // 요소들 사이의 여백 */
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	font-family: 'NotoSansKR-Regular';
	font-size: 1.5rem;
	padding: 0.8rem 1rem;
	border-radius: 1.2rem;

	&:hover {
		background-color: ${({ theme }) => theme.color.gold};
		font-weight: bold;
		transition: all 0.3s;
	}

	& .clicked {
		background-color: ${({ theme }) => theme.color.gold};
		font-weight: bold;
	}
`;
