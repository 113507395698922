import styled from 'styled-components';
import shortcut from '../../assets/image/shortcut/shortcut.webp';
import shortcut2x from '../../assets/image/shortcut/shortcut@2x.webp';
import shortcut3x from '../../assets/image/shortcut/shortcut@3x.webp';
import documentSvg from '../../assets/image/document/document.svg';

const shortcut_list = [shortcut, shortcut2x, shortcut3x];
export { shortcut_list, documentSvg };

export const Container = styled.div`
	& > img {
		width: 120px;
		position: absolute;
		right: 2rem;
		top: -4rem;
		${({ theme }) =>
			theme.common.setWidthResponsive({
				mobile: '120px',
				tablet: 'auto',
				desktop: 'auto',
			})}
	}
	@keyframes floatingAnimation {
		0% {
			transform: translate(0, 0);
		}
		50% {
			transform: translate(0, -10px);
		}
		100% {
			transform: translate(0, 0);
		}
	}
	font-family: NotoSansKR-Medium;
	width: 100%;
	height: 100%;
	border-radius: 0.5rem;
	min-height: 120px;
	background: ${({ backColor }) =>
		backColor === 'gradient1'
			? 'linear-gradient(124deg, #F68FA9, #6153EC)'
			: backColor === 'gradient2'
			? 'linear-gradient(124deg, #6153EC,#66F1F1)'
			: backColor === 'gradient3'
			? 'linear-gradient(124deg, #81BA7B, #E4F7BA)'
			: backColor};
	animation: rainbow 7s infinite ease;

	@keyframes rainbow {
		0% {
			background-position: 0% 100%;
		}
		35% {
			background-position: 80% 20%;
		}
		75% {
			background-position: 25% 60%;
		}
		100% {
			background-position: 0% 100%;
		}
	}
	background-size: 200% 200%;
	background-repeat: no-repeat;

	top: 0;
	left: 0;
	position: absolute;
	padding: ${({ uiStyle }) =>
		uiStyle === 'small' ? '2rem 0 0 2rem' : '3.2rem 0 0 3.2rem'};
	display: flex;
	flex-direction: column;
	opacity: 1;
	transition: all 0.5s;
	transform: translate(0, 0);
	color: white;
	border-radius: 2rem;
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
	pre {
		text-align: left;
		color: white;
		font-size: ${({ uiStyle }) => (uiStyle === 'small' ? '1.8rem' : '2.8rem')};
		&.sub {
			font-size: ${({ uiStyle }) => (uiStyle === 'small' ? '1.2rem' : '2rem')};
		}
	}
	hr {
		margin: 1rem 0;
		border: 1px solid rgba(0, 0, 0, 0.5);
	}

	& > p {
		opacity: 0;
		text-align: left;
		color: white;
		font-size: 1.6rem;
	}

	&:hover {
		& > p {
			opacity: 1;
			transition: 0.6s opacity;
			transform: translate(0, 0);
			padding-right: 2rem;
		}
		& > div > p {
			transition: 0.6s all;
			transform: translate(0, 0);
			padding-right: 2rem;
		}
		pre {
			&.sub {
				display: none;
			}
			transition: 0.6s padding;
			position: absolute;
			bottom: ${({ uiStyle }) => (uiStyle === 'small' ? '0rem' : '2rem')};
			padding-bottom: 2rem;
			left: ${({ uiStyle }) => (uiStyle === 'small' ? '2rem' : '3rem')};
			white-space: nowrap;
			font-size: 1.5rem;
		}
		& > div {
			p {
				opacity: 0;
			}
		}
		& > img {
			animation: floatingAnimation 3s infinite ease;
		}
		transform: translate(0, 0);
	}
`;

export const ShortCutButton = styled.div`
	width: fit-content;
	display: flex;
	column-gap: 2rem;
	opacity: 1;
	p {
		white-space: nowrap;
	}
	img {
		object-fit: fill !important;
		width: 20px !important;
		height: 20px !important;
		border-radius: 0 !important;
		filter: brightness(1000%) !important;
	}
	position: absolute;
	right: ${({ uiStyle }) => (uiStyle === 'small' ? '2rem' : '5rem')};
	bottom: ${({ uiStyle }) => (uiStyle === 'small' ? '2rem' : '4rem')};
`;
