import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import * as S from './OldPortalModal.style';

import { RootStoreContext } from '../../App';

const OldPortalModal = observer(() => {
	const { likuStore } = useContext(RootStoreContext);

	return (
		<S.Container>
			<S.Content>
				<S.Title>리쿠를 4.0 이상으로 업데이트해주세요.</S.Title>
				<S.Info>
					업데이트를 완료하고
					<br />
					새로운 포털에서 더 많은 교육 콘텐츠를 만나 보세요!
					<br />
					<br />
					리쿠를 업데이트하지 않으면,
					<br />
					이전 포털을 계속 사용하셔야 합니다.
				</S.Info>
				<S.ButtonWrap>
					<button className='back' onClick={() => likuStore.setThisLiku()}>
						닫기
					</button>
					<button
						className='reconnect'
						onClick={() => {
							window.location.href = 'http://portal.likuwithme.com/';
						}}>
						이전 포털 바로가기
					</button>
				</S.ButtonWrap>
			</S.Content>
		</S.Container>
	);
});

export default OldPortalModal;
