import styled from 'styled-components';

import calendar from '../../assets/image/alarm/calendar-minus-fill@3x.webp';
import meal from '../../assets/image/alarm/meal@3x.webp';
import play from '../../assets/image/alarm/play-filled@3x.webp';

export { calendar, meal, play };

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: start;
	height: 100%;
	column-gap: 2rem;
`;

export const UserBox = styled.div`
	flex: 2;
	min-height: 100px;
	min-width: 150px;
	width: fit-content;
`;

export const AlarmBox = styled.div`
	padding: 0.5rem 3rem 1rem 3rem;
	flex: 3;
	min-width: 200px;
	min-height: 100px;
	background-color: white;
	border-radius: 20px;
	border: solid 1px #f0f0f0;
`;

export const AlarmTitle = styled.div`
	font-size: 2rem;
	& > div {
		padding: 2rem 0;
		display: flex;
		justify-content: start;
		flex-wrap: nowrap;
		column-gap: 1rem;
		img {
			width: 1.8rem;
			height: 1.8rem;
		}
	}
`;

export const MakeAlarmBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	padding: 1rem 0;
	cursor: pointer;
	padding: 4rem 0 4rem 4rem;
	background-color: #ebebe9;
	border-radius: 12px;
	font-family: 'NotoSansKR-Regular';
	font-size: 1.6rem;
	&:hover {
		background-color: #dcdcdc;
	}
	margin-bottom: 3rem;
`;

export const MakeAlarmModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000000;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;
