import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import * as S from './PlayList.style';
import * as CS from './DockLikuController.style';

import useMotionQuery from '../../../../hooks/queries/useMotionQuery';
import { RootStoreContext } from '../../../../App';

const Motion = observer(({ motion, index }) => {
	const { motionStore } = React.useContext(RootStoreContext);
	const { data } = useMotionQuery(motion.uuid);

	useEffect(() => {
		if (data) {
			console.log('motionData', data, motion);
			motionStore.updateMotion(index, data);
		}
	}, [data]);

	// const isDone =

	return (
		<S.Motion
			isPlay={motionStore.playIndex === index}
			isDone={motionStore.playIndex > index}>
			<span className='number'>{index + 1}</span>
			<div className='info'>
				<div className='isPlay' />
				<span>{motion?.name || motion?.info}</span>
			</div>
			{/*<button onClick={() => motionStore.setPlayList(motion, index)}>*/}
			{/*	<Image srcArray={S.adjustable_list} />*/}
			{/*</button>*/}
			<button onClick={() => motionStore.setPlayList(motion, index)}>
				{'x'}
			</button>
		</S.Motion>
	);
});

const PlayList = observer(() => {
	const { motionStore, likuStore } = React.useContext(RootStoreContext);
	const [myMotions, setMyMotions] = useState([]);
	const [scenarioTitle, setScenarioTitle] = useState('');

	useEffect(() => {
		console.log('setPlayList', myMotions);
	}, [myMotions]);

	useEffect(() => {
		if (motionStore.playList?.length) {
			const motions = motionStore.playList.flatMap((motion) => {
				if (motion?.motions?.length) {
					return motion.motions;
				} else {
					return [motion];
				}
			});
			setMyMotions(motions);
		} else {
			setMyMotions([]);
		}
	}, [motionStore.playList?.length, scenarioTitle]);

	return (
		<S.PlayListBox className='dark'>
			<div className='title'>
				재생 목록 창
				<input
					placeholder='표현 이름을 입력하세요.'
					value={scenarioTitle}
					onChange={(e) => setScenarioTitle(e.target.value)}
				/>
			</div>
			<div className='content'>
				<S.List>
					{myMotions.map((myMotion, index) => {
						console.log('payload', myMotion);
						console.log('index***', index);
						return <Motion key={index} motion={myMotion} index={index} />;
					})}
				</S.List>
				<div className='rowWrap'>
					<S.Button
						className='list'
						onClick={() => {
							if (scenarioTitle !== '') {
								motionStore.createScenario(scenarioTitle);
								setScenarioTitle('');
							} else {
								alert('이름을 입력해주세요.');
							}
						}}>
						목록 저장
					</S.Button>
					<CS.Button
						onClick={() => {
							if (likuStore.thisLiku.motionStatus !== 'sleeping') {
								if (motionStore.playIndex === -1) {
									motionStore.setPlay();
								} else {
									likuStore.stop();
									likuStore.setIsControl(false);
									motionStore.setPlayIndex(-1);
								}
							} else {
								alert(
									'리쿠가 잠들어있어요.\n리쿠를 깨운 후 다시 시도해주세요.',
								);
							}
						}}>
						{motionStore.playIndex === -1 ? '목록 재생' : '정지'}
					</CS.Button>
				</div>
			</div>
		</S.PlayListBox>
	);
});

export default PlayList;
