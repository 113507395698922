import styled from 'styled-components';

import play from '../../assets/image/apps/Play.webp';
import play2x from '../../assets/image/apps/Play@2x.webp';
import play3x from '../../assets/image/apps/Play@3x.webp';

import edu from '../../assets/image/apps/Edu.webp';
import edu2x from '../../assets/image/apps/Edu@2x.webp';
import edu3x from '../../assets/image/apps/Edu@3x.webp';

import manage from '../../assets/image/apps/Manage.webp';
import manage2x from '../../assets/image/apps/Manage@2x.webp';
import manage3x from '../../assets/image/apps/Manage@3x.webp';

import custom from '../../assets/image/apps/Custom.webp';
import custom2x from '../../assets/image/apps/Custom@2x.webp';
import custom3x from '../../assets/image/apps/Custom@3x.webp';

import foo1 from '../../assets/image/FooImg.png';
import foo2 from '../../assets/image/FooImg2.png';
import foo3 from '../../assets/image/FooImg3.png';
import foo4 from '../../assets/image/FooImg4.png';

import documentSvg from '../../assets/image/document/document.svg';
import shareSvg from '../../assets/image/share/share.svg';
import folderSvg from '../../assets/image/folder/folder_color.svg';

import playBackground from '../../assets/image/cardBackground/enhanced-image.png';

export {
	play,
	play2x,
	play3x,
	edu,
	edu2x,
	edu3x,
	manage,
	manage2x,
	manage3x,
	custom,
	custom2x,
	custom3x,
	foo1,
	foo2,
	foo3,
	foo4,
	playBackground,
	documentSvg,
	shareSvg,
	folderSvg,
};

export const ContainerBox = styled.div`
	position: relative;
	width: 100%;
	padding: 0 10rem;
`;

export const AppBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	align-items: center;
	column-gap: 8%;
	row-gap: 2rem;
	margin: 4rem auto 6rem auto;
	transition: all 0.3s;
	flex-wrap: wrap;
	width: 100%;
	${({ theme }) => theme.device.mobile} {
		flex-direction: row;
	}
`;

export const Buttonlayer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	transition: all 0.5s;
	transform: translateY(2.5rem);
	background-color: ${({ backColor }) => backColor};
	flex: 1;
	height: ${({ uiStyle }) => (uiStyle === 'small' ? '120px' : '250px')};
	width: 100%;
	border-radius: 2rem;
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
	${({ theme, uiStyle }) =>
		theme.common.setHeightResponsive({
			mobile: uiStyle === 'small' ? '120px' : '250px',
			tablet: uiStyle === 'small' ? '120px' : '250px',
			desktop: uiStyle === 'small' ? '120px' : '250px',
		})}
	&:hover {
		backdrop-filter: blur(5px);
		filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
	}
`;

export const Button = styled.button`
	position: relative;
	display: inline-block;
	align-items: center;
	height: ${({ uiStyle }) => (uiStyle === 'small' ? '120px' : '250px')};
	&:first-child > img {
		width: 100%;
		height: ${({ uiStyle }) => (uiStyle === 'small' ? '120px' : '250px')};
		${({ theme, uiStyle }) =>
			theme.common.setHeightResponsive({
				mobile: uiStyle === 'small' ? '120px' : '250px',
				tablet: uiStyle === 'small' ? '120px' : '250px',
				desktop: uiStyle === 'small' ? '120px' : '250px',
			})}
		aspect-ratio: ${({ uiStyle }) => (uiStyle === 'small' ? 3 : 5 / 3)};
		position: relative;
		display: flex;
		justify-content: center;
		object-fit: cover;
		border-radius: 2rem;
	}

	& {
		cursor: pointer;
		border: none;
		background-color: transparent;

		&:hover img {
			filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
		}
	}
`;

export const BottomBtnBox = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	align-items: center;
	column-gap: 2rem;
	row-gap: 2rem;
	margin: 8rem auto;
	transition: all 0.3s;
	flex-wrap: wrap;
	width: 100%;
	flex-direction: column;
	${({ theme }) => theme.device.mobile} {
		flex-direction: row;
	}
`;
