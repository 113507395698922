import { authApi } from './index.js';

export const getAllTags = async () => {
	try {
		const { data: tags } = await authApi.get('/personal/tags');
		console.log('tags', tags);
		return tags;
	} catch (error) {
		console.error(error);
	}
};
