import { useEffect } from 'react';

/**
 * 컴포넌트 외부 위치를 클릭하면 펼쳐진 컴포넌트가 닫히도록 하는 훅
 * @param {import('react').Ref} elementRef : 기준 컴포넌트
 * @param {boolean} stateVariable : 상태를 관리하는 변수
 * @param {function} stateFunction : 상태를 업데이트하는 함수
 */
const useClickOutside = ({ elementRef, stateVariable, stateFunction }) => {
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (elementRef?.current && !elementRef.current.contains(e.target)) {
				stateFunction(false);
			}
		};

		if (stateVariable !== 'undefined') {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [elementRef?.current, stateVariable, stateFunction]);
};

export default useClickOutside;
