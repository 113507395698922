import { makeAutoObservable, runInAction } from 'mobx';
import { getMetaverseFaceImageList } from '../../services/apis/BirthdayApi';

class BirthdayStore {
	rootStore;
	childList = [];
	selectedChildList = [];
	photos = [];
	memoryPhotos = [];
	metaverseDBPhotos = [];
	prevPhoto = null;
	metaverseImageDB = null;
	startIndex;
	endIndex;
	finalEndIndex;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		this.childList = [];
		this.photos = [];
		this.prevPhoto = null;
		this.metaverseImageDB = null;
		this.selectedChildList = [];
		this.memoryPhotos = [];
		this.metaverseDBPhotos = [];
		this.startIndex = 0;
		this.endIndex = -1;
		this.finalEndIndex = -1;
	}

	setSelectedChildList(_list) {
		runInAction(() => {
			this.selectedChildList = _list;
		});
	}

	setChildList(_list) {
		runInAction(() => {
			this.childList = _list;
		});
	}

	setStartIndex(_index) {
		runInAction(() => {
			this.startIndex = _index;
		});
	}

	setEndIndex(_index) {
		runInAction(() => {
			this.endIndex = _index;
		});
	}

	setMemoryPhotos(_photos) {
		runInAction(() => {
			this.memoryPhotos = _photos;
		});
	}

	setMetaverseDBPhotos(_photos) {
		runInAction(() => {
			this.metaverseDBPhotos = _photos;
		});
	}

	setMetaverseImageDB(_data) {
		runInAction(() => {
			this.metaverseImageDB = _data;
		});
	}

	setFinalEndIndex(_index) {
		runInAction(() => {
			this.finalEndIndex = _index;
		});
	}

	setPhotos(_photos) {
		runInAction(() => {
			this.photos = _photos;
		});
	}

	setPrevPhoto(_photo) {
		runInAction(() => {
			this.prevPhoto = _photo;
			console.log('prev', this.prevPhoto);
		});
	}

	requestLikuMemory() {
		const likuUuid = this.rootStore.likuStore.thisLiku.uuid;
		if (!likuUuid) return;
		let topic = `liku/${likuUuid}/vis/image/metaverse`;
		let message = 'memory';
		console.log('memory', topic, message);
		this.rootStore.mqttStore.publishMessage(topic, message);
	}

	requestNewPhoto() {
		const likuUuid = this.rootStore.likuStore.thisLiku.uuid;
		let topic = `liku/${likuUuid}/vis/image/metaverse`;
		let message = 'new';
		console.log('사진찍기');
		this.rootStore.mqttStore.publishMessage(topic, message);
	}

	getMetaverseImageDB(topic, data) {
		console.log('sub', topic, data);
		if (data !== null) {
			this.setMetaverseImageDB(data);
		}
	}

	async getMetaverseDBPhotos(_serial) {
		const data = await getMetaverseFaceImageList(_serial);
		return data;
	}

	setWholeImg(topic, data) {
		console.log('sub', topic, data);
		if (data !== null) {
			// const image = URL.createObjectURL(
			// 	new Blob([data], { type: 'image/png' }),
			// );

			this.setPrevPhoto(data);
			// console.log('setWholeImg', topic, image, this.prevPhoto);
		}
	}

	setMemoryImg(topic, data) {
		console.log('sub', topic, data);
		if (data !== null) {
			this.setMemoryPhotos(data);
		}
	}
}

export default BirthdayStore;
