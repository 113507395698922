import styled, { css } from 'styled-components';

import apps from '../../../../../assets/image/apps/apps.webp';
import apps2 from '../../../../../assets/image/apps/apps@2x.webp';
import apps3 from '../../../../../assets/image/apps/apps@3x.webp';

import trashCan from '../../../../../assets/image/trashcan/trashcan.webp';
import trashCan2 from '../../../../../assets/image/trashcan/transhcan@2x.webp';
import trashCan3 from '../../../../../assets/image/trashcan/trashcan@3x.webp';

const app_list = [apps, apps2, apps3];
const trash_can_list = [trashCan, trashCan2, trashCan3];

export { app_list, trash_can_list };

export const Container = styled.div`
	display: inline-block;
	padding: 0;
	width: 100%;
	min-height: 20vh;
	min-width: 400px; /* 최근 검색어 영역 최소 너비 지정 */
`;

export const TitleSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: center;
	height: 3.5rem;
	padding: 3.5rem 0 0 1.5rem;
	padding-right: 0;
`;

export const SectionTitle = styled.div`
	display: flex;
	font-family: 'NotoSansKR-Bold';
	font-size: 2rem;
	width: 100%;
`;

export const ContentSection = styled.div`
	display: flex;
	width: 100%;
	overflow: auto;
	max-height: 30vh;
	margin-top: 0.5rem;
	padding: 1rem;
`;

export const HistoryWordItemRow = styled.ul`
	display: flex;
	flex-wrap: wrap;
	/* max-width: 98%; */
	max-height: 50vh;
	transition: all 0.3s;
	list-style: none;
	padding: 0;
	margin: 0;
	list-style-type: none;
`;

export const ClearAllHistoryWordItems = styled.div`
	display: flex;
	margin: 15px 15px;
	font-size: 2rem;
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 3px;

	&:hover {
		cursor: pointer;
		font-weight: 700;
		transition: all 0.3s;
	}
`;
