import { runInAction, reaction, makeAutoObservable } from 'mobx';
import KorToEn from '../../utils/KorToEn';

class InteractiveStore {
	rootStore;
	nodes = [];
	edges = [];
	currentId = -1;
	condition = { type: '', value: '' };
	listenText = '';
	arucoColor = '';
	arucoAction = null;
	touchPart = '';
	button = null;
	components = [];
	similarColors = {
		red: ['pink', 'orange'],
		pink: ['red', 'orange'],
		orange: ['red', 'pink'],
		blue: ['cyan', 'green'],
		cyan: ['blue', 'green'],
		green: ['blue', 'cyan'],
	};

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		this.nodes = [];
		this.edges = [];
		this.currentId = -1;
		this.condition = { type: '', value: '' };
		this.listenText = '';
		this.button = null;
		this.components = [];

		reaction(
			() => this.currentId,
			(currentId) => {
				console.log('reaction, currentId', this.currentId);
				this.setCondition({ type: '', value: '' });
				const currentNode = this.getCurrentNode();
				const curNodeBackground = currentNode?.data?.background;
				const curNodeBackgroundButton = curNodeBackground?.button;
				const curNodeBackgroundComponent = curNodeBackground?.component;
				console.log(
					'nodeComponent111',
					currentId,
					currentNode,
					curNodeBackgroundButton,
					curNodeBackgroundComponent,
				);

				if (curNodeBackgroundButton?.apply) {
					this.setButton(curNodeBackgroundButton);
				} else {
					this.buttonClear();
				}

				this.setComponents(curNodeBackgroundComponent);
				const nodeIndex = this.getNodeIndexById(currentId);
				console.log('nodeComponent222', nodeIndex);
				this.rootStore.videoStore.setSceneNumber(nodeIndex);
				// this.rootStore.playerControlStore.likuPlay();
			},
		);

		reaction(
			() => this.condition,
			() => {
				if (this.condition.type !== '' && this.condition.value !== '') {
					this.checkCondition();
				}
			},
		);
	}

	setButton(_button) {
		runInAction(() => {
			console.log('@#@#buttons', _button);
		});
	}

	setComponents(_components) {
		runInAction(() => {
			console.log('@#@#components', _components);
			this.components = _components;
		});
	}
	getCurrentNode() {
		console.log('getCurrentNode', this.currentId);
		if (!this.nodes) return;
		const currentNode = this.nodes.find((node) => {
			return node.id === this.currentId?.toString();
		});
		console.log('getCurrentNode currentNode', currentNode);

		if (currentNode) {
			return currentNode;
		} else if (this.nodes.length) {
			runInAction(() => {
				this.currentId = this.nodes[0].id;
			});
			console.log('this.nodes.length11', this.currentId);
			console.log('this.nodes.length22', this.nodes[0]);
			return this.nodes[0];
		}
	}

	setCurrentId(_id) {
		console.log('setCurrentId', _id);
		runInAction(() => {
			this.currentId = _id;
		});
	}

	getNextNodes() {
		const currentNode = this.getCurrentNode();
		console.log('getNextNodes', currentNode);
		let nextPool = [];
		this.edges?.forEach((edge) => {
			if (edge.source === currentNode.id) {
				const nextNodeIndex = this.getNodeIndexById(edge.target);
				// console.log('nextNodes', edge, this.nodes[nextNodeIndex]);

				nextPool.push({ next: edge.target, condition: { ...edge.data } });
			}
		});
		return nextPool;
	}

	setNodes(_nodes) {
		runInAction(() => {
			this.nodes = _nodes;
		});
	}

	setEdges(_edges) {
		runInAction(() => {
			this.edges = _edges;
		});
	}

	setCondition(_condition) {
		runInAction(() => {
			this.condition = _condition;
			console.log('_condition', _condition);
		});
	}

	areSetAndArrayEquivalent(set1, arr2) {
		if (set1.size !== arr2.length) return false;

		for (let item of set1) {
			if (!arr2.includes(item)) return false;
		}
		return true;
	}

	hasSound(nextPool) {
		return nextPool.find((next) => {
			// console.log(next);
			return next.condition.type === 'Sound';
		});
	}

	hasArucoSetting(nextPool) {
		return nextPool.find((next) => {
			// console.log(next);
			return next.condition.type?.includes('aruco_setting');
		});
	}

	hasAruco(nextPool) {
		return nextPool.find((next) => {
			// console.log(next);
			return next.condition.type?.includes('aruco_color');
		});
	}

	hasButton(nextPool) {
		return nextPool.find((next) => {
			// console.log(next);
			return next.condition.type?.includes('Button');
		});
	}

	hasRandom(nextPool) {
		const randomNodeList = nextPool.filter((node) => {
			return (
				node.condition.type?.includes('Conditional') &&
				node.condition.value?.includes('random')
			);
		});

		return randomNodeList.length > 0 ? randomNodeList : false;
	}

	hasTouchSetting(nextPool) {
		return nextPool.find((next) => {
			// console.log(next);
			return next.condition.type?.includes('touch_setting');
		});
	}

	hasTouch(nextPool) {
		return nextPool.find((next) => {
			// console.log(next);
			return (
				next.condition.type?.includes('Conditional') &&
				next.condition.value?.includes('touch')
			);
		});
	}

	checkSoundSetting() {
		console.log('@#@#listen 듣기 시작');
		this.rootStore.mqttStore.playerMqtt.setListenText(
			this.rootStore.interactiveStore.setListenText.bind(
				this.rootStore.interactiveStore,
			),
		);

		// let count = 0;
		// const MAXCOUNT = 100;
		// this.setCondition({ type: '', value: '' });
		// const intervalId = setInterval(() => {
		// 	if (count >= MAXCOUNT && nextPool.length) {
		// 		if (
		// 			this.condition.type === 'Sound' &&
		// 			this.condition.value.includes('?')
		// 		) {
		// 			console.log('@#@#listen 잘못들었어');
		// 		}
		// 		this.setCurrentId(nextPool[0].next);
		// 		clearInterval(intervalId);
		// 	} else if (this.condition.type === 'Sound') {
		// 		clearInterval(intervalId);
		// 	}
		// }, 1000);
	}

	checkSound(nextPool) {
		console.log('@#@#listen');
		let andPool = [];
		let updateCurrentId = null;
		for (let next of nextPool) {
			const isAndCondition = next.condition.value.includes('&');
			const isOrCondition = next.condition.value.includes('|');
			const orPool = next.condition.value.split('|');

			if (isAndCondition && andPool.includes(this.condition.value)) {
				andPool = [...andPool, this.condition.value];
				const isEqual = this.areSetAndArrayEquivalent(
					andPool,
					next.condition.value.split('&'),
				);
				if (isEqual) {
					// this.setCurrentId(next.next);
					updateCurrentId = next.next;
					break;
				}
			}
			if (isOrCondition && orPool.includes(this.condition.value)) {
				// this.setCurrentId(next.next);
				updateCurrentId = next.next;
				break;
			}

			// 현재 내가 고른 condition (this.condition)과 다음으로 갈 분기의 condition을 비교해 만족하는 condition이 있다면 해당 conditoin을 NextNode로 설정
			if (
				next.condition.type === this.condition.type &&
				next.condition.value === this.condition.value
			) {
				// this.setCurrentId(next.next);
				updateCurrentId = next.next;
				break;
			} else if (this.condition.value == '?') {
				console.log(
					'this.rootStore.playerControlStore.roles',
					this.rootStore.playerControlStore.roles,
				);
				console.log('this.condition', this.condition);
				const role = Object.keys(this.rootStore.playerControlStore.roles).find(
					(key) => {
						return (
							this.rootStore.playerControlStore.roles[key].liku.uuid ===
							this.condition.uuid
						);
					},
				);

				const message = {
					action: {
						action_name: 'None',
						action_type: 'overlap',
						pitch: 0,
						yaw: 0,
					},
					display: {
						display_name: 'None',
						delay: 0,
						playtime: 1,
						playspeed: 1,
					},
					speech: {
						// speech_name: "emptyp01.mp3",
						speech_name: 'TTS_output',
						TTS: '다시 말해줄래?',
						delay: 0,
						repeat: 1,
					},
					listen: 'transcript',
				};

				this.rootStore.mqttStore?.playerMqtt.playMotion(
					this.rootStore.playerControlStore.executionUuid,
					role,
					message,
				);
				this.setCondition({ type: '', value: '' });
			}
		}
		if (!updateCurrentId && this.condition.value != '') {
			const failNext = nextPool.find((next) => next.condition.value === '오답');
			updateCurrentId = failNext.next;
		}
		console.log('updateCurrentId', updateCurrentId);
		if (updateCurrentId !== null) {
			this.setCurrentId(updateCurrentId);
			this.rootStore.mqttStore.playerMqtt.unsetListenText();
		}
	}

	checkListsEquality(list1, list2) {
		if (list1.length !== list2.length) {
			return false;
		}

		let mismatchCount = 0;
		let maxMismatch = this.rootStore.videoStore.content?.aruco?.aruco_precision
			? 0
			: 1;

		// this.rootStore.videoStore.content?.aruco
		for (let i = 0; i < list1.length; i++) {
			if (list1[i] !== list2[i]) {
				mismatchCount++;
				if (mismatchCount > maxMismatch) {
					return false;
				}
			}
		}

		return true;
	}

	checkAruco(nextPool) {
		for (let next of nextPool) {
			const conditionValue = next.condition.value
				.replace(/[ !()]/g, '')
				.split(',');
			const likuConditionValue = Object.values(this.condition.value);

			let removeDuplicates = [];
			console.log(
				'this.rootStore.videoStore.content?.aruco?.aruco_precision',
				this.rootStore.videoStore.content?.aruco?.aruco_precision,
			);
			if (this.rootStore.videoStore.content?.aruco?.aruco_precision) {
				for (let value of likuConditionValue) {
					if (!removeDuplicates.includes(value)) {
						removeDuplicates.push(value);
						console.log('removeDuplicates11', removeDuplicates);
					} else {
						let isFoundSimilar = false;
						for (let color of this.similarColors[value]) {
							if (!removeDuplicates.includes(color)) {
								removeDuplicates.push(color);
								isFoundSimilar = true;
								console.log('removeDuplicates22', removeDuplicates);
								break;
							}
						}
						if (!isFoundSimilar) {
							removeDuplicates.push(value);
						}
					}
				}
			} else {
				removeDuplicates = likuConditionValue;
			}

			const result = this.checkListsEquality(conditionValue, removeDuplicates);
			const pattern = /^!\([^()]+\)$/;

			if (next.condition.type === this.condition.type) {
				if (result || (pattern.test(next.condition.value) && !result)) {
					console.log('checkAruco setCurrentId', next);
					this.setCurrentId(next.next);
					this.setArucoColor('');
					break;
				}
			}
		}
	}

	setArucoColor(color) {
		runInAction(() => {
			const arucoColor = Object.values(color).toString();
			this.arucoColor = arucoColor;
			// this.arucoColor = color;

			console.log('condition1', this.arucoColor);
			console.log('condition2', arucoColor);
			this.setCondition({ type: 'aruco_color', value: color });
		});
	}

	setArucoAction(nextPool) {
		const condition = nextPool.condition.value.split('&&');
		const touchCondition = condition.find((r) => r.includes('touch'));
		const touchPart = touchCondition.replace('touch-', '');
		const isAruco = condition.includes('aruco');

		console.log('isAruco', isAruco);
		console.log('touchPart', touchPart);
		console.log('this.condition', this.condition);
		console.log('this.arucoAction', this.arucoAction);
		if (
			isAruco &&
			this.arucoAction &&
			this.condition.value.includes(touchPart)
		) {
			this.rootStore.mqttStore?.playerMqtt.playMotion(
				this.rootStore.playerControlStore.executionUuid,
				Object.keys(this.rootStore.playerControlStore.roles)[0],
				this.arucoAction,
			);
			this.arucoAction = '';
			this.setArucoColor('');
			this.setCondition({ type: '', value: '' });
			this.setCurrentId(nextPool.next);
		}
	}

	arucoSave(nextPool) {
		// for (let next of nextPool) {
		// 	console.log('next', next);
		// 	console.log('next.condition.type', next.condition.type);
		// 	if (next.condition.type === this.condition.type) {
		if (this.arucoColor) {
			const aruco = this.rootStore.videoStore.content?.aruco;

			const arucoSplit = this.arucoColor.split(',');
			const actionColor = arucoSplit[1];
			const displayColor = arucoSplit[2];
			const speechColor = arucoSplit[0];

			const message = {
				action: {
					action_name: aruco['2'][actionColor],
					action_type: 'overlap',
					pitch: 0,
					yaw: 0,
				},
				display: {
					display_name: aruco['3'][displayColor],
					delay: 0,
					playtime: 1,
					playspeed: 1,
				},
				speech: {
					// speech_name: "emptyp01.mp3",
					speech_name: 'TTS_output',
					TTS: aruco['1'][speechColor],
					delay: 0,
					repeat: 1,
				},
			};

			this.arucoAction = message;
			this.setCurrentId(nextPool.next);
		}
		// this.setCurrentId(nextPool.next);
	}

	checkButton(nextPool) {
		for (let next of nextPool) {
			if (next.condition.type === this.condition.type) {
				console.log('checkButton setCurrentId');
				this.setCurrentId(next.next);
				break;
			}
		}
	}

	async checkCondition() {
		const nextPool = this.getNextNodes();
		console.log('checkCondition nextPool', nextPool);

		if (!nextPool.length) return;
		if (nextPool.length === 1 && !nextPool[0].condition?.value) {
			console.log('nextPool length 1!!!!');
			this.setCurrentId(nextPool[0].next);
			return true;
		}

		const hasSound = this.hasSound(nextPool);
		const hasArucoSetting = this.hasArucoSetting(nextPool);
		const hasAruco = this.hasAruco(nextPool);
		const hasButton = this.hasButton(nextPool);
		const hasRandom = this.hasRandom(nextPool);
		const hasTouchSetting = this.hasTouchSetting(nextPool);
		const hasTouch = this.hasTouch(nextPool);

		if (hasSound) {
			if (this.condition.type === 'Sound') {
				this.checkSound(nextPool);
			} else {
				this.checkSoundSetting();
			}
			console.log('isSound');
		} else if (hasArucoSetting) {
			console.log('hasArucoSetting', nextPool);
			// const condition = nextPool.condition;
			// const handler = (color) => this.setArucoColor(nextPool.condition, color);
			const aruco = JSON.parse(
				JSON.stringify(this.rootStore.videoStore.content?.aruco),
			);
			delete aruco.aruco_precision;
			this.rootStore.mqttStore.playerMqtt.setArucoMode(
				aruco,
				this.setArucoColor.bind(this),
			);
			this.setCurrentId(nextPool[0].next);
			console.log('is ArucoSetting');
		} else if (hasAruco) {
			console.log('nextPool', nextPool[0]);
			if (nextPool[0].condition.type === 'aruco_color') {
				this.checkAruco(nextPool);
			} else if (nextPool[0].condition.type === 'aruco_color_save') {
				this.arucoSave(nextPool[0]);
			}
			console.log('is Aruco');
		} else if (hasButton) {
			this.checkButton(nextPool);
			console.log('is Button');
		} else if (hasRandom) {
			console.log('is hasRandom');
			const randomNode = Math.floor(Math.random() * hasRandom.length);
			this.setCurrentId(hasRandom[randomNode].next);
		} else if (hasTouchSetting) {
			console.log('is TouchSetting');
			this.rootStore.mqttStore.playerMqtt.setTouchMode(
				'start',
				this.setTouch.bind(this),
			);
			this.setCurrentId(nextPool[0].next);
		} else if (hasTouch) {
			console.log('is hasTouch');
			this.setArucoAction(nextPool[0]);
		} else {
			console.log('is NOT CONDITON !!!');
			return true;
		}
	}

	goNextByNodeId(_id) {
		runInAction(() => {
			this.currentId = _id;
		});
	}

	getNodeIndexById(_id) {
		let currentIndex = 0;
		this.nodes.map((node, index) => {
			if (node.id === _id) {
				currentIndex = index;
			}
		});
		return currentIndex;
	}

	getListenText() {
		return this.listenText;
	}

	setListenText(topic, _text) {
		const topicSpliceArray = topic.split('/');
		console.log('topicSpliceArray', topicSpliceArray);
		console.log('topicSpliceArray[1]', topicSpliceArray[1]);
		runInAction(() => {
			this.listenText = _text;
			console.log('@#@#listen', _text);
			this.setCondition({
				type: 'Sound',
				value: _text,
				uuid: topicSpliceArray[1],
			});
		});
	}

	setTouch(part) {
		runInAction(() => {
			this.setCondition({ type: 'touch', value: part });
			// this.touchPart=part;
		});
	}

	buttonClear() {
		runInAction(() => {
			this.button = null;
			this.components = [];
		});
	}

	clear() {
		runInAction(() => {
			this.currentId = -1;
			this.condition = { type: '', value: '' };
			this.listenText = '';
			this.components = [];
		});
		console.log('currentId clear', this.currentId);
		console.log('components clear', this.components);
	}
}

export default InteractiveStore;
