import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	height: 100%;
	width: 100%;
	column-gap: 2rem;
	font-family: 'NotoSansKR-Regular';
	.dropdown {
		p {
			display: flex;
			align-items: center;
			font-size: 1.5rem;
			white-space: nowrap;
		}
		width: 100%;
		display: flex;
		column-gap: 1rem;
		.selected {
			width: 100%;
		}
	}

	.dropdown-contents {
		margin-top: 1.5rem;
		transform-origin: top;
		transition: all 0.5s;
		display: flex;
		flex-direction: column;

		transform: scaleY(0);
		opacity: 0;
		width: 100%;
		&.clicked {
			transform: scaleY(1);
			opacity: 1;
		}
		& > div {
			cursor: pointer;
			&:hover {
				background-color: ${({ theme }) => theme.color.gray_de};
			}
		}
	}
`;

export const SelectedUserBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	width: 100%;
	min-height: 4rem;
	color: ${({ theme }) => theme.color.gray_70};
	padding: 0.5rem 2rem;
	padding-right: 3rem;
	background-color: white;
	&:hover {
		background-color: ${({ theme }) => theme.color.gray_de};
	}
	border-radius: 2rem;
	border: 1px solid ${({ theme }) => theme.color.gray_70};
	cursor: pointer;
	.arrow img {
		transition: transform 0.3s;
		transform: ${({ clicked }) =>
			clicked ? 'rotate(270deg)' : 'rotate(90deg)'};
		position: absolute;
		top: calc(50% - 5px);
		right: 10px;
		height: 10px;
		aspect-ratio: 1;
	}
	.userImage img {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
	}
	.userImage {
		display: flex;
		align-items: center;
		span {
			white-space: nowrap;
		}
	}
`;

export const DropdownUserBox = styled.div`
	display: flex;
	img {
		border: 1px solid ${({ theme }) => theme.color.gray_cb};
		width: 4rem;
		height: 4rem;
		border-radius: 30%;
		margin-right: 1rem;
	}
	padding: 1rem 0;
	border-bottom: 1px dashed ${({ theme }) => theme.color.gray_cb};
`;

export const DropdownUserContainer = styled.div`
	background-color: white;
	padding: 2rem;
	border-radius: 2rem;
	overflow-y: auto;
	max-height: 65vh;
	border: 1px solid ${({ theme }) => theme.color.gray_eb};
`;

export const UserSelectAllBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 4rem;
	gap: 2rem;
	margin-bottom: 2rem;
	&:hover {
		background-color: white !important;
	}
	& > div {
		font-family: 'NotoSansKR-Bold';
		flex: 1;
		text-align: center;
		border-radius: 8px;
		background-color: #ebebe9;
		padding: 1.5rem 0;
		&:hover {
			filter: brightness(0.9);
		}
		&.selected {
			background-color: #2ece70;
			color: white;
		}
	}
`;
