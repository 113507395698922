import styled from 'styled-components';

export const Container = styled.li`
	margin: 4px 6px;
	border-radius: 15px;
	transition: all 0.3s;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ContainerWrapper = styled.div`
	display: inline-block;
	padding: 4px 8px;
	font-size: 1.4rem;
	background-color: rgba(253, 253, 253, 0.7);
	border-radius: 15px;
	border: 2px solid rgba(200, 200, 200, 0.7);

	&:hover {
		background-color: rgba(190, 190, 190, 0.8);
		transition: all 0.3s;
	}
`;

export const HistoryWordName = styled.span`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	align-self: center;
	color: rgb(120, 120, 120);
	border-radius: 8px;
	font-weight: bold;
	padding: 5px;
	max-width: 12vh;
	font-size: 1.3rem;

	& p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const HistoryWordDeleteButton = styled.span`
	display: inline-flex;
	align-self: center;
	width: 20px;
	height: 20px;
	font-size: 14px;
	text-align: center;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	padding: 4px;
	margin: 1px;
	color: white;
	font-weight: 500;
	border-radius: 50px;
	border: 2px solid rgb(65, 188, 73);
	background-color: rgba(86, 209, 96);

	&:hover {
		font-weight: bolder;
		border-radius: 50px;
		background-color: rgb(0, 230, 106);
		transition: all 0.3s;
	}
`;
