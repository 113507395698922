import { authApi, createParams } from './index';

// export const getUserFace = async (faceUserId, collection) => {
export const getUserFace = async (request) => {
	try {
		console.log('request', request);
		const params = createParams(request);
		const { data } = await authApi.get('/portal/collection/faces', { params });

		// console.log('getUserFace result', data);
		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const getUserFaceParent = async (parentId) => {
	try {
		const { data } = await authApi.get(
			`/portal/collection/all/faces/${parentId}`,
		);

		console.log('getUserFaceParent result', data);
		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const setUserFace = async (request, formData) => {
	try {
		console.log('request', request);
		const params = createParams(request);
		const { data } = await authApi.post('/portal/collection/faces', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			params,
		});

		console.log('setUserFace result', data);
		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};
export const deleteUserFace = async (request) => {
	try {
		const params = createParams(request);
		const { data } = await authApi.delete('/portal/collection/faces', {
			params,
		});

		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const getUserFaces = async (request) => {
	try {
		console.log('request', request);
		const params = createParams(request);
		const url = '/portal/collection/faces/first';
		// const url = '/portal/collection/faces/first2';
		const { data } = await authApi.get(url, {
			params,
		});

		return data;
	} catch (error) {
		console.error(error);
		return false;
	}
};

// const UserApi = {
// 	getExpression: async () => {
// 		try {
// 			const { data } = defaultApi.get('');
// 			return data;
// 		} catch (error) {
// 			console.error(error);
// 		}
// 	},
// };
//
// export default UserApi;
