import React from 'react';
import { observer } from 'mobx-react';
import useUserFaceQuery from '../../../hooks/queries/useUserFaceQuery';
import * as S from './UserSelect.style';
import Image from '../../../components/image/Image';
import { arrow_right_list } from '../../manage/attendanceTable/selectbox/TimeSelectBox.style';

const SelectedUser = observer(({ selectedUser, clicked }) => {
	const { data, isLoading } = useUserFaceQuery(
		selectedUser?.id,
		selectedUser?.collections ? selectedUser.collections[0]?.name : null,
	);
	console.log(data);
	return (
		<S.SelectedUserBox clicked={clicked}>
			{selectedUser === '전체' || selectedUser === '공통' ? (
				selectedUser
			) : (
				<div className='userImage'>
					<Image srcArray={data ? [data[0].path] : ''} />
					<span>{selectedUser.name}</span>
				</div>
			)}
			<div className={'arrow'}>
				<Image srcArray={arrow_right_list} />
			</div>
		</S.SelectedUserBox>
	);
});

export default SelectedUser;
