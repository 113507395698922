import styled from 'styled-components';
import instagram from '../../assets/image/footer/instagram/insta.webp';
import instagram2x from '../../assets/image/footer/instagram/insta@2x.webp';
import instagram3x from '../../assets/image/footer/instagram/insta@3x.webp';
import instagram_color from '../../assets/image/footer/instagram/insta-color.webp';
import instagram_color2x from '../../assets/image/footer/instagram/insta-color@2x.webp';
import instagram_color3x from '../../assets/image/footer/instagram/insta-color@3x.webp';
import youtube from '../../assets/image/footer/youtube/youtube.webp';
import youtube2x from '../../assets/image/footer/youtube/youtube@2x.webp';
import youtube3x from '../../assets/image/footer/youtube/youtube@3x.webp';
import youtube_color from '../../assets/image/footer/youtube/youtube-color.webp';
import youtube_color2x from '../../assets/image/footer/youtube/youtube-color@3x.webp';
import youtube_color3x from '../../assets/image/footer/youtube/youtube-color@2x.webp';
import google_play_icon from '../../assets/image/footer/google-play-icon/google-play-icon.webp';
import google_play_icon2x from '../../assets/image/footer/google-play-icon/google-play-icon@2x.webp';
import google_play_icon3x from '../../assets/image/footer/google-play-icon/google-play-icon@3x.webp';
import google_play_icon_color from '../../assets/image/footer/google-play-icon/google-play-icon-color.webp';
import google_play_icon_color2x from '../../assets/image/footer/google-play-icon/google-play-icon-color@3x.webp';
import google_play_icon_color3x from '../../assets/image/footer/google-play-icon/google-play-icon-color@2x.webp';
import apple_app_store from '../../assets/image/footer/apple-app-store/apple-app-store.webp';
import apple_app_store2x from '../../assets/image/footer/apple-app-store/apple-app-store@2x.webp';
import apple_app_store3x from '../../assets/image/footer/apple-app-store/apple-app-store@3x.webp';
import apple_app_store_color from '../../assets/image/footer/apple-app-store/apple-app-store-color.webp';
import apple_app_store_color2x from '../../assets/image/footer/apple-app-store/apple-app-store-color@3x.webp';
import apple_app_store_color3x from '../../assets/image/footer/apple-app-store/apple-app-store-color@2x.webp';
import googleQR from '../../assets/image/footer/qrCode/googleQR.svg';
import appleQR from '../../assets/image/footer/qrCode/appleQR.svg';

export {
	instagram,
	instagram2x,
	instagram3x,
	instagram_color,
	instagram_color2x,
	instagram_color3x,
	youtube,
	youtube2x,
	youtube3x,
	youtube_color,
	youtube_color2x,
	youtube_color3x,
	google_play_icon,
	google_play_icon2x,
	google_play_icon3x,
	google_play_icon_color,
	google_play_icon_color2x,
	google_play_icon_color3x,
	apple_app_store,
	apple_app_store2x,
	apple_app_store3x,
	apple_app_store_color,
	apple_app_store_color2x,
	apple_app_store_color3x,
	googleQR,
	appleQR,
};

export const Container = styled.footer`
	* {
		box-sizing: border-box;
	}
	background-color: ${({ theme }) => theme.color.gray_f0};
`;

export const Alert = styled.div`
	height: 12rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.color.gray_70};
	font-size: 1.4rem;
	text-align: center;
	background-color: white;
`;

export const Content = styled.article`
	padding: 7.2rem 0rem;
	${({ theme }) => theme.common.container};
	height: 38rem;
	display: flex;
`;

export const LContent = styled.div`
	flex: 2;
	font-size: 1.6rem;
	color: ${({ theme }) => theme.color.gray_70};
	ul {
		margin-bottom: 1.6rem;
		display: flex;
		li {
			padding-right: 1.6rem;
			&:not(:last-child) {
				border-right: black 1px solid;
			}
			margin-right: 1.6rem;
			&:hover {
				text-shadow: 1px 1px 0.5px rgba(0, 0, 0, 0.5);
			}
		}
	}
	& .torooc {
		font-family: Helvetica-Bold;
	}
`;

export const RContent = styled.div`
	flex: 1;
	position: relative;
	ul {
		display: flex;
		column-gap: 1.2rem;
		width: 28rem;
		li {
			padding: 0 1rem;
			img {
				width: 100%;
			}
			flex: 1;
			&:nth-of-type(-n + 2) img:hover {
				filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
			}
		}
	}
	& .QR {
		display: none;
		&.visible {
			display: block;
		}
		pointer-events: none;
		width: 14rem;
		position: absolute;
		left: 14.5rem;
		height: 14rem;
		top: -8rem;
		background-color: rgba(255, 255, 255, 0.8);
		border: 1px solid ${({ theme }) => theme.color.gray_f0};
		border-radius: 32px;
		img {
			padding: 1rem;
		}
	}
`;
