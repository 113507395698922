import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	pointer-events: auto;
`;

export const Content = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 2rem;
	background: white;
	border-radius: 8px;
	${({ theme }) => theme.common.wh('45rem', '27rem')};
	${({ theme }) => theme.common.flex('column', 'center', 'space-around')};
`;

export const Title = styled.h1`
	font-family: NotoSansKR-Bold;
	font-size: 2rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #46423d;
`;

export const Info = styled.span`
	font-family: NotoSansKR-Regular;
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #353535;
`;

export const ButtonWrap = styled.div`
	${({ theme }) => theme.common.wh('100%', 'auto')};
	${({ theme }) => theme.common.flex('row', 'center', 'space-around')};

	button {
		padding: 1rem;
		border: none;
		border-radius: 1rem;
		font-family: NotoSansKR-Bold;
		${({ theme }) => theme.common.wh('45%', '100%')};
	}
	.back {
		background-color: ${({ theme }) => theme.color.gray};
	}
	.reconnect {
		background-color: ${({ theme }) => theme.color.gold};
	}
`;
