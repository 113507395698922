import React from 'react';
import { Tooltip } from 'react-tooltip';

/**
 * 특정 요소에 대한 툴팁을 만들어 주는 유틸
 * @param {string} anchorSelect 툴팁을 적용할 대상 (`.className`)
 * @param {string} toolTipPlace 툴팁이 표시되는 위치 (top, top-start, top-end, right, right-start, right-end, bottom, bottom-start, bottom-end, left, left-start, left-end)
 * @param {Object} toolTipStyle 툴팁 스타일 (리액트 인라인 스타일과 비슷하게 객체로 전달)
 * @param {string} toolTipContent 툴팁 안에 넣을 내용
 */

const TooltipItem = ({
	anchorSelect,
	toolTipPlace,
	toolTipStyle,
	toolTipContent,
}) => {
	return (
		<Tooltip
			anchorSelect={anchorSelect}
			place={toolTipPlace}
			style={toolTipStyle}>
			{toolTipContent}
		</Tooltip>
	);
};

export default TooltipItem;
