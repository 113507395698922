import styled from 'styled-components';

export const Container = styled.main`
	position: relative;
	min-height: calc(100vh - 14rem);
	padding: 7.5rem 0 0 0;
	${({ theme }) => theme.common.container};
`;

export const Nav = styled.nav`
	font-family: NotoSansKR-Bold;
	width: 100%;
	top: 0;
	left: 0;
	height: 7.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 2rem;
	color: ${({ theme }) => theme.color.gray_70};
	.dash {
		content: '';
		width: 1px;
		height: 30%;
		border-right: 1px dashed gray;
	}
	& > div:not(.dash) {
		cursor: pointer;
	}
	.selected,
	& > div:not(.dash):hover {
		color: ${({ theme }) => theme.color.orange};
	}
`;
