import { useLocation, useNavigate } from 'react-router-dom';

// 옵션 설명
// normal : 기본 모드 (태그 클릭 시, 모든 태그 정보 URL Param에 넘기기)
// unique : 고유값 모드 (태그 클릭 한 것만 URL Param에 넘기기)
const useTagClickHandler = (option = 'normal') => {
	const location = useLocation();
	const navigate = useNavigate();
	const handleTagClick = (_tag, queryKey) => {
		const searchParams = new URLSearchParams(location.search);
		const currentPath = location.pathname;

		let currentSearchIds = searchParams.getAll(queryKey);

		console.log('currentPath', currentPath);
		console.log('currentSearchIds', currentSearchIds);
		// 중복 제거
		const uniqueIds = new Set(currentSearchIds);
		currentSearchIds = Array.from(uniqueIds);
		console.log('_tag', _tag);

		let index = currentSearchIds.findIndex((id) => id == (_tag?.id || _tag));

		// console.log('<1> [currentSearchIds, index]', currentSearchIds, index);

		if (index !== -1) {
			currentSearchIds.splice(index, 1);
		} else {
			currentSearchIds.push(_tag.id.toString());
		}

		if (option !== 'normal' || index === -1) {
			if (!currentSearchIds.includes(_tag.id.toString())) {
				currentSearchIds.push(_tag.id.toString());
			}
		}
		const curQuery = searchParams.get(queryKey);
		console.log('curQuery', curQuery);
		searchParams.delete(queryKey);
		searchParams.delete('category');

		if (option === 'normal') {
			currentSearchIds.forEach((id) => searchParams.append(queryKey, id));
		} else if (option === 'unique') {
			console.log(currentSearchIds, curQuery);
			// if (currentSearchIds[0] === curQuery) {
			// 	navigate(`${currentPath}`);
			// 	return;
			// }
			currentSearchIds
				.slice(-1)
				.forEach((id) => searchParams.append(queryKey, id));
		}
		navigate(`${currentPath}?${searchParams.toString()}`);
	};

	return handleTagClick;
};

export default useTagClickHandler;
