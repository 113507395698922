import styled from 'styled-components';
import { Box } from '../Dock.style';

import x from '../../../assets/image/x/x.webp';
import x2x from '../../../assets/image/x/x@2x.webp';
import x3x from '../../../assets/image/x/x@3x.webp';

const x_list = [x, x2x, x3x];

export { x_list };

export const Content = styled(Box)`
	overflow: visible;
	min-width: 32rem;
	${({ theme }) => theme.common.flex('row', 'center', 'space-between')};
`;

export const LinkButton = styled.button`
	position: sticky;
	padding: 0;
	border: 0;
	border-radius: 2.4rem;
	font-family: NotoSansMyanmar-Bold;
	font-size: 1.6rem;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.08);
	font-weight: bold;
	font-family: NotoSansMyanmar-Bold;
	color: ${({ theme }) => theme.color.bold};
	background-color: ${({ theme }) => theme.color.background};
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('100%')};
`;

export const IconContainer = styled.div`
	position: absolute;
	left: 4.72rem;
	aspect-ratio: 1 / 1;
	${({ theme }) => theme.common.wh('3.2rem')};
`;
