import styled, { keyframes } from 'styled-components';

export const SpinAnimation = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

export const Container = styled.div`
	display: inline-block;
	overflow: hidden;
	${({ theme }) => theme.common.wh('100%')};
	${({ theme }) => theme.common.flex()};
`;

export const Spinner = styled.div`
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
	//background-color: antiquewhite;
	${({ theme }) => theme.common.wh('100%')};
`;

export const SpinnerElement = styled.div`
	position: absolute;
	left: 46%;
	top: 2.5%;
	border-radius: 9.72px / 12.15px;
	transform-origin: 50% 200%;
	background-color: ${({ theme, color }) =>
		color ? color : theme.color.mainColor};
	animation: ${SpinAnimation} linear 0.5319148936170213s infinite;
	transform: ${({ count, index }) => `rotate(${(360 / count) * index}deg)`};
	animation-delay: ${({ index }) => `${index * 0.04835589941972921}s`};
	${({ theme }) => theme.common.wh('4%', '24%')};
`;
