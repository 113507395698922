import styled from 'styled-components';
import { Box } from './DockLikuController.style';

export const MakeExpressionBox = styled(Box)`
	.content {
		padding: 1.6rem 2rem;
		gap: 1.05rem;
	}

	& textarea {
		margin-bottom: 0.05rem;
		font-family: NotoSansKR-Regular;
		font-size: 1.5rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		outline: none;
		resize: none;
		border: none;
		background-attachment: local;
		background-size: 100% 2.15rem;
		background-position: 0 -2.2rem; /* 수정 */
		background-repeat: repeat-y; /* 수정 */
		line-height: 2.15rem;
		color: ${({ theme }) => theme.color.subTitle};
		background-image: ${({ theme }) => `linear-gradient(
			transparent,
			transparent 2.05rem,
			${theme.color.gray_f4} 2.05rem,
			${theme.color.gray_f4} 2.15rem,
			transparent 2.15rem
		)`};
		${({ theme }) => theme.common.wh('100%', 'auto')};
	}

	@media (max-width: 1000px) {
		& textarea {
			font-size: 1.2rem;
		}
	}
`;

export const SelectBox = styled.div`
	position: relative;
	//padding: 0.3rem 1.5rem 0.4rem 1.5rem;
	border: none;
	border-radius: 4px;
	font-family: NotoSansKR-Regular;
	font-size: 1.5rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: center;
	color: #46423d;
	background-color: #f0f0ef;
	transition: max-height 1.5s ease;
	${({ theme }) => theme.common.wh('12rem', '3rem')};

	@media (max-width: 1000px) {
		font-size: 1.2rem;
		${({ theme }) => theme.common.wh('9.6rem', '2.4rem')};
	}

	.selected {
		//padding: 0rem 0.8rem;

		border-radius: 4px;
		white-space: nowrap;
		font-family: NotoSansKR-Bold;
		font-weight: bold;
		color: #46423d;
		${({ theme }) => theme.common.flex()};
		${({ theme }) => theme.common.wh('100%')};

		&.action {
			color: #fff;
			background-color: #82affa;
		}

		&.display {
			color: #fff;
			background-color: #fe80ab;
		}
	}

	ul {
		position: absolute;
		left: 0;
		top: 2.4rem;
		overflow-y: auto;
		transition: all 0.2s ease;
		font-family: NotoSansKR-Regular;
		font-size: 1.2rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		text-align: center;
		color: #46423d;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
		max-height: ${({ click }) => (click ? '12rem' : '0rem')};
		${({ theme }) => theme.common.flex('column', 'flex-start', 'flex-start')};
		${({ theme }) => theme.common.wh('100%', 'auto')};
	}

	li {
		z-index: 9999;
		border-radius: 4px;
		padding: 0.3rem 0.8rem;
		font-family: NotoSansKR-Regular;
		font-weight: normal;
		color: #9a9a9a;
		background-color: transparent;
		${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
		${({ theme }) => theme.common.wh('100%', '2.4rem')};

		&:hover {
			background-color: #f0f0ef;
		}
	}
`;
