import { authApi } from './index.js';

export const getAllScenario = async () => {
	try {
		const { data } = await authApi.get('/contents/scenarios');
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const createScenario = async (
	_motionList,
	_scenarioOrder,
	_memberId,
	_title,
) => {
	console.log('createScenario', _motionList, _scenarioOrder, _memberId);
	const formattedRequest = _motionList.map((item, index) => {
		return {
			scenario: {
				name: _title,
				scenarioOrder: _scenarioOrder,
				member: _memberId,
			},
			uuid: item.uuid,
			motionOrder: index + 1,
			member: _memberId,
		};
	});
	console.log(formattedRequest);
	const { data } = await authApi.post(
		'/contents/scenarios/motions',
		formattedRequest,
	);
	return formattedRequest;
};

export const deleteScenario = async (_scenarioId, _motionId, _motionOrder) => {
	try {
		const { data } = await authApi.delete(
			`/contents/scenarios/${_scenarioId}/motions/${_motionId}?motionOrder=${_motionOrder}`,
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const deleteScenarioId = async (_scenarioId) => {
	try {
		const { data } = await authApi.delete(
			`/contents/scenarios?id=${_scenarioId}`,
		);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getScenario = async (id) => {
	try {
		const { data } = await authApi.get(`/contents/scenarios/${id}`);
		return data;
	} catch (error) {
		console.error(error);
	}
};
