import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0;
	width: 100%;
	height: 100%;
	transition: all 0.3s;
	/* background-color: rgba(192, 192, 192, 0.9); */
	overflow: hide;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	padding: 0;
	background-color: white;

	&::-webkit-scrollbar {
		height: 4px;
		background: white;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.color.gold};
	}

	& .clicked {
		background-color: ${({ theme }) => theme.color.gold};
		font-weight: bold;
		transition: all 0.3s;

		&:hover {
			filter: brightness(95%);
		}
	}

	/* @media screen and (max-width: ${({ theme }) => theme.size.mobile}) {
		display: none;
	} */
`;

export const CategoryBox = styled.div`
	display: flex;
	height: 100%;
`;

export const BlackBackground = styled.div`
	display: block;
	position: fixed;
	top: 100px;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100vw;
	z-index: -9999;
	top: 150px;
`;
