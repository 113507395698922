import React, { useState } from 'react';
import { observer } from 'mobx-react';
import * as S from './UserSelect.style';
import useUserFaceQuery from '../../../hooks/queries/useUserFaceQuery';
import Image from '../../../components/image/Image';

const DropdownUser = observer(({ user, onClick }) => {
	const { data } = useUserFaceQuery(
		user?.id,
		user?.collections ? user.collections[0]?.name : null,
	);
	const calculateAge = (number) => {
		// Calculate age based on the number
		const age =
			new Date().getFullYear() - parseInt(number.toString().substring(0, 4));

		return age;
	};

	const age = calculateAge(user?.birth);

	return (
		<S.DropdownUserBox key={user?.id} onClick={onClick}>
			<Image srcArray={[data ? data[0]?.path : '']} />
			<div>
				<p>{user?.name}</p>
				<p>
					{age}세 / {user.gender === 'MALE' ? '남성' : '여성'}
				</p>
			</div>
		</S.DropdownUserBox>
	);
});

export default DropdownUser;
