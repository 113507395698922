import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react';

import * as S from './RobotState.style';

import { RootStoreContext } from '../../../../App';
import { useClick } from '../../../../hooks/useClick';

const RobotState = observer(() => {
	const { likuStore } = useContext(RootStoreContext);

	const selectBoxRef = useRef(null);
	const selectedOptionRef = useRef(null);

	const { click: isVolumeList, onClick: setIsVolumeList } = useClick(false);

	const isSleep = likuStore.thisLiku?.motionStatus === 'sleeping';

	const volumes = [...Array(11).keys()].map((value) => {
		const volume = value * 10;
		return (
			<li
				key={volume}
				ref={volume === likuStore.thisLiku?.volume ? selectedOptionRef : null}
				onClick={() => likuStore.setVolume(volume)}>
				{volume}%
			</li>
		);
	});

	return (
		<S.RobotStateBox className='dark'>
			<div className='title'>
				로봇 상태
				<div className='wrap'>
					<div className='stateWrap'>
						<S.StateButton
							disabled={isSleep}
							click={isSleep}
							onClick={() => likuStore.setMotionState('sleep')}>
							{isSleep ? '자는중' : '재우기'}
						</S.StateButton>
						<S.StateButton
							disabled={!isSleep}
							click={!isSleep}
							onClick={() => likuStore.setMotionState('active')}>
							{!isSleep ? '활동중' : '깨우기'}
						</S.StateButton>
					</div>
					<S.SelectBox
						ref={selectBoxRef}
						click={isVolumeList}
						onClick={() => setIsVolumeList()}>
						<span className='selected'>{likuStore.thisLiku?.volume}%</span>
						<ul>{volumes}</ul>
					</S.SelectBox>
					{/*<S.VolumeButton></S.VolumeButton>*/}
				</div>
			</div>
		</S.RobotStateBox>
	);
});

export default RobotState;
