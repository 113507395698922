import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import * as S from './AienMainPage.style';

import { RootStoreContext } from '../../App';
import AienMainPageButton from './AienMainPageButton';

const AienMainPage = observer(() => {
	const { likuStore } = React.useContext(RootStoreContext);
	const navigate = useNavigate();

	const handleClickForStepOne = () => {
		alert('[STEP1] 전체 화면으로 바로 실행');
	};

	const handleClickForStepTwo = () => {
		alert('[STEP2] ?????');
	};

	const handleClickForStepThree = () => {
		alert('[STEP3] ?????');
	};

	return (
		<S.ContainerBox>
			{/*<Banner />*/}
			<S.AppBox>
				<AienMainPageButton
					onClick={() => handleClickForStepOne()}
					subTitle={'아이엔'}
					buttonTitle={'STEP I'}
					buttonDescription={'STEP1 영상 보기 (전체화면)'}
					backColor={'gradient1'}
					imageSrcArray={[S.playBackground]}
					alt='aien step 1'
				/>
				<AienMainPageButton
					onClick={() => handleClickForStepTwo()}
					buttonDescription={'STEP2 페이지 이동'}
					backColor={'gradient2'}
					subTitle={'아이엔'}
					buttonTitle={'STEP II'}
					imageSrcArray={[S.foo2]} // [S.manage, S.manage2x, S.manage3x]
					alt='aien step 2'
				/>
				<AienMainPageButton
					onClick={() => handleClickForStepThree()}
					buttonDescription={'STEP3 페이지 이동'}
					backColor={'gradient3'}
					subTitle={'아이엔'}
					buttonTitle={'STEP III'}
					imageSrcArray={[S.foo2]} // [S.manage, S.manage2x, S.manage3x]
					alt='aien step 3'
				/>
			</S.AppBox>
		</S.ContainerBox>
	);
});

export default AienMainPage;
